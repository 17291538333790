import React, { useEffect, useState, useCallback } from "react";
import NavTabs from "../../../components/nav-tabs/index";
import Input from "../../../components/input/index";
import CommomButton from "../../../components/common-button/index";
import StatusChip from "../../../components/status-chip/index";
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Table,
  Toast,
  Card,
  ToastContainer,
  Image,
  OverlayTrigger,
  Modal,
  Tooltip as TT,
  Button,
  // Dropdown,
} from "react-bootstrap";
import {fetchCampaignDetail, updateGuestStatus, fetchAllCampaignDetail,fetchCampaignById, cloneCampaign, deleteCampaign} from "../../../store/eventsSlice";
import { Container, Col, Row } from "react-bootstrap";
import "../../pages/eventsDetails/styleModule.css";
import { useNavigate, useParams } from "react-router-dom";
import CommonLayout from "../../layouts/master/index";
import { useLoader } from "../../../context/LoaderContext";
import moment from "moment";
import * as XLSX from "xlsx";
import axios from "axios";
import { pdfjs, Document, Page } from "react-pdf";
import {
  Calendar03Icon,
  SentIcon,
  UserIcon,
  MessageSearch01Icon,
  MessageDone01Icon,
  MessagePreview01Icon,
  MessageCancel01Icon,
  Message01Icon,
  MessageQuestionIcon,
  MessageUpload01Icon,
  ReloadIcon,
  ArrowLeftIcon,
  CheckmarkBadge04Icon,
  Cancel01Icon,
  FileDownloadIcon,
  Delete02Icon,
  Coins01Icon,
  MessageDelay01Icon,
  Coins02Icon,
  CancelCircleHalfDotIcon,
  EyeIcon,
  CheckmarkCircle04Icon,
  Clock05Icon,
  InformationCircleIcon,
  Copy02Icon,
  // ArrowDown01Icon,
  // MessageEdit01Icon,
  // PauseCircleIcon,
  // CancelCircleIcon,
  NoteEditIcon,
  MessagePreview02Icon,
} from "../../../icons/icons";
import { CampaignsDataTabs } from "../../../constants/pages/campaign/detail/index";
import BackPageTab from "../../../components/back-page-tab";
import CommonPagination from "../../../components/common-pagination";
import Zoom from "react-medium-image-zoom"; // For image zooming
import "react-medium-image-zoom/dist/styles.css"; // Image zooming styles
import PdfZoomViewModal from "./PdfZoomViewModal";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { PDFDocument, rgb } from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";
import CreatableSelect from "react-select/creatable";
import { recordsPerPageOptions } from "../campaings/common/Common";
import TableLoader from "../../../components/TableLoader";
import DeletePopup from "../../../components/delete-popup";
import Checkbox from "../../../components/checkbox";
import SmallWidgets from "../../../components/widget/small-widgets";
import { GreenDownloadIcon } from "../../../constants/icons/icons";

let ws;

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function CampaignDetails() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const { setLoading, loading } = useLoader();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("toast-success");

  const campaingDetailsData = useSelector(
    (state) => state.events?.campaingDetailsData
  );
  const total = useSelector((state) => state.events?.total);
  // const totalPages = useSelector((state) => state.events?.totalPages);
  const campaingDetails = useSelector((state) => state.events?.campaingDetails);

  const [currentPage, setCurrentPage] = useState(0);
  const [limit, setLimit] = useState({ value: 50, label: 50 });
  const [searchTerm, setSearchTerm] = useState("");
  const [documentUrl, setDocumentUrl] = useState(null);
  // const [pdfTotalPages, setPdfTotalPages] = useState(0);
  const [pdfCurrentPage, setPdfCurrentPage] = useState(1);

  // const [activeTab, setActiveTab] = useState("list");
  const [isFailedSelected, setIsFailedSelected] = useState(false);
  const [isInvalidSelected, setIsInvalidSelected] = useState(false);
  const [activeSubTab, setActiveSubTab] = useState("guests");

  const [activeTabStatus, setActiveTabStatus] = useState("all");
  const [tableLoading, setTableLoading] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [deleteCampaignModal, setDeleteCampaignModal] = useState(false);

  const [selectedItems, setSelectedItems] = useState([]);

  const passedCampaignItem = location?.state?.campaing || location?.state?.item;

  const currentPagePassedInDetail = location?.state?.currentPage || 0;

  // console.log("selectedItems",selectedItems,selectedItems?.length);

  const fetchDocument = useCallback(
    async (url) => {
      try {
        setLoading(true);
        const response = await axios.get(url, { responseType: "blob" });
        // console.log("response : ",response);
        const blobUrl = URL.createObjectURL(response.data);
        // console.log("blobUrl : ",blobUrl);

        setDocumentUrl(blobUrl);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching document: ", error);
        setLoading(false);
      }
    },
    [setLoading]
  );

  const fetchCampaignDetailData = useCallback(() => {
    // if (activeTab === "list") {
    window.scrollTo({ top: 0, behavior: "smooth" }); 
    setIsFailedSelected(false);
    setIsInvalidSelected(false);
    setLoading(true);
    dispatch(
      fetchCampaignDetail({
        offset: currentPage,
        limit: limit?.value,
        search: searchTerm,
        is_test: activeSubTab === "guests" ? "false" : "true",
        id: id,
      })
    ).finally(() => {
      setLoading(false);
    });
    // }
  }, [
    dispatch,
    currentPage,
    limit?.value,
    searchTerm,
    id,
    activeSubTab,
    setLoading,
    // activeTab,
  ]);

  const fetchCampaignByIdData = useCallback(() => {
    // if (activeTab === "details") {
    // setLoading(true);
    dispatch(fetchCampaignById({ id }))
      .then((response) => {
        const { campaingDetails } = response.payload;
        if (campaingDetails && campaingDetails?.new_template_url) {
          fetchDocument(campaingDetails.new_template_url);
        }
      })
      .finally(() => {
        setLoading(false);
      });
    // }
  }, [dispatch, id, setLoading, fetchDocument]);

  useEffect(() => {
    fetchCampaignDetailData();
  }, [fetchCampaignDetailData]);

  useEffect(() => {
    fetchCampaignByIdData();
  }, [fetchCampaignByIdData]);

  const handleStatusClick = (status) => {
    setActiveTabStatus(status);
    setLoading(true);
    setIsFailedSelected(status === "failed");
    setIsInvalidSelected(status === "invalid");
    const fetchParams = {
      offset: currentPage,
      limit: limit?.value,
      search: searchTerm,
      is_test: false,
      id: id,
    };

    if (status !== "all") {
      fetchParams.status = status;
    }

    dispatch(fetchCampaignDetail(fetchParams)).finally(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    ws = new WebSocket(process.env.REACT_APP_SOCKET_URL);

    ws.onopen = () => {
      const wsId = `cmd_${id}`;
      ws.send(JSON.stringify({ type: "REGISTER", id: wsId }));
      // setSock(ws);
    };

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      const { status, guest, reason } = data;
      dispatch(updateGuestStatus({ id: parseInt(guest.id), status, reason }));
      setSnackbarMessage(`Message to ${guest.mobile_number} ${status}`);
      setSnackbarVariant(status === "sent" ? "toast-success" : "toast-danger");
      setShowSnackbar(true);
    };

    ws.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    ws.onclose = () => {
      setTimeout(() => {
        ws = new WebSocket(process.env.REACT_APP_SOCKET_URL);
      }, 1000);
    };

    return () => {
      ws.close();
    };
  }, [dispatch, id]);

  const handlePageChange = (newPageNumber) => {
    setCurrentPage(newPageNumber - 1);
    window.scrollTo({ top: 0, behavior: "smooth" }); 
  };

  // const handleBackClick = () => {
  //   const clientId = campaingDetailsData?.data[0]?.client_id;
  //   console.log("clientId ", clientId);
    

  //   if (clientId) {
  //     const targetPath = `/customers/${clientId}`;
  //     // console.log("Navigating to:", targetPath);
  //     navigate(targetPath, { replace: true });
  //     window.location.href = targetPath; // Fallback to ensure navigation
  //   } else {
  //     console.error("Client ID not found, unable to navigate.");
  //     navigate(-1); // Go back one step if clientId is not available
  //   }
  // };

  const handleBackClick = () => {
    sessionStorage.setItem('currentPageNo', currentPagePassedInDetail);
    navigate(-1)
  }

  const handleRefreshClick = () => {
    // window.location.reload();
    setTableLoading(true);
    dispatch(fetchCampaignDetail({ id: id })).finally(() => {
      setTableLoading(false);
    });
  };

  const handleExportClick = async () => {
    try {
      setLoading(true); // Enable loading state
      let requestData = {
        limit: campaingDetailsData?.total_recipients,
        is_test: "false",
        id: id,
      };
  
      if (activeTabStatus !== "all") {
        requestData.status = activeTabStatus;
      }
  
      const allDataResponse = await dispatch(
        fetchAllCampaignDetail(requestData)
      ).unwrap();
  
      const data = allDataResponse?.campaingDetailsData?.data;
  
      if (!data || !data.length) {
        console.warn("No data available to export");
        setLoading(false);
        return;
      }
  
      // Collect all unique variable keys across the dataset
      const allVariableKeys = Array.from(
        new Set(
          data.flatMap((event) => Object.keys(event.variables || {}))
        )
      );
  
      // Map the data for export
      const dataToExport = data.map((event) => {
        const variables = event.variables || {};
        const variableColumns = allVariableKeys.reduce((acc, key) => {
          acc[key] = variables[key] || "-"; // Add variable value or leave empty
          return acc;
        }, {});
  
        return {
          Name: event?.name || "N/A",
          Mobile: event?.mobile_number || "N/A",
          ...variableColumns, // Add dynamic variable columns
          Date: event?.campaign?.created_at
            ? new Date(event.campaign.created_at).toLocaleString("en-IN", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              })
            : "-",
          ScheduleDate: event?.campaign?.scheduler_date
            ? moment(event.campaign.scheduler_date).format("DD/MM/YYYY hh:mm A")
            : "-",
          Status: event?.status !== "pending" ? event.status : "Pending",
          FailedReason: event?.failed_reason || "-",
          CreditsUsed: event?.credits || 0,
          SentStatus: event?.whatsapp_sent
            ? new Date(event.whatsapp_sent * 1000).toLocaleString("en-IN", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              })
            : "-",
          DeliveryStatus: event?.whatsapp_deliver
            ? new Date(event.whatsapp_deliver * 1000).toLocaleString("en-IN", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              })
            : "-",
          SeenStatus: event?.whatsapp_seen
            ? new Date(event.whatsapp_seen * 1000).toLocaleString("en-IN", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              })
            : "-",
        };
      });
  
      // Export to Excel
      const worksheet = XLSX.utils.json_to_sheet(dataToExport);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Campaign Details");
  
      const fileName = `${campaingDetails?.client_event?.name || "Campaign"}_${
        campaingDetails?.name || "Details"
      }_${new Date().toISOString().split("T")[0]}.xlsx`;
  
      XLSX.writeFile(workbook, fileName);
      setLoading(false); // Disable loading state
    } catch (error) {
      console.error("Error exporting data: ", error);
      setLoading(false); // Disable loading state on error
    }
  };
  
  const handleResendFailedClick = async () => {
    try {
      setLoading(true);
      await axios.post(
        `${process.env.REACT_APP_WHATSAPP_API_URL}/api/whatsapp/resend-failed`,
        {
          clientId: campaingDetailsData?.data[0]?.client_id,
          wsId: "cmd",
          campaignId: parseInt(id),
          fileUrl:
            campaingDetailsData?.data[0]?.campaign?.template_type !== "text"
              ? `${process.env.REACT_APP_API_URL}/storage/${campaingDetailsData?.data[0]?.campaign?.template_url}`
              : null,
          companyId: campaingDetailsData?.data[0]?.company_id,
        }
      );
      setSnackbarMessage("Resending failed messages started successfully");
      setSnackbarVariant("toast-success");
      setShowSnackbar(true);
    } catch (error) {
      setSnackbarMessage("Failed to start resending messages");
      setSnackbarVariant("toast-danger");
      setShowSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  const handleInvalidClick = async () => {
    try {
      setLoading(true);
      dispatch(cloneCampaign({ id: id }))
        .then((response) => {
          // console.log("Response :::: ", response);
          if (response && response.payload && response.payload.success) {
            setSnackbarMessage("New Campaign Created Successfully!");
            setSnackbarVariant("toast-success");
            setShowSnackbar(true);
            // navigate(``)
            handleModalClose();
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      setSnackbarMessage("Failed to Create New Campaig");
      setSnackbarVariant("toast-danger");
      setShowSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    // setPdfTotalPages(numPages);
    setPdfCurrentPage(1);
    // setNumPages(numPages);
  };

  const handleSubTabSelect = (key) => {
    setActiveSubTab(key);
    setCurrentPage(0);
  };

  const getUserInitial = (name) => {
    return name
      ? name
          .split(" ")
          .map((n) => n.charAt(0))
          .join("")
      : "UN";
  };

  const titles = [{ name: "Customers", path: "/customers" }];

  const [showAddModal, setShowAddModal] = useState(false);

  const handleModalClose = () => {
    setShowAddModal(false);
  };

  const handleOpenModal = () => {
    setShowAddModal(true);
  };

  const [openPdfModalView, setOpenPdfModalView] = useState(false);
  const [pdfPageWiseView, setPdfPageWiseView] = useState([]);

  // Handler for opening the Pdf Zoom View
  const handleOpenPdfView = async () => {
    setLoading(true);
    if (campaingDetails?.template_type === "pdf") {
      const url = campaingDetails?.new_template_url; // API-provided PDF URL
      // Convert PDF to page-wise images or load directly
      processPdf(url);
    }
    await new Promise(() =>
      setTimeout(() => {
        setOpenPdfModalView(true);
        setLoading(false);
      }, 2000)
    );
  };

  const processPdf = async (pdfUrl) => {
    // Example logic using react-pdf (or PDF.js for advanced splitting):
    const pdfjsLib = window.pdfjsLib;
    const pdfDoc = await pdfjsLib.getDocument(pdfUrl).promise;
    // const pdfDoc = await pdfjsLib.getDocument(`http://localhost:8000/storage/Campaigns/1728043874.pdf`).promise;

    // Extract each page as an image and store in pdfPageWiseView
    const pageImages = [];
    for (let i = 1; i <= pdfDoc.numPages; i++) {
      const page = await pdfDoc.getPage(i);
      const viewport = page.getViewport({ scale: 1 });
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      canvas.width = viewport.width;
      canvas.height = viewport.height;
      await page.render({ canvasContext: context, viewport }).promise;
      const base64Image = canvas.toDataURL("image/png");
      pageImages.push({
        base64: base64Image,
        width: viewport.width,
        height: viewport.height,
      });
    }
    if (pageImages.length > 0) {
      setPdfPageWiseView(pageImages); // Set page-wise view
    }
  };

  // Handler for closing the Pdf Zoom View
  const handleClosePdfViewModal = () => {
    setOpenPdfModalView(false);
  };

  // Utility to check if the URL is an image
  const isImageFile = (url) => {
    const imageExtensions = [".jpeg", ".jpg", ".png"];
    return imageExtensions.some((ext) => url.toLowerCase().endsWith(ext));
  };

  const changeFont = async (data) => {
    console.log("Data :::: ", data);
    let pos = campaingDetails?.variable_positions;

    // console.log("pos : : : : : :", pos);

    const existingPdfBytes = await fetch(
      campaingDetails?.new_template_url
    ).then((res) => res.arrayBuffer());

    const pdfDocs1 = await PDFDocument.load(existingPdfBytes);
    console.log("pdfDocs1", pdfDocs1);

    const pages = pdfDocs1.getPages();
    pdfDocs1.registerFontkit(fontkit);
    let embeddedFonts = [];
    for (let key in pos) {
      const fontObject = pos[key];

      console.log("FontObject :::: ", fontObject);
      const fontBytes = await fetch(fontObject.fontFile).then((res) =>
        res.arrayBuffer()
      );

      console.log(" fontBytes ::::: ", fontBytes);
      await fontkit.create(fontBytes);
      const embeddedFont = await pdfDocs1.embedFont(fontBytes, {
        subset: true,
      });
      embeddedFonts.push(embeddedFont);

      console.log("embeddedFonts ::::: ", embeddedFonts);
    }

    let index = 0;
    for (let key in pos) {
      const page = pages[pos[key].page - 1];
      const { height } = page.getSize();
      const textHeight = embeddedFonts[index].sizeAtHeight(pos[key]?.size);
      let x = pos[key].x;

      console.log("COLOR HHAHAHHAHAH:::: ", textHeight);
      page.drawText(data[key].replaceAll(/;/g, ","), {
        x: x,
        y:
          height -
          (pos[key].y +
            textHeight -
            (textHeight >= 46
              ? 7
              : textHeight >= 30
              ? 6
              : textHeight >= 12
              ? 3
              : 1)),
        size: pos[key]?.size,
        font: embeddedFonts[index],
        color: rgb(
          pos[key]?.color[0] / 255,
          pos[key]?.color[1] / 255,
          pos[key]?.color[2] / 255
        ),
      });
      index++;
    }
    const pdfBytes = await pdfDocs1.save();
    return pdfBytes;
  };

  // Download Failed Campaigns (PDFs or Images)
  const downloadFailedZip = async (failedList, type) => {
    console.log("failedList, type",failedList, type);
    if (!failedList || failedList.length === 0) {
      alert("No failed campaigns to download");
      return;
    }
    // setLoading(true);

    const zip = new JSZip();

    for (let i = 0; i < failedList.length; i++) {
      const entry = failedList[i];
      console.log("entry", entry);

      const url = entry?.campaign?.template_url;
      // console.log("url :: :: ", url);

      if (url.endsWith(".pdf")) {
        // Process PDF
        try {
          const pdfBytes = await changeFont({
            ...entry?.variables,
            name: entry?.name,
          });

          // const fileName = `${entry.name || `failed-${i + 1}`}.pdf`;
          const fileName =
            type === "failed"
              ? `${entry.name || `failed-${i + 1}`}.pdf`
              : `${entry.name || `invalid-${i + 1}`}.pdf`;
          zip.file(fileName, pdfBytes);
        } catch (error) {
          console.error("Error processing PDF:", error);
        }
      } else if (isImageFile(url)) {
    //     // Process Image
        try {
          // Check if the entry contains an array of image URLs
          const imageUrls = Array.isArray(entry.imageUrls)
            ? entry.imageUrls
            : [url];

          // Get API base URL from environment variable
          const apiUrl = process.env.REACT_APP_API_URL;

          // console.log("imageUrls", imageUrls);

          // Loop through each image URL
          for (let j = 0; j < imageUrls.length; j++) {
            const imageUrl = `${apiUrl}/storage/${imageUrls[j]}`;
            // console.log("imageUrl", imageUrl);

            const imageResponse = await fetch(imageUrl);
            // console.log("imageResponse", imageResponse?.url);

            if (!imageResponse.ok) {
              console.error(`Failed to fetch image from URL: ${imageUrl}`);
              continue; // Skip this image and proceed to the next
            }

            const imageBlob = await imageResponse.blob();
            // console.log("imageBlob", imageBlob);

            const fileExtension = url.split(".").pop();
            // console.log("fileExtension", fileExtension);

            const fileName =
              type === "failed"
                ? `${entry.name || `failed-${i + 1}`}.${fileExtension}`
                : `${entry.name || `invalid-${i + 1}`}.${fileExtension}`;
            // console.log("fileName", fileName);

            zip.file(fileName, imageBlob);
          }
        } catch (error) {
          console.error("Error processing image:", error);
        }
      } else {
        console.warn(`Unsupported file type for entry: ${entry.name}`);
      }
    }

    // Generate the ZIP file and download it
    try {
      const content = await zip.generateAsync({ type: "blob" });
      saveAs(
        content,
        type === "failed" ? "failed-list.zip" : "invalid-list.zip"
      );
      setLoading(false);
    } catch (error) {
      console.error("Error generating ZIP:", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }


    // zip.generateAsync({ type: "blob" }).then((content) => {
    //   saveAs(
    //     content,
    //     type === "failed" ? "failed-list.zip" : "invalid-list.zip"
    //   );
    // setLoading(false);
    // });
  };

  const showBtnIfArrHasPdfOrImg =
    Array.isArray(campaingDetailsData?.data) &&
    campaingDetailsData.data.length > 0 &&
    campaingDetailsData.data.some(
      (item) =>
        item.campaign?.template_type === "pdf" ||
        item.campaign?.template_type === "image"
    );

  const handleChange = (newValue) => {
    if (newValue) {
      // Update state with both value and label
      setLimit({ value: newValue.value, label: newValue.label });
    } else {
      // If the value is null (when user clears selection), set a default value
      setLimit({ value: 50, label: 50 }); // Set to default object
    }
  };

  // console.log("campaingDetailsData :: Table", campaingDetailsData);
  // console.log("campaingDetailsOBJ : : ", campaingDetails);

  // Open Delete Campaign Modal
  const handleDeleteCampaign = () => {
    setDeleteCampaignModal(true);
  };

  // Final handler of Delete Campaign
  const handleConfirmDeleteCampaign = () => {
    try {
      setIsSubmitting(true); // Set loading state
      setLoading(true); // Optional loader if you have one
      dispatch(deleteCampaign({ id: id }));
      setSnackbarMessage("Campaign deleted successfully");
      setSnackbarVariant("toast-success");
      setShowSnackbar(true);
      navigate("/all-campaigns");
    } catch (error) {
      setSnackbarMessage("Failed to delete campaign");
      setSnackbarVariant("toast-danger");
      setShowSnackbar(true);
      setLoading(false);
    } finally {
      setIsSubmitting(false);
      setLoading(false); // Optional loader
      setDeleteCampaignModal(false);
    }
  };

  // Edit handler for Editing Campaign in Campaign Details
  const handleEditCampaign = (e) => {
    e.preventDefault();
    if (!passedCampaignItem) {
      setSnackbarMessage("campaign Id is missing");
      setSnackbarVariant("toast-danger");
      setShowSnackbar(true);
      return;
    }
    if (passedCampaignItem?.client_event?.whatsapp_client?.is_active) {
      navigate(`/customers/update-campaign/${passedCampaignItem.id}`);
    } else {
      setSnackbarMessage("Please Connect Whatsapp!!");
      setSnackbarVariant("danger");
      setShowSnackbar(true);
    }
  }; 

  // Download Each Guest Pdf Individually Handler
  const downloadEachGuestPdfHanlder = async (item) => {
    if (Object.keys(item).length === 0) {
      setSnackbarMessage("No Data available to download");
      setSnackbarVariant("toast-danger");
      setShowSnackbar(true);
      return;
    }
    setLoading(true);
  
    const url = item?.campaign?.template_url;
  
    if (url.endsWith(".pdf")) {
      try {
        const pdfBytes = await changeFont({
          ...item?.variables,
          name: item?.name,
        });
        const fileName = `${item.name || `-PDF`}.pdf`;
        // Trigger the file download
        const blob = new Blob([pdfBytes], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
  
        setSnackbarMessage("PDF downloaded successfully");
        setSnackbarVariant("toast-success");
        setShowSnackbar(true);
        setLoading(false);
      } catch (error) {
        console.error("Error while generating PDF:", error);
        setSnackbarMessage("Failed to download PDF");
        setSnackbarVariant("toast-danger");
        setShowSnackbar(true);
        setLoading(false);
      }
    } else {
      console.warn(`Unsupported file type for entry: ${item.name}`);
    }
  };

  // Check if all current page items are selected
  // const isAllCurrentPageSelected = campaingDetailsData?.data?.every((item) =>
  //   selectedItems.some((contact) => contact.id === item.id)
  // );

  // Handler for Selecting all and unselecting all Guests contacts
  // const handleSelectContacts = (e,type,item) => {
  //   if (type === "all"){
  //     if (isAllCurrentPageSelected) {
  //       // Unselect all items on the current page
  //       setSelectedItems((prev) =>
  //         prev?.filter(
  //           (contact) =>
  //             !campaingDetailsData?.data?.some((current) => current?.id === contact?.id)
  //         )
  //       );
  //     } else {
  //       // Select all items on the current page
  //       const newSelections = campaingDetailsData?.data?.filter(
  //         (item) => !selectedItems?.some((contact) => contact?.id === item?.id)
  //       );
  //       setSelectedItems((prev) => [...prev, ...newSelections]);
  //     }

  //   }else if (type === "single" && item){
  //     const isSelected = selectedItems?.some((contact) => contact?.id === item?.id);
  //     if (isSelected) {
  //       // Unselect the item
  //       setSelectedItems((prev) => prev?.filter((contact) => contact?.id !== item?.id));
  //     } else {
  //       // Select the item
  //       setSelectedItems((prev) => [...prev, item]);
  //     }
  //   }

  // }

  const handleSelectContacts = (e,item) => {
      // If single item is selected/unselected, check if it exceeds the 10-item limit
      const isSelected = selectedItems?.some((contact) => contact?.id === item?.id);
      if (isSelected) {
        // Unselect the item
        setSelectedItems((prev) => prev?.filter((contact) => contact?.id !== item?.id));
      } else {
        // If selected items are less than 10, add the item
        if ((selectedItems?.length || 0) < 10) {
          setSelectedItems((prev) => [...(prev || []), item]);
        } else {
          // Show error if trying to select more than 10 items
          setSnackbarMessage(`You can only select up to 10 contacts. Unselect some contacts to select more.`);
          setSnackbarVariant("toast-danger");
          setShowSnackbar(true);
        }
      }
  };

  // Download Zip Handler for multiple selected contacts
  const handledownloadMultipleContact = async () => {
    if (!selectedItems || selectedItems.length === 0) {
      setSnackbarMessage(`No Items Available to Download`);
      setSnackbarVariant("toast-danger");
      setShowSnackbar(true);
      return;
    }
    setLoading(true);
    const zip = new JSZip();

    for (let i = 0; i < selectedItems.length; i++) {
      const entry = selectedItems[i];
      console.log("entry", entry);

      const url = entry?.campaign?.template_url;
      // console.log("url :: :: ", url);

      if (url.endsWith(".pdf")) {
        // Process PDF
        try {
          const pdfBytes = await changeFont({
            ...entry?.variables,
            name: entry?.name,
          });

          const fileName = `${entry.name || `${i + 1}`}.pdf`;
          zip.file(fileName, pdfBytes);
        } catch (error) {
          console.error("Error processing PDF:", error);
        }
      } else if (isImageFile(url)) {
    //     // Process Image
        try {
          // Check if the entry contains an array of image URLs
          const imageUrls = Array.isArray(entry.imageUrls)
            ? entry.imageUrls
            : [url];

          // Get API base URL from environment variable
          const apiUrl = process.env.REACT_APP_API_URL;

          // console.log("imageUrls", imageUrls);

          // Loop through each image URL
          for (let j = 0; j < imageUrls.length; j++) {
            const imageUrl = `${apiUrl}/storage/${imageUrls[j]}`;
            // console.log("imageUrl", imageUrl);

            const imageResponse = await fetch(imageUrl);
            // console.log("imageResponse", imageResponse?.url);

            if (!imageResponse.ok) {
              console.error(`Failed to fetch image from URL: ${imageUrl}`);
              continue; // Skip this image and proceed to the next
            }

            const imageBlob = await imageResponse.blob();
            // console.log("imageBlob", imageBlob);

            const fileExtension = url.split(".").pop();
            // console.log("fileExtension", fileExtension);

            const fileName =`${entry.name || `${i + 1}`}.${fileExtension}`
            // console.log("fileName", fileName);

            zip.file(fileName, imageBlob);
          }
        } catch (error) {
          console.error("Error processing image:", error);
        }
      } else {
        console.warn(`Unsupported file type for entry: ${entry.name}`);
      }
    }

    // Generate the ZIP file and download it
      try {
      const content = await zip.generateAsync({ type: "blob" });
      saveAs(content,"contacts.zip");
      setLoading(false);
    } catch (error) {
      console.error("Error generating ZIP:", error);
      setLoading(false);
    } finally {
      setLoading(false);
      setSelectedItems([])
    }

  }
  
 
  
  

  return (
    <CommonLayout titles={titles}>
      <ToastContainer position="top-end" className="p-3">
        <Toast
          onClose={() => setShowSnackbar(false)}
          show={showSnackbar}
          delay={3000}
          autohide
          bg={snackbarVariant}
          className="position-fixed top-3 end-0 me-3 shadow-none"
        >
          <Toast.Body className="d-flex align-items-center gap-1 lh-sm">
            <CheckmarkBadge04Icon
              width={18}
              height={18}
              color="#28c76f"
              strokeWidth="2"
            />
            <span className="text-capitalize">{snackbarMessage}</span>
          </Toast.Body>
        </Toast>
      </ToastContainer>
      <Container fluid className="px-0">
        <div className="d-flex align-items-center justify-content-between">
        <BackPageTab
          to="#"
          onClick={() => handleBackClick()}
          // onClick={() => {navigate(-1)}}
          icon={<ArrowLeftIcon width={22} height={22} color="#444050" strokeWidth="2.2"/>}
          title="Campaigns Details"
        />
        <div>
        {(passedCampaignItem?.status === "pending" || passedCampaignItem?.status === "drafted") && (
          <CommomButton
            onClick={(e) => handleEditCampaign(e)}
            icon={<NoteEditIcon width={17} height={17} color="#ffffff" strokeWidth="2.5"/>}
            title="Edit"
            className="btn-primary common-one-shadow"
          />
         )} 
        {(passedCampaignItem?.status === "pending" || passedCampaignItem?.status === "drafted" || passedCampaignItem?.status === "scheduled" || passedCampaignItem?.status === "failed" || passedCampaignItem?.status === "cancel") && (
          <CommomButton
            onClick={handleDeleteCampaign}
            icon={<Delete02Icon width={17} height={17} color="#ffffff" strokeWidth="2.5"/>}
            title="Delete"
            className="btn-danger common-one-shadow mx-1"
          />
         )}
        </div>
        </div>
        <Row
          className="mt-4"
        // onClick={handleClickOutside}
        >
          <Col xl={12} xxl={4}>
            <div className="grid gap-4 mb-4 mb-xxl-0">
              <Card className="rounded-2 g-col-12 g-col-lg-6 g-col-xxl-12 border-0 common-shadow w-100 common-card">
                <Card.Header className="px-0 pt-0 pb-4 bg-transparent border-0 common-card-header">
                  <div className="d-flex justify-content-between align-items-center gap-3">
                    <h5 className="fw-medium text-capitalize">
                      Customer Details
                    </h5>
                  </div>
                </Card.Header>
                <Card.Body className="p-0 common-card-body">
                  <Link
                    to=""
                    className="d-flex align-items-center gap-2 text-decoration-none mb-4 card-profile"
                  >
                    <div className=" rounded-circle d-flex align-items-center justify-content-center flex-shrink-1 user-icon">
                      <span className="text-uppercase fw-normal">
                        {getUserInitial(campaingDetails?.client_event?.name)}
                      </span>
                    </div>
                    <h6 className="fw-medium ms-1 text-capitalize">
                      {campaingDetails.client_event?.name}
                    </h6>
                  </Link>
                  <div className="d-flex flex-column gap-3 card-content">
                    <div>
                      <h6 className=" fw-medium mb-2 text-capitalize">
                        Contact info
                      </h6>
                      <p className="mb-1 fw-normal">
                        Email: {campaingDetails.client_event?.email}
                      </p>
                      <p className=" fw-normal">
                        Mobile: {campaingDetails.client_event?.contact_number}
                      </p>
                    </div>
                  </div>
                </Card.Body>
              </Card>
              <Card className="rounded-2 g-col-12 g-col-lg-6 g-col-xxl-12 border-0 common-shadow w-100 common-card">
                <Card.Header className="px-0 pt-0 pb-4 bg-transparent border-0 common-card-header">
                  <div>
                    <h5 className="fw-medium text-capitalize mb-1">
                      Campaigns Details
                    </h5>
                  </div>
                </Card.Header>
                <Card.Body className="p-0 common-card-body">
                  <div className="grid row-gap-3 column-gap-0 column-gap-xxl-2 card-content mb-4">
                    <div className="g-col-12 g-col-xl-6">
                      <h6 className=" fw-medium mb-1 text-capitalize">
                        Campaigns Name
                      </h6>
                      <div className="d-flex align-items-center gap-2">
                        <p className="fw-normal margin-top-4">
                          {campaingDetails.name ? campaingDetails.name : "-"}
                        </p>
                      </div>
                    </div>
                    <div className="g-col-12 g-col-xl-6">
                      <h6 className=" fw-medium mb-1 text-capitalize">
                        Campaigns Type
                      </h6>
                      <div className="d-flex align-items-center gap-2">
                        <p className="fw-normal margin-top-4">
                          {/* {campaingDetails?.template_type ? campaingDetails?.template_type === "text" ? "Message" : campaingDetails?.template_type === "pdf" ? "PDF" : campaingDetails?.template_type === "image" ? "Image" : campaingDetails?.template_type : "-"} */}
                          {campaingDetails?.template_type
                            ? campaingDetails?.template_type === "text"
                              ? "Without Invitation File"
                              : campaingDetails?.template_type === "pdf" ||
                                campaingDetails?.template_type === "image"
                              ? "With Invitation File"
                              : campaingDetails?.template_type
                            : "-"}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="grid row-gap-3 column-gap-0 column-gap-xxl-2 mb-3 mb-xxl-4">
                    <div className="g-col-12 g-col-xl-6">
                      <div className="d-flex align-items-center status-content primary-status">
                        <div className=" rounded-2 d-flex align-items-center justify-content-center status-icon">
                          <SentIcon
                            width={28}
                            height={28}
                            color="#A5596A"
                            strokeWidth="1.5"
                          />
                        </div>
                        <div>
                          <h6
                            className={`fw-medium text-capitalize  ${
                              campaingDetails.status ===
                                ("drafted" ||
                                  "Drafted" ||
                                  "scheduled" ||
                                  "Scheduled") && "purple-cr"
                            }  ${
                              campaingDetails.status ===
                                ("in-process" || "In-Process") && "yellow-cr"
                            } ${
                              campaingDetails.status ===
                                ("completed" || "Completed") && "green-cr"
                            } ${
                              campaingDetails.status ===
                                ("partially failed" || "Partially Failed") &&
                              "red-cr"
                            } ${
                              campaingDetails.status ===
                                ("failed" || "Failed") && "red-cr"
                            } ${
                              campaingDetails.status ===
                                ("cancel" || "Cancel") && "red-cr"
                            } `}
                          >
                            {campaingDetails.status}
                          </h6>
                          <span className="text-capitalize">Status</span>
                        </div>
                      </div>
                    </div>
                    <div className="g-col-12 g-col-xl-6">
                      <div className="d-flex align-items-center status-content primary-status">
                        <div className=" rounded-2 d-flex align-items-center justify-content-center status-icon">
                          <UserIcon
                            width={28}
                            height={28}
                            color="#A5596A"
                            strokeWidth="1.5"
                          />
                        </div>
                        <div>
                          <h6 className="fw-medium text-capitalize">
                            {activeSubTab === "guests"
                              ? campaingDetailsData?.total_non_tested
                              : campaingDetailsData?.total_tested || 0}
                          </h6>
                          <span className="text-capitalize">Recipient</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="grid row-gap-3 column-gap-0 column-gap-xxl-2 mb-3 mb-xxl-4">
                    <div className="g-col-12 g-col-xl-6">
                      <div className="d-flex align-items-center status-content primary-status">
                        <div className=" rounded-2 d-flex align-items-center justify-content-center status-icon">
                          <MessageDelay01Icon
                            width={28}
                            height={28}
                            color="#A5596A"
                            strokeWidth="1.5"
                          />
                        </div>
                        <div>
                          <h6 className="fw-medium text-capitalize">
                            {" "}
                            {campaingDetails?.delay_second_from
                              ? campaingDetails?.delay_second_from
                              : "-"}
                          </h6>
                          <span className="text-capitalize">Delay From</span>
                        </div>
                      </div>
                    </div>
                    <div className="g-col-12 g-col-xl-6">
                      <div className="d-flex align-items-center status-content primary-status">
                        <div className=" rounded-2 d-flex align-items-center justify-content-center status-icon">
                          <MessageDelay01Icon
                            width={28}
                            height={28}
                            color="#A5596A"
                            strokeWidth="1.5"
                          />
                        </div>
                        <div>
                          <h6 className="fw-medium text-capitalize">
                            {campaingDetails?.delay_second_to
                              ? campaingDetails?.delay_second_to
                              : "-"}
                          </h6>
                          <span className="text-capitalize">Delay To</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="grid row-gap-3 column-gap-0 column-gap-xxl-2 mb-3 mb-xxl-4">
                    <div className="g-col-12 g-col-xl-6">
                      <div className="d-flex align-items-center status-content primary-status">
                        <div className=" rounded-2 d-flex align-items-center justify-content-center status-icon">
                          <Coins01Icon
                            width={28}
                            height={28}
                            color="#A5596A"
                            strokeWidth="1.5"
                          />
                        </div>
                        <div>
                          <h6 className="fw-medium text-capitalize">
                            {campaingDetailsData?.total_sent || 0}
                          </h6>
                          <span className="text-capitalize">
                            Invitee Credit Used
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="g-col-12 g-col-xl-6">
                      <div className="d-flex align-items-center status-content primary-status">
                        <div className=" rounded-2 d-flex align-items-center justify-content-center status-icon">
                          <Coins02Icon
                            width={28}
                            height={28}
                            color="#A5596A"
                            strokeWidth="1.5"
                          />
                        </div>
                        <div>
                          <h6 className="fw-medium text-capitalize">
                            {campaingDetails?.client_guests_test_count}
                          </h6>
                          <span className="text-capitalize">
                            Test Credit Used
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="grid row-gap-3 column-gap-0 column-gap-xxl-2 card-content ">
                    <div className="g-col-12 g-col-xl-6">
                      <h6 className=" fw-medium mb-1 text-capitalize">
                        Created On
                      </h6>
                      <p className="fw-normal">
                        {moment(campaingDetails.created_at).format(
                          "DD/MM/YYYY hh:mm A"
                        )}
                      </p>
                    </div>
                    <div className="g-col-12 g-col-xl-6">
                      <h6 className=" fw-medium mb-1 text-capitalize">
                        Updated On
                      </h6>
                      <p className="fw-normal">
                        {moment(campaingDetails.updated_at).format(
                          "DD/MM/YYYY hh:mm A"
                        )}
                      </p>
                    </div>
                  </div>

                  <div className="grid row-gap-3 column-gap-0 column-gap-xxl-2 card-content mt-3">
                    <div className="g-col-12 g-col-xl-6">
                      <h6 className=" fw-medium mb-1 text-capitalize">
                        Country Code
                      </h6>
                      <p className="fw-normal">
                        {campaingDetails.country_code ? `+${campaingDetails.country_code}` : "-"}
                         
                      </p>
                    </div>
                 
                  </div>
                </Card.Body>
              </Card>

              {campaingDetails &&
                campaingDetails?.status &&
                campaingDetails?.status === "scheduled" && (
                  <Card className="rounded-2 g-col-12 g-col-lg-6 g-col-xxl-12 border-0 common-shadow w-100 common-card">
                    <Card.Header className="px-0 pt-0 pb-4 bg-transparent border-0 common-card-header">
                      <div>
                        <h5 className="fw-medium text-capitalize mb-1">
                          Schedule Details
                        </h5>
                      </div>
                    </Card.Header>
                    <Card.Body className="p-0 common-card-body">
                      <div className="grid row-gap-3 column-gap-0 column-gap-xxl-2 card-content">
                        <div className="g-col-12 g-col-xl-6">
                          <h6 className=" fw-medium mb-1 text-capitalize">
                            Schedule Date & Time
                          </h6>
                          <div className="d-flex align-items-center gap-2">
                            <p>
                              <Calendar03Icon
                                width={16}
                                height={16}
                                color="#A5596A"
                                strokeWidth="2"
                              />
                            </p>
                            <p className="fw-normal margin-top-4">
                              {campaingDetails?.scheduler_date
                                ? moment(
                                    campaingDetails?.scheduler_date
                                  ).format("DD-MM-YYYY hh:mm A")
                                : "-"}
                            </p>
                          </div>
                        </div>
                        <div className="g-col-12 g-col-xl-6">
                          <h6 className=" fw-medium mb-1 text-capitalize">
                            Sent Date & Time
                          </h6>
                          <div className="d-flex align-items-center gap-2">
                            <p>
                              <Calendar03Icon
                                width={16}
                                height={16}
                                color="#A5596A"
                                strokeWidth="2"
                              />
                            </p>
                            <p className="fw-normal margin-top-4">
                              {campaingDetails?.scheduler_date
                                ? moment(
                                    campaingDetails?.scheduler_date
                                  ).format("DD-MM-YYYY hh:mm A")
                                : "-"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                )}

              <Card className="rounded-2 g-col-12 g-col-lg-6 g-col-xxl-12 border-0 common-shadow w-100 common-card">
                <Card.Header className="px-0 pt-0 pb-4 bg-transparent border-0 common-card-header">
                  <div>
                    <h5 className="fw-medium text-capitalize mb-1">Message</h5>
                  </div>
                </Card.Header>
                <Card.Body className="p-0 common-card-body">
                  {campaingDetails.message ? (
                    <div className="d-flex flex-column gap-3 card-content mb-3 mb-xxl-4">
                      <p
                        className="fw-normal"
                        style={{ whiteSpace: "pre-wrap" }}
                      >
                        {campaingDetails.message}
                      </p>
                    </div>
                  ) : (
                    <div className="text-center w-100 blank-table-view">
                      <h5>No message available</h5>
                    </div>
                  )}
                </Card.Body>
              </Card>

              <Card className="rounded-2 g-col-12 g-col-lg-6 g-col-xxl-12 border-0 common-shadow w-100 common-card">
                <Card.Header className="px-0 pt-0 pb-4 bg-transparent border-0 common-card-header">
                  <h6 className=" fw-medium mb-3 text-capitalize">
                    Invitation/PDF File
                  </h6>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex flex-column">
                      <h6 className=" fw-medium mb-1 text-capitalize">
                        Actual PDF Name
                      </h6>
                      <p className="fw-normal margin-top-4">
                        {campaingDetails?.original_pdf_name
                          ? campaingDetails?.original_pdf_name
                          : "-"}
                      </p>
                    </div>
                    <div className="d-flex flex-column">
                      <h6 className=" fw-medium mb-1 text-capitalize">
                        Customize PDF Name
                      </h6>
                      <p className="fw-normal margin-top-4">
                        {campaingDetails?.pdf_name
                          ? campaingDetails?.pdf_name
                          : "-"}
                      </p>
                    </div>
                  </div>
                </Card.Header>
                <Card.Body className="p-0 common-card-body">
                  {documentUrl ? (
                    <div className="d-flex align-items-center justify-content-center gap-3 card-content">
                      <div
                        className={`${
                          documentUrl ? "pdf-view border overflow-hidden" : null
                        }`}
                      >
                        {campaingDetails?.template_type === "pdf" ? (
                          <>
                            <div
                              onClick={() => handleOpenPdfView()}
                              className="cursor-pointer h-100"
                            >
                              <Document
                                file={documentUrl}
                                onLoadSuccess={onDocumentLoadSuccess}
                                onLoadError={(error) =>
                                  console.log(
                                    "Error while loading document:",
                                    error
                                  )
                                }
                                onSourceError={(error) =>
                                  console.log(
                                    "Error while fetching document source:",
                                    error
                                  )
                                }
                                className="h-100 w-100"
                              >
                                <Page
                                  width={400}
                                  className="h-100 w-100"
                                  pageNumber={pdfCurrentPage}
                                  renderTextLayer={false}
                                  renderAnnotationLayer={false}
                                />
                                {/* {Array.from(new Array(numPages), (el, index) => (
                                    <Page
                                      key={`page_${index + 1}`}
                                      className={`h-100 w-100 ${isZoomed ? "shadow-xl" : ""}`}
                                      width={isZoomed ? 800 : 400} // Adjust width for zoomed/non-zoomed state
                                      pageNumber={isZoomed ? index + 1 : pdfCurrentPage} // Display current page in non-zoomed view
                                      renderTextLayer={false}
                                      renderAnnotationLayer={false}
                                    />
                                  ))} */}
                              </Document>
                            </div>
                            {/* </div>
                            </div> */}
                          </>
                        ) : campaingDetails?.template_type?.startsWith(
                            "image"
                          ) ? (
                          <Zoom>
                            <img
                              src={documentUrl}
                              alt="Campaign Document"
                              style={{ maxWidth: "100%" }}
                            />
                          </Zoom>
                        ) : (
                          <p>Unsupported document type</p>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="text-center w-100 blank-table-view">
                      <h5>No PDF available</h5>
                    </div>
                  )}
                </Card.Body>
              </Card>
            </div>
          </Col>
          <Col xl={12} xxl={8}>
            <div className="px-0 custpmize-grid gap-3 mb-4">
              <div>
                <SmallWidgets
                  count={
                    activeSubTab === "guests"
                      ? campaingDetailsData?.total_non_tested
                      : campaingDetailsData?.total_tested || 0
                  }
                  title="Total Recipients"
                  icon={
                    <Message01Icon
                      width={26}
                      height={26}
                      color="#444050"
                      strokeWidth="1.5"
                    />
                  }
                  onClick={() => handleStatusClick("all")}
                  className={activeTabStatus === "all" && "active"}
                />
              </div>
              <div>
                <SmallWidgets
                  count={campaingDetailsData?.total_pending || 0}
                  title="In Queue"
                  icon={
                    <MessageSearch01Icon
                      width={26}
                      height={26}
                      color="#444050"
                      strokeWidth="1.5"
                    />
                  }
                  onClick={() => handleStatusClick("pending")}
                  className={activeTabStatus === "pending" && "active"}
                />
              </div>
              <div>
                <SmallWidgets
                  count={campaingDetailsData?.total_sent || 0}
                  title="Sent"
                  icon={
                    <SentIcon
                      width={26}
                      height={26}
                      color="#444050"
                      strokeWidth="1.5"
                    />
                  }
                  onClick={() => handleStatusClick("sent")}
                  className={activeTabStatus === "sent" && "active"}
                />
              </div>
              <div>
                <SmallWidgets
                  count={campaingDetailsData?.total_delivered || 0}
                  title="Delivered"
                  icon={
                    <MessageDone01Icon
                      width={26}
                      height={26}
                      color="#444050"
                      strokeWidth="1.5"
                    />
                  }
                  onClick={() => handleStatusClick("delivered")}
                  className={activeTabStatus === "delivered" && "active"}
                />
              </div>
              <div>
                <SmallWidgets
                  count={campaingDetailsData?.total_read || 0}
                  title="Seen"
                  icon={
                    <MessagePreview01Icon
                      width={26}
                      height={26}
                      color="#444050"
                      strokeWidth="1.5"
                    />
                  }
                  onClick={() => handleStatusClick("seen")}
                  className={activeTabStatus === "seen" && "active"}
                />
              </div>
              <div>
                <SmallWidgets
                  count={campaingDetailsData?.total_failed || 0}
                  title="Failed"
                  icon={
                    <MessageCancel01Icon
                      width={26}
                      height={26}
                      color="#444050"
                      strokeWidth="1.5"
                    />
                  }
                  onClick={() => handleStatusClick("failed")}
                  className={activeTabStatus === "failed" && "active"}
                />
              </div>
              <div>
                <SmallWidgets
                  count={campaingDetailsData?.total_invalid || 0}
                  title="Invalid"
                  icon={
                    <MessageQuestionIcon
                      width={26}
                      height={26}
                      color="#444050"
                      strokeWidth="1.5"
                    />
                  }
                  onClick={() => handleStatusClick("invalid")}
                  className={activeTabStatus === "invalid" && "active"}
                />
              </div>
              <div>
                <SmallWidgets
                  count={campaingDetailsData?.not_seen || 0}
                  title="Not-Seen"
                  icon={
                    <MessagePreview02Icon
                      width={26}
                      height={26}
                      color="#444050"
                      strokeWidth="1.5"
                    />
                  }
                  onClick={() => handleStatusClick("not_seen")}
                  className={activeTabStatus === "not_seen" && "active"}
                />
              </div>
            </div>
            <div className="mb-4">
              <NavTabs
                defaultActiveKey={activeSubTab}
                id="uncontrolled-tab-example"
                onSelect={handleSubTabSelect}
                tabsData={CampaignsDataTabs}
              />
            </div>
            <div className="bg-white rounded-2 common-shadow custom-style-3">
              <div className="p-4 d-flex align-items-center justify-content-between gap-4">
                <Input
                  type="search"
                  value={searchTerm}
                  placeholder="Search"
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                    setCurrentPage(0);
                  }}
                  className="shadow-none base-search"
                />

                <div className="d-flex align-items-center justify-content-center gap-2 flex-wrap">
                   <CommomButton
                      onClick={() => handledownloadMultipleContact()} 
                      title="Download"
                      className="btn-primary common-one-shadow"
                      subClassName="gap-1"
                      disabled={selectedItems?.length === 0 || selectedItems?.length === 1}
                    />
                  {activeTabStatus === "failed" && showBtnIfArrHasPdfOrImg && (
                    <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 250 }} overlay={<TT id="button-tooltip">Dowload Failed File</TT>}>
                      <Button variant="link" onClick={() => downloadFailedZip(campaingDetailsData?.data, "failed")} disabled={campaingDetailsData?.data?.length === 0} className="btn-outline-primary-icon d-flex align-items-center justify-content-center">
                        <FileDownloadIcon width={17} height={17} color="#A5596A" strokeWidth="2.5"/>
                      </Button>
                    </OverlayTrigger>
                  )}

                  {activeTabStatus === "invalid" && showBtnIfArrHasPdfOrImg && (
                    <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 250 }} overlay={<TT id="button-tooltip">Dowload Invalid File</TT>}>
                      <Button
                        variant="link"
                        onClick={() => downloadFailedZip(campaingDetailsData?.data,"invalid")}
                        disabled={campaingDetailsData?.data?.length === 0}
                        className="btn-outline-primary-icon d-flex align-items-center justify-content-center"
                      >
                        <FileDownloadIcon width={17} height={17} color="#A5596A" strokeWidth="2.5"/>
                      </Button>
                    </OverlayTrigger>
                   )}

                  {isFailedSelected && (
                    <CommomButton
                      onClick={() => handleResendFailedClick()}
                      title="Resend"
                      className="btn-primary common-one-shadow"
                      subClassName="gap-1"
                      disabled={campaingDetailsData?.data?.length === 0}
                    />
                  )}

                  {isInvalidSelected && (
                    <CommomButton
                      onClick={() => handleOpenModal()}
                      title="Make a Copy"
                      className="btn-primary common-one-shadow"
                      subClassName="gap-1"
                      disabled={campaingDetailsData?.data?.length === 0}
                    />
                  )}

                  {campaingDetails?.status !== "drafted" && (
                    <>
                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 250 }}
                        overlay={<TT id="button-tooltip">Upload</TT>}
                      >
                        <Button
                          variant="link"
                          onClick={() => handleExportClick()}
                          className="btn-outline-primary-icon d-flex align-items-center justify-content-center"
                        >
                          <MessageUpload01Icon
                            width={17}
                            height={17}
                            color="#A5596A"
                            strokeWidth="2.5"
                          />
                        </Button>
                      </OverlayTrigger>

                      {isInvalidSelected && (
                        <OverlayTrigger
                          placement="bottom"
                          delay={{ show: 250, hide: 250 }}
                          overlay={<TT id="button-tooltip">Make a Copy</TT>}
                        >
                          <Button
                            variant="link"
                            onClick={() => handleOpenModal()}
                            disabled={campaingDetailsData?.data?.length === 0}
                            className="btn-outline-primary-icon d-flex align-items-center justify-content-center"
                          >
                            <Copy02Icon
                              width={17}
                              height={17}
                              color="#A5596A"
                              strokeWidth="2.5"
                            />
                          </Button>
                        </OverlayTrigger>
                      )}

                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 250 }}
                        overlay={<TT id="button-tooltip">Refresh</TT>}
                      >
                        <Button
                          variant="link"
                          onClick={() => handleRefreshClick()}
                          className="btn-outline-primary-icon d-flex align-items-center justify-content-center"
                        >
                          <ReloadIcon
                            width={17}
                            height={17}
                            color="#A5596A"
                            strokeWidth="2.5"
                          />
                        </Button>
                      </OverlayTrigger>
                    </>
                  )}
                </div>
              </div>
              <div style={{ maxHeight: '510px', overflowY: 'auto'}}>
              <Table responsive className="common-table">
                <thead>
                  <tr className="position-sticky top-0 z-2">
                    <th>
                      <div className="table-br">
                        {/* ignor this p Tag - Dont Remove p Tag and Do not Change Content */}
                        <p className="opacity-0">checkbox</p>
                        {/* <Checkbox
                          checked={isAllCurrentPageSelected}
                          onChange={(e) => handleSelectContacts(e,"all","item")}
                          className="sm-checkbox d-flex align-items-center"
                        /> */}
                      </div>
                    </th>
                    <th>
                      <div className="table-br">
                        {/* ignor this p Tag - Dont Remove p Tag and Do not Change Content */}
                        <p className="opacity-0">icon</p>
                      </div>
                    </th>

                    <th>
                      <div className="table-br">
                        <p>Id</p>
                      </div>
                    </th>

                    <th>
                      <div className="table-br">
                        <p>Name</p>
                      </div>
                    </th>

                    <th>
                      <div className="table-br">
                        <p>Mobile</p>
                      </div>
                    </th>

                    <th>
                      <div className="table-br">
                        <p>Status</p>
                      </div>
                    </th>

                    <th>
                      <div className="table-br">
                        <p>Sent Status</p>
                      </div>
                    </th>

                    <th>
                      <div className="table-br">
                        <p>Delivery Status</p>
                      </div>
                    </th>

                    <th>
                      <div className="table-br">
                        <p>Seen Status</p>
                      </div>
                    </th>
                    <th>
                      <div className="table-br">
                        <p>Download</p>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {tableLoading ? (
                    <tr>
                      <td colSpan="9">
                        <TableLoader />
                      </td>
                    </tr>
                  ) : campaingDetailsData?.data?.length === 0 ? (
                    <tr>
                      <td
                        colSpan="9"
                        className="text-center"
                        // style={{position: "relative", left: "330px"}}
                        // colSpan={getColumnsForActiveTab(activeTab).length + 1}
                      >
                        <div className="text-center w-100 blank-table-view">
                          <Image
                            src="/assets/images/component-common/blank-table.png"
                            alt="Blank-Table-Data-Image"
                            className="img-fluid"
                          />
                          <div className="mt-3">
                            <h5 className="fw-medium">
                              Couldn't find any data.
                            </h5>
                            <p className="pt-2 mt-1">
                              This report does not contain any points.
                              {/* Try to
                              change your filter and try again. */}
                            </p>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    campaingDetailsData?.data?.map((event, i) => {
                      return (
                        <tr key={i}>
                          <td className="table-check d-flex">
                            <Checkbox
                              onChange={(e) => handleSelectContacts(e,event)}
                              checked={selectedItems.some((contact) => contact.id === event.id)}
                              className="sm-checkbox d-flex align-items-center"
                            />

                          </td>
                         
                          <td className="table-svg">
                            {event?.status === "failed" ? (
                              <InformationCircleIcon
                                height={16}
                                width={16}
                                strokeWidth="2"
                                color="#ff4c51"
                              />
                            ) : event?.status === "sent" ? (
                              <SentIcon
                                height={16}
                                width={16}
                                strokeWidth="2"
                                color="#A5596A"
                              />
                            ) : event?.status === "read" ? (
                              <EyeIcon
                                height={16}
                                width={16}
                                strokeWidth="2"
                                color="#A5596A"
                              />
                            ) : event?.status === "delivered" ? (
                              <CheckmarkCircle04Icon
                                height={16}
                                width={16}
                                strokeWidth="2"
                                color="#28c76f"
                              />
                            ) : event?.status === "invalid" ? (
                              <MessageQuestionIcon
                                height={16}
                                width={16}
                                strokeWidth="2"
                                color="#ff4c51"
                              />
                            ) : event?.status === "pending" ? (
                              <Clock05Icon
                                height={16}
                                width={16}
                                strokeWidth="2"
                                color="#ff9f43"
                              />
                            ) : event?.status === "cancel" ? (
                              <CancelCircleHalfDotIcon
                                height={16}
                                width={16}
                                strokeWidth="2"
                                color="#ff4c51"
                              />
                            ) : (
                              <InformationCircleIcon
                                height={16}
                                width={16}
                                strokeWidth="2"
                                color="#A5596A"
                              />
                            )}
                          </td>
                          <td>
                            <div className="d-flex align-items-center gap-2">
                              <OverlayTrigger
                                placement="top"
                                overlay={<TT id="tooltip-top">{event?.failed_reason ? event?.failed_reason : event?.status}</TT>}>
                                  <Image
                                    src={event?.status === "failed"
                                        ? "/images/table-icon/failed.svg"
                                        : event?.status === "sent"
                                        ? "/images/table-icon/Send.svg"
                                        : event?.status === "read"
                                        ? "/images/table-icon/eye.svg"
                                        : event?.status === "delivered"
                                        ? "/images/table-icon/check.svg"
                                        : event?.status === "invalid"
                                        ? "/images/table-icon/invalid.svg"
                                        : event?.status === "pending" 
                                        ? "/images/table-icon/pending.svg"
                                        : event?.status === "cancel"
                                        ? "/images/table-icon/cancel.svg"
                                        : "/images/modal-icon/info-icon.svg"}
                                    color={"#000"}
                                    alt="info-icon"
                                    className="table-icon"
                                    width={15}
                                    height={15}
                                  />
                                  {/* // )} */}
                                </OverlayTrigger>

                                <div>
                                  {event?.order_number
                                    ? `#${event?.order_number}`
                                    : `#${i + 1 + currentPage * limit?.value}`}
                                </div>
                              </div>
                            </td>
                            {/* <td className="text-capitalize">
                            {event?.failed_reason || "-"}
                          </td> */}
                            <td className="text-capitalize">{event?.name}</td>
                            <td>{event?.mobile_number}</td>
                            {/* <td>
                            {new Date(
                              event?.campaign.created_at
                            ).toLocaleString("en-IN", {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                              hour: "2-digit",  
                              minute: "2-digit",
                              hour12: true,
                            })}
                          </td> */}
                            {/* <td>
                            {event?.campaign.scheduler_date
                              ? moment(event?.campaign?.scheduler_date).format(
                                  "DD/MM/YYYY hh:mm A"
                                )
                              : "-"}
                          </td> */}
                            <td className="text-capitalize">
                              {event?.status !== "pending" ? (
                                <StatusChip
                                  bg="chip-ornage"
                                  title={event.status}
                                  className="text-capitalize"
                                />
                              ) : (
                                <div>-</div>
                              )}
                            </td>

                          {/* <td>{event?.credits}</td> */}
                          <td>
                            {event?.whatsapp_sent
                              ? new Date(
                                  event?.whatsapp_sent * 1000
                                ).toLocaleString("en-IN", {
                                  day: "2-digit",
                                  month: "2-digit",
                                  year: "numeric",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                  hour12: true,
                                })
                              : "-"}
                          </td>
                          <td>
                            {event?.whatsapp_deliver
                              ? new Date(
                                  event?.whatsapp_deliver * 1000
                                ).toLocaleString("en-IN", {
                                  day: "2-digit",
                                  month: "2-digit",
                                  year: "numeric",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                  hour12: true,
                                })
                              : "-"}
                          </td>
                          <td>
                            {event?.whatsapp_seen
                              ? new Date(
                                  event?.whatsapp_seen * 1000
                                ).toLocaleString("en-IN", {
                                  day: "2-digit",
                                  month: "2-digit",
                                  year: "numeric",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                  hour12: true,
                                })
                              : "-"}
                          </td>
                          <td>
                          {/* <td  className="table-svg"> */}
                          {event?.campaign?.template_type === "pdf" && 
                              <OverlayTrigger placement="top" delay={{ show: 250, hide: 250 }} overlay={<TT id="button-tooltip">Download PDF</TT>}>
                              <button onClick={() => downloadEachGuestPdfHanlder(event)} className="download-pdf-btn cursor-pointer">
                                  <GreenDownloadIcon width={22} height={22} color="#7367f0" strokeWidth="2"/>
                              </button>
                            </OverlayTrigger>}
                          {/* </td> */}
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </Table>
              </div>

              <div className="p-3 mx-1 d-flex flex-wrap align-items-center justify-content-between gap-4">
                <p className="custom-table-infotext ">
                  Displaying {currentPage * limit?.value + 1} to{" "}
                  {Math.min((currentPage + 1) * limit?.value, total)} of {total}{" "}
                  Customers
                </p>
                <div className="d-flex gap-5">
                  {/* {totalPages > 1 ? (
                    <> */}
                  {campaingDetailsData?.data?.length !== 0 && (
                    <>
                      <CreatableSelect
                        isClearable
                        options={recordsPerPageOptions}
                        value={limit}
                        onChange={handleChange}
                        placeholder="Select or create"
                        menuPortalTarget={document.body} // Render the menu at the body level
                        menuPlacement="top" // Display the options above the input
                        styles={{
                          control: (base) => ({
                            ...base,
                            width: "170px", // Fixed width for the input field
                          }),
                          menu: (base) => ({
                            ...base,
                            width: "170px", // Fixed width for the dropdown menu
                          }),
                          menuPortal: (base) => ({
                            ...base,
                            zIndex: 9999, // Ensure it appears above everything
                          }),
                        }}
                      />

                      <CommonPagination
                        totalItems={total}
                        itemsPerPage={limit?.value}
                        currentPage={currentPage + 1}
                        onPageChange={handlePageChange}
                      />
                    </>
                  )}

                  {/* </>
                  // ) : null} */}
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <DeletePopup
          show={deleteCampaignModal}
          onHide={() => setDeleteCampaignModal(false)}
          icon={
            <Delete02Icon
              width={48}
              height={48}
              color="#ff4c51"
              strokeWidth="1.5"
            />
          }
          title="Confirm Delete?"
          content="Are you sure you want to delete this campaign?"
          removeTitle="Cancel"
          onClick={handleConfirmDeleteCampaign}
          disabled={isSubmitting}
          saveTitle={isSubmitting ? "Deleting..." : "Delete"}
        />

        <Modal
          show={showAddModal}
          centered
          dialogClassName="common-popup-dialog"
          contentClassName="common-popup-content"
          backdropClassName="common-popup-backdrop"
        >
          <Modal.Body className="common-popup-body">
            <div
              className="position-absolute end-0 z-2 bg-white rounded-1 cursor-pointer d-flex align-items-center justify-content-center  common-popup-colose-btn"
              onClick={handleModalClose}
            >
              <Cancel01Icon
                width={16}
                height={16}
                color="#BCBBC1"
                strokeWidth="2.5"
              />
            </div>
            <div className="mb-4 text-center common-popup-heading">
              <h4 className="text-capitalize mb-2 fw-medium">New Campaign</h4>
              <p>
                Are you sure, you want to make a new copy of {activeTabStatus} ?
              </p>
            </div>

            <div className="d-flex align-items-center justify-content-center gap-3 common-popup-btn">
              <CommomButton
                title={loading ? "Adding..." : "Add"}
                type="submit"
                disabled={loading}
                className="btn-primary primary-shadow"
                onClick={handleInvalidClick}
              />
              <CommomButton
                title="Cancel"
                onClick={handleModalClose}
                className="btn-muted"
                disabled={loading}
              />
            </div>
          </Modal.Body>
        </Modal>
        <PdfZoomViewModal
          show={openPdfModalView}
          handleClose={handleClosePdfViewModal}
          pdfPageWiseView={pdfPageWiseView}
          campaingDetails={campaingDetails}
        />
      </Container>
    </CommonLayout>
  );
}
