import React from "react";

export default function BaisicWidgets({ title, number, icon }) {
  return (
    <div className="baisic-widgets position-relative">
      <p className="fw-medium text-capitalize">{title}</p>
      <h5 className="fw-medium mt-3">{number}</h5>
      <div className=" position-absolute icon d-flex align-items-center justify-content-between">
        {icon}
      </div>
    </div>
  );
}
