import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosapi from "../utilities/axiosApi";

export const fetchCustomers = createAsyncThunk(
  "customer/fetchCustomers",
  async ({ offset, limit, search, sort_by_key, sort_by_value }, { rejectWithValue }) => {
    try {
      const response = await axiosapi.get("/api/reseller/customers", {
        params: { offset, limit, search, sort_by_key, sort_by_value },
      });
      return {
        data: response.data.data.data,
        total: response.data.data.total,
        currentPages: response.data.data.current_page,
        totalPages: response.data.data.total_pages,
      };
    } catch (err) {
      console.error("GET API Error:", err); 
      return rejectWithValue(err.response.data);
    }
  }
);

export const addCreditCustomer = createAsyncThunk(
  'reseller/addCreditCustomer',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosapi.post(`/api/reseller/customer-credit`, data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchCustomerCreditDetail = createAsyncThunk(
  "reseller/fetchCustomerCreditDetail",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosapi.get("/api/reseller/customer-credit-details/" + id);
      return response.data.data;
    }
    catch (err) {
      console.error("GET API Error:", err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchCustomerDetail = createAsyncThunk(
  "reseller/fetchCustomerDetail",
  async (id, {rejectWithValue}) => {
    try {
      const response = await axiosapi.get("/api/reseller/customer/" + id);
      return response.data.data;
    }
    catch (err) {
      console.error("GET API Error:", err);
      return rejectWithValue(err.response.data);
    }
  }
)

export const fetchCustomerCredits = createAsyncThunk(
  "customer/fetchCustomerCredits",
  async ({ id, offset, limit, search, sort_by_key, sort_by_value }, { rejectWithValue }) => {
    try {
      const response = await axiosapi.get("/api/reseller/customer-credits/" + id, {
        params: { id, offset, limit, search, sort_by_key, sort_by_value },
      });      
      return {
        data: response.data.data.data,
        total: response.data.data.total,
        currentPages: response.data.data.current_page,
        totalPages: response.data.data.total_pages,
      };
    }
    catch (err) {
      console.error("GET API Error:", err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchCustomerEvents = createAsyncThunk(
  "customer/fetchCustomerEvents",
  async ({ id, offset, limit, search, sort_by_key, sort_by_value }, { rejectWithValue }) => {
    try {
      const response = await axiosapi.get("/api/reseller/customer-events/" + id, {
        params: { id, offset, limit, search, sort_by_key, sort_by_value },
      });

      return {
        data: response.data.data.data,
        total: response.data.data.total,
        currentPages: response.data.data.current_page,
        totalPages: response.data.data.total_pages,
      };
    }
    catch (err) {
      console.error("GET API Error:", err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchClientDetail = createAsyncThunk(
  "reseller/fetchClientDetail",
  async (id, {rejectWithValue}) => {
    try {
      const response = await axiosapi.get("/api/reseller/client-event/" + id);
      return response.data.data;
    }
    catch (err) {
      console.error("GET API Error:", err);
      return rejectWithValue(err.response.data);
    }
  }
)

export const fetchCampaignDetails = createAsyncThunk(
  "campaign/fetchCampaignDetails",
  async ({ id, offset, limit, search, sort_by_key, sort_by_value }, { rejectWithValue }) => {
    try {
      const response = await axiosapi.get("/api/reseller/customer-campaigns/" + id, {
        params: { id, offset, limit, search, sort_by_key, sort_by_value },
      });
      return {
        data: response.data.data.data,
        total: response.data.data.total,
        currentPages: response.data.data.current_page,
        totalPages: response.data.data.total_pages,
      };
    }
    catch (err) {
      console.error("GET API Error:", err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchCampaignDetail = createAsyncThunk(
  "campaign/fetchCampaignDetail",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosapi.get("/api/admin/campaign/" + id);
      return response.data.data;
    }
    catch (err) {
      console.error("GET API Error:", err);
      return rejectWithValue(err.response.data);
    }
  }
)

export const fetchGuestDetails = createAsyncThunk( 
  "guest/fetchGuestDetails",
  async ({ id, offset, limit, search, sort_by_key, sort_by_value }, { rejectWithValue }) => {
    try {
      const response = await axiosapi.get("/api/admin/campaign-guests/" + id, {
        params: { id, offset, limit, search, sort_by_key, sort_by_value },
      });
      return {
        data: response.data.data.data,
        total: response.data.data.total,
        currentPages: response.data.data.current_page,
        totalPages: response.data.data.total_pages,
      };
    }
    catch (err) {
      console.error("GET API Error:", err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const addCustomer = createAsyncThunk(
  "customer/addCustomer",
  async (customerData, { rejectWithValue }) => {
    try {
      const response = await axiosapi.post("/api/reseller/customer", customerData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateCustomer = createAsyncThunk(
  "customer/updateCustomer",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosapi.post(`/api/reseller/customer/${data.get('id')}`, data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteCustomer = createAsyncThunk(
  "customer/deleteCustomer",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosapi.delete(`/api/reseller/customer/${id}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
    }
);

export const fetchCountry = createAsyncThunk(
  "reseller/fetchCountry",
  async () => {
    try {
      const response = await axiosapi.get("/api/countries");
      // console.log("RES : ", response);
      return response.data.data;
    } catch (err) {
      console.error("GET API Error:", err);
      return err.response.data;
    }
  }
);



const customerSlice = createSlice({
  name: "customer",
  initialState: {
    customers: [],
    countries: [],
    city:[],
    currentCustomer: {},
    customerEvents: [],
    customerCredits: [],
    currentClient:{},
    campaigns: [],
    currentCampaign:{},
    guests:[],
    creditCustomers:[],
    currentCreditDetail:{},
    total: 0,
    page: 1,
    limit: 10,
    status: 'idle',
    error: null,
    totalPages: 0
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomers.fulfilled, (state, action) => {
        state.customers = action.payload.data || []; 
        state.total = action.payload.total || 0; 
        state.page = action.payload.offset;
        state.limit = action.payload.limit;
        state.totalPages = action.payload.totalPages;
        state.search = action.payload.search;
      })
      .addCase(fetchCustomerDetail.fulfilled, (state, action) => {
        state.currentCustomer = action.payload;
      })
      .addCase(fetchCustomerEvents.fulfilled, (state, action) => {
        state.customerEvents = action.payload;
        state.total = action.payload.total || 0;
        state.page = action.payload.offset;
        state.limit = action.payload.limit;
        state.totalPages = action.payload.totalPages;
        state.search = action.payload.search;
      })
      .addCase(fetchCustomerCredits.fulfilled, (state, action) => {
        state.customerCredits = action.payload;
        state.total = action.payload.total || 0;
        state.page = action.payload.offset;
        state.limit = action.payload.limit;
        state.totalPages = action.payload.totalPages;
        state.search = action.payload.search;
      })
      .addCase(fetchClientDetail.fulfilled, (state, action) => {
        state.currentClient = action.payload;
      })
      .addCase(fetchCampaignDetails.fulfilled, (state, action) => {
        state.campaigns = action.payload;
        state.total = action.payload.total || 0;
        state.page = action.payload.offset;
        state.limit = action.payload.limit;
        state.totalPages = action.payload.totalPages;
        state.search = action.payload.search;
      })
      .addCase(fetchCampaignDetail.fulfilled, (state, action) => {
        state.currentCampaign = action.payload;
      })
      .addCase(fetchGuestDetails.fulfilled, (state, action) => {
        state.guests = action.payload;
        state.total = action.payload.total || 0;
        state.page = action.payload.offset;
        state.limit = action.payload.limit;
        state.totalPages = action.payload.totalPages;
        state.search = action.payload.search;
      })
      .addCase(fetchCustomerCreditDetail.fulfilled, (state, action) => {
        state.currentCreditDetail = action.payload;
      })
      .addCase(addCreditCustomer.fulfilled, (state, action) => {
        if (action.payload && action.payload.success) {
          state.creditCustomers.push(action.payload.data);
          state.total += 1;
        }
      })
      .addCase(addCustomer.fulfilled, (state, action) => {
        if (action.payload && action.payload.success){
          state.customers.push(action.payload);
          state.total += 1;
        }
      })      
      .addCase(updateCustomer.fulfilled, (state, action) => {
        const index = state.customers.findIndex((customer) => customer.id === action.payload.id);
        if (index !== -1) {
          state.customers[index] = {...state.customers[index], ...action.payload};
        }
      })
      .addCase(deleteCustomer.fulfilled, (state, action) => {
        state.customers = state.customers.filter(
          (r) => r.id !== action.payload.id
        );
        state.total -= 1;
      })
      .addCase(fetchCountry.fulfilled, (state, action) => {
        state.countries = action.payload;
      })  
  },
});

export default customerSlice.reducer;
