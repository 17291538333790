import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AdminLogin from "./container/pages/auth/Login";
import ForgotPassword from "./container/pages/auth/ForgotPassword";
import ResetPassword from "./container/pages/auth/ResetPassword";
import Dashboard from "./container/pages/dashboard/Dashboard";
import { AuthProvider } from "./context/AuthContext";
import Customers from "./container/pages/events/events";
import EventDetails from "./container/pages/eventsDetails/event";
// middlewares
// import ProtectedRoute from "./components/ProtectedRoute";
// import Campaign from "./container/pages/campaings/Campaigns";
import Add from "./container/pages/campaings/Add";
import CampaignDetails from "./container/pages/campaignDetails/CampaignDetails";
import ProtectedLayout from "./components/ProtectedLayout";
// import Wallet from './container/pages/wallet/WalletCredit'
import Wallet from "./container/pages/wallet/WalletCredit";
import Profile from "./container/pages/profile/ProfileDetail";
import { LoaderProvider } from "./context/LoaderContext";
import Loader from "./components/Loader";
import RechargeHistory from "./container/pages/recharge-history/RechargeHistory";
import CreditsLog from "./container/pages/CreditsLog/creditsLog";
import AllCampaigns from "./container/pages/allCampaigns/AllCampaigns";

import "./styles/index.css";

function App() {
  return (
    <AuthProvider>
      <LoaderProvider>
        <Loader />
        <Router>
          <Routes>
            <Route exact path="/" element={<AdminLogin />} />
            <Route exact path="/forgot-password" element={<ForgotPassword />} />
            <Route exact path="/reset-password" element={<ResetPassword />} />

            <Route path="/" element={<ProtectedLayout />}>
              <Route exact path="/dashboard" element={<Dashboard />} />
              <Route exact path="/customers" element={<Customers />} />
              <Route exact path="/customers/:id" element={<EventDetails />} />
              {/* <Route exact path="/campaigns" element={<Campaign />} /> */}
              {/* <Route
                exact
                path="customers/:clientId/add-campaign/:id"
                element={<Add />}
              /> */}
              <Route
                exact
                path="customers/new-campaign"
                element={<Add case_type='new' initial_step={0} />}
              />
              <Route
                exact
                path="customers/update-campaign/:campaignId"
                element={<Add case_type='update' initial_step={1}/>}
              />
              <Route
                exact
                path="customers/view-campaign/:id"
                element={<CampaignDetails />}
              />
              <Route exact path="/wallet" element={<Wallet />} />
              <Route exact path="/profile" element={<Profile />} />
              <Route
                exact
                path="/recharge-history"
                element={<RechargeHistory />}
              />
              <Route exact path="/credits-history" element={<CreditsLog />} />
              <Route exact path="/all-campaigns" element={<AllCampaigns />} />

              {/* Newly added routes for same pages to avoid conflict between selective modules in customer & campaign */}
              <Route exact path="/all-campaigns/view-campaign/:id" element={<CampaignDetails />}/>
              {/* <Route
                exact
                path="/all-campaigns/new-campaign"
                element={<Add case_type='new' initial_step={0} />}
              />
              <Route
                exact
                path="/all-campaigns/update-campaign/:campaignId"
                element={<Add case_type='update' initial_step={1}/>}
              /> */}
            </Route>
          </Routes>
        </Router>
      </LoaderProvider>
    </AuthProvider>
  );
}

export default App;
