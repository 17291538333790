import React, { useEffect, useState, useCallback } from "react";
// import Input from "../../../components/input/index";
import CommonPagination from "../../../components/common-pagination/index";
import Widget from "../../../components/widget/index";
import { useSelector, useDispatch } from "react-redux";
import {
  Table,
  Button,
  Toast,
  Container,
  Col,
  Row,
  ToastContainer,
  Image,
  //   Dropdown,
  //   Modal,
  // OverlayTrigger,
  // Tooltip,
} from "react-bootstrap";
// import {
//   StackPlus,
//   Coins,
//   StackMinus,
//   MoneyWavy,
//   // Calendar,
// } from "../../../constants/icons/icons";
// import { Coins } from "../../../constants/icons/icons";
// import {
//   fetchCustomers,
//   deleteCustomer,
//   fetchCountry,
//   toggleCustomerActiveStatus,
// } from "../../../store/customerSlice";
// import View from "./View";
// import { MoreVertical } from "react-feather";
// import PaginationComponent from "../pagination/pagination";
import "./style.css";
// import CustomerDetailModal from "./CustomerDetail";
// import { createSelector } from 'reselect';
// import { ArrowUp, ArrowDown } from "react-feather";
// import { CheckCircle } from "react-feather";
import moment from "moment";
import { useLoader } from "../../../context/LoaderContext";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
// import { fetchCreditsLog } from "../../../store/walletSlice";
import { fetchCreditLogBooks } from "../../../store/eventsSlice";
import {
  Add01Icon,
  // Building03Icon,
  // PlusMinusSquare01Icon,
  // MinusPlusSquare01Icon,
  // BacteriaIcon,
  MoneyAdd02Icon,
  MoneyRemove02Icon,
  MoneyReceiveFlow02Icon,
  Layers01Icon,
  CheckmarkBadge04Icon,
} from "../../../icons/icons";
import CreatableSelect from "react-select/creatable";
import { recordsPerPageOptions } from "../campaings/common/Common";

export default function List({ editHandler, handleOpenModal }) {
  const { setLoading } = useLoader();
  const dispatch = useDispatch();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("toast-success");
  //   const [currentCustomer, setCurrentCustomer] = useState(null);
  // const [showViewModal, setShowViewModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  // const limit = 10;
  // const [searchTerm, setSearchTerm] = useState("");
  // const firstRender = useRef(true);
  //   const [showCustomerDetailModal, setShowCustomerDetailModal] = useState(false);
  //   const countries = useSelector((state) => state.customer.countries);
  const creditLogBooks = useSelector((state) => state.events?.creditLogBooks);

  // console.log("creditLogBooks : ", creditLogBooks?.data);
  //   const [sortKey, setSortKey] = useState("");
  //   const [sortOrder, setSortOrder] = useState("asc");
  const { total } = useSelector((state) => ({
    total: state.events?.creditLogBooks?.total,
    // customers: state.customer.customers,
  }));
  //   const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  //   const [customerToDelete, setCustomerToDelete] = useState({});
  //   const [showToggleConfirmation, setShowToggleConfirmation] = useState(false);
  //   const [customerToToggle, setCustomerToToggle] = useState({});
  //   const [toggleStatus, setToggleStatus] = useState(null);

  const [limit, setLimit] = useState({ value: 10, label: 10 });

  const toastMessage = localStorage.getItem("toastMessage");

  const [dateRange, setDateRange] = useState({
    startDate: moment().subtract(29, "days"),
    endDate: moment(),
  });

  useEffect(() => {
    if (toastMessage) {
      setShowSnackbar(true);
      setSnackbarMessage(toastMessage);
      setSnackbarVariant("toast-success" || "toast-danger");
      localStorage.removeItem("toastMessage");
      if (toastMessage === "Validation failed") {
        setShowSnackbar(true);
        setSnackbarMessage(toastMessage);
        setSnackbarVariant("toast-danger");
        localStorage.removeItem("toastMessage");
      }
    }
  }, [toastMessage]);

  const getCreditsLog = useCallback(() => {
    // setLoading(true);

    dispatch(
      fetchCreditLogBooks({
        start_date: dateRange?.startDate.format("YYYY/MM/DD"),
        end_date: dateRange?.endDate.format("YYYY/MM/DD"),
        offset: currentPage,
        limit: limit?.value,
        // search: searchTerm,
      })
    )
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    dispatch,
    setLoading,
    dateRange?.startDate,
    dateRange?.endDate,
    currentPage,
    limit?.value,
    // searchTerm
  ]);

  useEffect(() => {
    getCreditsLog();
  }, [getCreditsLog]);

  const handleChange = (newValue) => {
    if (newValue) {
      // Update state with both value and label
      setLimit({ value: newValue.value, label: newValue.label });
    } else {
      // If the value is null (when user clears selection), set a default value
      setLimit({ value: 10, label: 10 }); // Set to default object
    }
  };

  const handlePageChange = (newPageNumber) => {
    setCurrentPage(newPageNumber - 1);
    window.scrollTo({ top: 0, behavior: "smooth" }); 
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick(e);
      }}
      className="text-muted text-primary-hover"
      role="button"
    >
      {children}
    </div>
  ));

  CustomToggle.displayName = "CustomToggle";

  //   const handleRowClick = (customer) => {
  //     setSearchTerm("");
  //     // setCurrentCustomer(customer);
  //     setShowCustomerDetailModal(true);
  //   };

  //   const ActionMenu = ({ customer }) => {
  //     return (
  //       <Dropdown drop="up" className=" position-static">
  //         <Dropdown.Toggle as={CustomToggle}>
  //           <MoreVertical size="15px" className="text-muted" />
  //         </Dropdown.Toggle>
  //         <Dropdown.Menu align={"end"}>
  //           <Dropdown.Item
  //             eventKey="1"
  //             onClick={(e) => {
  //               editHandler(customer.id);
  //               e.stopPropagation();
  //             }}
  //           >
  //             Edit
  //           </Dropdown.Item>
  //           <Dropdown.Item eventKey="2">
  //             View
  //           </Dropdown.Item>
  //           <Dropdown.Item
  //             eventKey="3"
  //             onClick={(e) => {
  //               e.stopPropagation();
  //               promptDeleteCustomer(customer);
  //             }}
  //           >
  //             Delete
  //           </Dropdown.Item>
  //         </Dropdown.Menu>
  //       </Dropdown>
  //     );
  //   };

  //   const getCountryName = (countryId) => {
  //     const country = countries.find((c) => c.id === countryId);
  //     return country ? country.name : "Not Found";
  //   };

  //   const handleSort = (key) => {
  //     const isAsc = sortKey === key && sortOrder === "asc";
  //     setSortKey(key);
  //     setSortOrder(isAsc ? "desc" : "asc");
  //   };

  //   const renderSortIcons = (key) => {
  //     const isActive = sortKey === key;
  //     return (
  //       <>
  //         <ArrowUp
  //           size={16}
  //           onClick={() => handleSort(key, "asc")}
  //           style={{
  //             cursor: "pointer",
  //             color: isActive && sortOrder === "asc" ? "black" : "#ccc",
  //           }}
  //         />
  //         <ArrowDown
  //           size={16}
  //           onClick={() => handleSort(key, "desc")}
  //           style={{
  //             cursor: "pointer",
  //             color: isActive && sortOrder === "desc" ? "black" : "#ccc",
  //           }}
  //         />
  //       </>
  //     );
  //   };

  //   const promptDeleteCustomer = (customer) => {
  //     setCustomerToDelete(customer);
  //     setShowDeleteConfirmation(true);
  //   };

  //   const handleDeleteCustomer = async () => {
  //     if (customerToDelete) {
  //       try {
  //         await dispatch(deleteCustomer(customerToDelete.id));
  //         setSnackbarMessage("Customer deleted successfully");
  //         setSnackbarVariant("success");
  //         dispatch(
  //           fetchCustomers({
  //             offset: currentPage * limit,
  //             limit,
  //             search: searchTerm,
  //           })
  //         );
  //       } catch (error) {
  //         setSnackbarMessage("Error deleting customer");
  //         setSnackbarVariant("danger");
  //       } finally {
  //         setShowSnackbar(true);
  //         setShowDeleteConfirmation(false);
  //         setCustomerToDelete(null);
  //       }
  //     }
  //   };

  //   const promptToggleConfirmation = (customer, status) => {
  //     setCustomerToToggle(customer);
  //     setToggleStatus(status);
  //     setShowToggleConfirmation(true);
  //   };

  //   const handleConfirmToggleActive = () => {
  //     if (customerToToggle) {
  //       dispatch(
  //         toggleCustomerActiveStatus({
  //           id: customerToToggle.id,
  //           isActive: toggleStatus.toString(),
  //         })
  //       )
  //         .then(() => {
  //           setSnackbarMessage(
  //             `Customer ${
  //               toggleStatus ? "activated" : "deactivated"
  //             } successfully`
  //           );
  //           setSnackbarVariant("success");
  //           getCustomerList();
  //         })
  //         .catch((error) => {
  //           setSnackbarMessage("Failed to update customer status");
  //           setSnackbarVariant("danger");
  //         })
  //         .finally(() => {
  //           setShowSnackbar(true);
  //           setShowToggleConfirmation(false);
  //           setCustomerToToggle(null);
  //           setToggleStatus(null);
  //         });
  //     }
  //   };

  //   const StatusDropdown = ({ customer }) => (
  //     <Dropdown>
  //       <Dropdown.Toggle
  //         size="sm"
  //         variant={customer.is_active ? "success" : "danger"}
  //         id="dropdown-basic"
  //       >
  //         {customer.is_active ? "Active" : "Inactive"}
  //       </Dropdown.Toggle>
  //       <Dropdown.Menu>
  //         <Dropdown.Item
  //           onClick={() =>
  //             promptToggleConfirmation(customer, !customer.is_active)
  //           }
  //         >
  //           Set {customer.is_active ? "Inactive" : "Active"}
  //         </Dropdown.Item>
  //       </Dropdown.Menu>
  //     </Dropdown>
  //   );

  const handleEvent = (event, picker) => {
    setCurrentPage(0);
    setDateRange({
      startDate: picker.startDate,
      endDate: picker.endDate,
    });
  };

  // console.log("limit", typeof limit, limit);
  // console.log("total",total);
  // console.log("TEST : : ", total > limit?.value)

  return (
    <>
      <ToastContainer position="top-end" className="p-3">
        <Toast
          onClose={() => setShowSnackbar(false)}
          show={showSnackbar}
          delay={5000}
          autohide
          bg={snackbarVariant}
          className="position-fixed top-3 end-0 me-3 shadow-none"
        >
          <Toast.Body className="d-flex align-items-center gap-1 lh-sm">
            <CheckmarkBadge04Icon
              width={18}
              height={18}
              color="#28c76f"
              strokeWidth="2"
            />
            <span className="text-capitalize">{snackbarMessage}</span>
          </Toast.Body>
        </Toast>
      </ToastContainer>

      <Container fluid className="px-0">
        {/* <Row>
          <Col xl={12} lg={12} md={12} xs={12} className="mt-6">
            <div className="position-relative overflow-hidden border-radius-8 tablecontainer">
              <div className="mb-2 d-flex justify-content-start">
                <DateRangePicker
                  initialSettings={{
                    startDate: dateRange.startDate.toDate(),
                    endDate: dateRange.endDate.toDate(),
                    ranges: {
                      Today: [moment(), moment()],
                      Yesterday: [
                        moment().subtract(1, "days"),
                        moment().subtract(1, "days"),
                      ],
                      "Last 7 Days": [moment().subtract(6, "days"), moment()],
                      "Last 30 Days": [moment().subtract(29, "days"), moment()],
                      "This Month": [
                        moment().startOf("month"),
                        moment().endOf("month"),
                      ],
                      "Last Month": [
                        moment().subtract(1, "month").startOf("month"),
                        moment().subtract(1, "month").endOf("month"),
                      ],
                      "Last 3 Months": [
                        moment().subtract(3, "month").startOf("month"),
                        moment().subtract(1, "month").endOf("month"),
                      ],
                      "Last 6 Months": [
                        moment().subtract(6, "month").startOf("month"),
                        moment().subtract(1, "month").endOf("month"),
                      ],
                      "This Year": [
                        moment().startOf("year"),
                        moment().endOf("year"),
                      ],
                    },
                    locale: {
                      format: "YYYY-MM-DD",
                    },
                  }}
                  onApply={(e, _) => handleEvent(e, _)}
                >
                  <Button variant="primary" className="">
                    {dateRange.startDate.format("DD/MM/YYYY")} -{" "}
                    {dateRange.endDate.format("DD/MM/YYYY")}
                  </Button>
                </DateRangePicker>
              </div>
              <Row className="mb-4 g-4 justify-content-center cerdit-logs-content">
                <Col xxl={2} xl={2} lg={4} md={6} xs={12}>
                  <div className="p-4 rounded-3 cedit-h-card h-100">
                    <div className="d-flex align-items-start justify-content-between gap-3">
                      <div>
                        <p className="mb-0 text-capitalize">Opening Balance</p>
                        <span>{creditLogBooks?.opening_balance || 0}</span>
                      </div>
                      <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1">
                        <Coins size={20} color="#624BFF" weight="duotone" />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col className="align-content-center" xs="auto">
                  <h2 className=" align-items-center mb-0">+</h2>
                </Col>
                <Col xxl={2} xl={2} lg={4} md={6} xs={12}>
                  <div className="p-4 rounded-3 cedit-h-card  h-100">
                    <div className="d-flex align-items-start justify-content-between gap-3">
                      <div>
                        <p className="text-capitalize">Purchased Credit</p>
                        <span>{creditLogBooks?.total_credits || 0}</span>
                      </div>
                      <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1">
                        <StackPlus size={20} color="#624BFF" weight="duotone" />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col className="align-content-center" xs="auto">
                  <h2 className="mb-0">-</h2>
                </Col>
                <Col xxl={2} xl={2} lg={4} md={6} xs={12}>
                  <div className="p-4 rounded-3 cedit-h-card  h-100">
                    <div className="d-flex align-items-start justify-content-between gap-3">
                      <div>
                        <p className="text-capitalize">Sold Credit</p>
                        <span>{creditLogBooks?.total_debits || 0}</span>
                      </div>
                      <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1">
                        <StackMinus
                          size={20}
                          color="#624BFF"
                          weight="duotone"
                        />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col className="align-content-center" xs="auto">
                  <h2 className="mb-0">=</h2>
                </Col>
                <Col xxl={2} xl={2} lg={4} md={6} xs={12}>
                  <div className="p-4 rounded-3 cedit-h-card  h-100">
                    <div className="d-flex align-items-start justify-content-between gap-3">
                      <div>
                        <p className="text-capitalize">Closing Balance</p>
                        <span>
                          {creditLogBooks?.total_credits -
                            creditLogBooks?.total_debits || 0}
                        </span>
                      </div>
                      <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1">
                        <MoneyWavy size={20} color="#624BFF" weight="duotone" />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <div className="table-responsive rounded-2 custom-tbl">
                <table className="w-100 rounded-2 overflow-hidden">
                  <thead>
                    <tr>
                      <th className="fw-medium">
                        <div className="d-flex align-items-center gap-th">
                          <p className="mb-0">Date</p>
                        </div>
                      </th>
                      <th className="fw-medium">
                        <div className="d-flex align-items-center gap-th">
                          <p className="mb-0">Description</p>
                        </div>
                      </th>
                      <th className="fw-medium">
                        <div className="d-flex align-items-center gap-th">
                          <p className="mb-0">Message Credit</p>
                        </div>
                      </th>
                      <th className="fw-medium">
                        <div className="d-flex align-items-center gap-th">
                          <p className="mb-0">Message Debit</p>
                        </div>
                      </th>
                      <th className="fw-medium">
                        <div className="d-flex align-items-center gap-th">
                          <p className="mb-0">Balance</p>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="cu-tbody">
                    {creditLogBooks?.data?.length > 0 ? (
                      creditLogBooks?.data?.map((credit, i) => (
                        <tr key={i} className="align-middle data_row">
                          <td>
                            {moment(credit.created_at).format(
                              "DD/MM/YYYY hh:mm A"
                            )}
                          </td>
                          <td>
                            {credit.description
                              ? credit.description
                              : credit?.is_credited
                                ? "Credits added by Reseller"
                                : `Credits transferred to ${credit?.customer?.name}`}
                          </td>
                          {credit.is_credited && credit.credits ? (
                            <td>{credit.is_credited && credit.credits}</td>
                          ) : (
                            <td>0</td>
                          )}
                          {!credit.is_credited && credit.credits ? (
                            <td>{!credit.is_credited && credit.credits}</td>
                          ) : (
                            <td>0</td>
                          )}
                          {credit?.opening_balance ? (
                            <td>{credit.opening_balance}</td>
                          ) : (
                            <td>0</td>
                          )}
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="6" className="text-center border-bottom-0">
                          <p className="my-5  no-data text-capitalize">
                            No data available
                          </p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td>
                        <div className="d-flex align-items-center gap-th">
                          <p className="mb-0">Total</p>
                        </div>
                      </td>
                      <td></td>
                      <td>
                        <div className="d-flex align-items-center gap-th">
                          <p className="mb-0">{creditLogBooks.total_credits}</p>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center gap-th">
                          <p className="mb-0">{creditLogBooks.total_debits}</p>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center gap-th">
                          <p className="mb-0">0</p>
                        </div>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
              {total > limit && (
                <PaginationComponent
                  totalItems={total}
                  itemsPerPage={limit}
                  currentPage={currentPage + 1}
                  onPageChange={handlePageChange}
                />
              )}
            </div>
          </Col>
        </Row> */}
        <div className="bg-white p-4 rounded-2 common-shadow common-tab-spacing">
          <Row className="px-0 organization-list-content">
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={6}
              xl={4}
              xxl={3}
              className="organization-widget"
            >
              <Widget
                count={creditLogBooks?.opening_balance || 0}
                title="Opening Balance"
                icon={
                  <Layers01Icon
                    width={26}
                    height={26}
                    color="#444050"
                    strokeWidth="1.5"
                  />
                }
                className="w-100 "
              />
              <div className="hr-line my-3 d-xl-none"></div>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={6}
              xl={4}
              xxl={3}
              className="organization-widget"
            >
              <Widget
                count={creditLogBooks?.total_credits || 0}
                title="Purchased Credit"
                icon={
                  <MoneyAdd02Icon
                    width={26}
                    height={26}
                    color="#444050"
                    strokeWidth="1.5"
                  />
                }
                className="w-100"
              />
              <div className="hr-line my-3 d-lg-none"></div>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={6}
              xl={4}
              xxl={3}
              className="organization-widget"
            >
              <Widget
                count={creditLogBooks?.total_debits || 0}
                title="Sold Credit"
                icon={
                  <MoneyRemove02Icon
                    width={26}
                    height={26}
                    color="#444050"
                    strokeWidth="1.5"
                  />
                }
                className="w-100"
              />
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={6}
              xl={4}
              xxl={3}
              className="organization-widget"
            >
              <Widget
                count={creditLogBooks?.closing_balance || 0}
                title="Closing Balance"
                icon={
                  <MoneyReceiveFlow02Icon
                    width={26}
                    height={26}
                    color="#444050"
                    strokeWidth="1.5"
                  />
                }
                className="w-100"
              />
            </Col>
          </Row>
        </div>
        <div className="bg-white rounded-2 overflow-hidden common-shadow">
          <div className="coomon-table-action-head d-flex align-items-center justify-content-end gap-4">
            {/* <Input
              type="search"
              value={searchTerm}
              placeholder="Search Credits History"
              onChange={(e) => {
                setSearchTerm(e.target.value);
                setCurrentPage(0);
              }}
              className="shadow-none base-search"
            /> */}
            <DateRangePicker
              initialSettings={{
                startDate: dateRange.startDate.toDate(),
                endDate: dateRange.endDate.toDate(),
                ranges: {
                  Today: [moment(), moment()],
                  Yesterday: [
                    moment().subtract(1, "days"),
                    moment().subtract(1, "days"),
                  ],
                  "Last 7 Days": [moment().subtract(6, "days"), moment()],
                  "Last 30 Days": [moment().subtract(29, "days"), moment()],
                  "This Month": [
                    moment().startOf("month"),
                    moment().endOf("month"),
                  ],
                  "Last Month": [
                    moment().subtract(1, "month").startOf("month"),
                    moment().subtract(1, "month").endOf("month"),
                  ],
                  "Last 3 Months": [
                    moment().subtract(3, "month").startOf("month"),
                    moment().subtract(1, "month").endOf("month"),
                  ],
                  "Last 6 Months": [
                    moment().subtract(6, "month").startOf("month"),
                    moment().subtract(1, "month").endOf("month"),
                  ],
                  "This Year": [
                    moment().startOf("year"),
                    moment().endOf("year"),
                  ],
                },
                locale: {
                  format: "YYYY-MM-DD",
                },
              }}
              onApply={(e, _) => handleEvent(e, _)}
            >
              <Button
                variant="primary"
                className=" border-0 rounded-2 text-decoration-none common-btn btn-primary common-one-shadow"
              >
                <div className="d-flex align-items-center justify-content-center common-btn-gap">
                  <span>
                    <Add01Icon
                      width={16}
                      height={16}
                      color="#ffffff"
                      strokeWidth="2"
                    />
                  </span>
                  <div className="fw-medium text-white text-capitalize">
                    {dateRange.startDate.format("DD/MM/YYYY")} -{" "}
                    {dateRange.endDate.format("DD/MM/YYYY")}
                  </div>
                </div>
              </Button>
            </DateRangePicker>
          </div>

          <div>
            <Table responsive className="common-table">
              <thead>
                <tr className="position-sticky top-0 z-2">
                  <th>
                    <div className="table-br">
                      <p>Date</p>
                    </div>
                  </th>
                  <th>
                    <div className="table-br">
                      <p>Description</p>
                    </div>
                  </th>
                  <th>
                    <div className="table-br">
                      <p>Message Credit</p>
                    </div>
                  </th>
                  <th>
                    <div className="table-br">
                      <p>Message Debit</p>
                    </div>
                  </th>
                  <th>
                    <div className="table-br">
                      <p>Balance</p>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {creditLogBooks?.data?.length === 0 ? (
                  <tr>
                    <td
                      className="text-center"
                      colSpan="7"
                      // colSpan={getColumnsForActiveTab(activeTab).length + 1}
                    >
                      <div className=" text-center w-100 blank-table-view">
                        <Image
                          src="/assets/images/component-common/blank-table.png"
                          alt="Blank-Table-Data-Image"
                          className="img-fluid"
                        />
                        <div className="mt-3">
                          <h5 className="fw-medium">Couldn't find any data.</h5>
                          <p className="pt-2 mt-1">
                            This report does not contain any points.
                            {/* Try to change your filter and try again. */}
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                ) : (
                  creditLogBooks?.data?.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>
                          {moment(item.created_at).format("DD/MM/YYYY hh:mm A")}
                        </td>

                        <td>
                          {item.description
                            ? item.description
                            : item?.is_credited
                            ? "Credits added by Reseller"
                            : `Credits transferred to ${item?.customer?.name}`}
                        </td>

                        <td>
                          {item.is_credited && item.credits
                            ? item.is_credited && item.credits
                            : "0"}
                        </td>

                        <td>
                          {!item.is_credited && item.credits
                            ? !item.is_credited && item.credits
                            : "0"}
                        </td>

                        <td>
                          {item?.opening_balance ? item.opening_balance : "0"}
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={2}>
                    <p>Total</p>
                  </td>
                  <td>
                    <p>{creditLogBooks.total_credits}</p>
                  </td>
                  <td>
                    <p>{creditLogBooks.total_debits}</p>
                  </td>
                  <td>
                    <p>0</p>
                  </td>
                </tr>
              </tfoot>
            </Table>
          </div>

          <div className="common-table-foot-new mx-1 d-flex flex-wrap align-items-center justify-content-between gap-4">
            <p className="custom-table-infotext ">
              Displaying {currentPage * limit?.value + 1} to{" "}
              {Math.min((currentPage + 1) * limit?.value, total)} of {total}{" "}
              Credits History
            </p>
            <div className="d-flex gap-4">
              {total > limit?.value && (
                <>
                  <CreatableSelect
                    isClearable
                    options={recordsPerPageOptions}
                    value={limit}
                    onChange={handleChange}
                    placeholder="Select or create"
                    menuPortalTarget={document.body} // Render the menu at the body level
                    menuPlacement="top" // Display the options above the input
                    className="custom-pagination-visible-item"
                    styles={{
                      control: (base) => ({
                        ...base,
                        width: "170px", // Fixed width for the input field
                      }),
                      menu: (base) => ({
                        ...base,
                        width: "170px", // Fixed width for the dropdown menu
                      }),
                      menuPortal: (base) => ({
                        ...base,
                        zIndex: 9999, // Ensure it appears above everything
                      }),
                    }}
                  />
                  <CommonPagination
                    totalItems={total}
                    itemsPerPage={limit?.value}
                    currentPage={currentPage + 1}
                    onPageChange={handlePageChange}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </Container>

      {/* {currentCustomer && (
        <CustomerDetailModal
          show={showCustomerDetailModal}
          handleCustomerDetailClose={() => setShowCustomerDetailModal(false)}
          customer={currentCustomer}
        />
      )} */}

      {/* {currentCustomer && (
        <View
          show={showViewModal}
          handleClose={() => setShowViewModal(false)}
          customer={currentCustomer}
        />
      )} */}
    </>
  );
}
