import React from "react";
import { Button } from "react-bootstrap";

export default function CommomButton({
  icon,
  type,
  title,
  onClick,
  disabled,
  className,
  onMouseDown,
  subClassName,
}) {
  return (
    <Button
      variant="link"
      type={type}
      onClick={onClick}
      disabled={disabled}
      onMouseDown={onMouseDown} 
      className={`border-none rounded-2 text-decoration-none common-btn ${className}`}
    >
      <div
        className={`d-flex align-items-center justify-content-center common-btn-gap ${subClassName}`}
      >
        {icon && <span>{icon}</span>}
        {title &&  <div className="fw-medium">{title}</div>}
      </div>
    </Button>
  );
}
