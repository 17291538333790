import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosapi from "../utilities/axiosApi";

export const fetchEvents = createAsyncThunk(
  "events/fetchEvents",
  async ({ offset, limit, search, id }, { rejectWithValue }) => {
    try {
      const response = await axiosapi.get(`/api/customer/clients/${id}`, {
        params: { offset, limit, search },
      });

      // console.log("Events : ", response);
      return {
        data: response.data.data.data,
        total: response.data.data.total,
        currentPages: response.data.data.current_page,
        totalPages: response.data.data.total_pages,
      };
    } catch (err) {
      console.error("GET API Error:", err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const addCampaign = createAsyncThunk(
  "events/addCampaign",
  async (campaingData, { rejectWithValue }) => {
    try {
      const response = await axiosapi.post(
        "/api/customer/campaign",
        campaingData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const cloneCampaign = createAsyncThunk(
  "events/cloneCampaign",
  async ({id}, { rejectWithValue }) => {
    try {
      const response = await axiosapi.post(
        "/api/customer/campaign-clone/" + id 
      );

      console.log("STore Response For cLone :::", response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteCampaign = createAsyncThunk(
  "events/deleteCampaign",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await axiosapi.delete(
        "/api/customer/campaign/" + id
        // campaingData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const cancelCampaign = createAsyncThunk(
  "events/cancelCampaign",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await axiosapi.post(
        "/api/customer/campaign-status-change",
        {
          campaign_id: id
        }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteCustomer = createAsyncThunk(
  "events/deleteCustomer",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await axiosapi.delete(
        "/api/customer/client-event/" + id
        // campaingData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchEventDetail = createAsyncThunk(
  "events/fetchEventDetail",
  async ({ offset, limit, search, id, status }, { rejectWithValue }) => {
    // console.log('Id : ', id);
    let stat = status === "all" ? "" : status;
    try {
      const response = await axiosapi.get(`/api/customer/campaigns/${id}`, {
        params: { offset, limit, search, status: stat },
      });

      // console.log('campaingsData API : ', response);
      return {
        total: response.data.data.total,
        currentPages: response.data.data.current_page,
        totalPages: response.data.data.total_pages,
        campaingsData: response.data.data.data,
      };
    } catch (err) {
      console.error("GET API Error:", err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchCustomer = createAsyncThunk(
  "events/fetchCustomer",
  async ({ id }, { rejectWithValue }) => {
    // console.log('Id : ', id);
    
    try {
      const response = await axiosapi.get(`/api/customer/client-event/${id}`, {
        // params: { offset, limit, search, status: stat },
      });

      // console.log('campaingsData API : ', response);
      return {
        // total: response.data.data.total,
        // currentPages: response.data.data.current_page,
        // totalPages: response.data.data.total_pages,
        event: response.data.data,
      };
    } catch (err) {
      console.error("GET API Error:", err);
      return rejectWithValue(err.response.data);
    }
  }
);



export const fetchAllCampaigns = createAsyncThunk(
  "events/fetchAllCampaigns",
  async ({ offset, limit, search, status }, { rejectWithValue }) => {
    // console.log('Id : ', id);
    let stat = status === "all" ? "" : status;
    try {
      const response = await axiosapi.get(`/api/customer/all-campaigns`, {
        params: { offset, limit, search, status: stat },
      });

      // console.log("campaingsData API : ", response);
      return {
        allCampaings: response.data.data,
      };
    } catch (err) {
      console.error("GET API Error:", err);
      return rejectWithValue(err.response.data);
    }
  }
);
export const fetchCampaignDetail = createAsyncThunk(
  "events/fetchCampaignDetail",
  async (
    { offset, limit, search, id, is_test, status },
    { rejectWithValue }
  ) => {
    // console.log('Campaign Details ID. : ', id);
    try {
      // console.log('campaingDetailsData API : ');
      const response = await axiosapi.get(
        `/api/customer/campaign-guests/${id}`,
        {
          params: { offset, limit, search, is_test, status },
        }
      );

      // console.log("campaingDetailsData API : ", response);
      return {
        total: response.data.data.total,
        currentPages: response.data.data.current_page,
        totalPages: response.data.data.total_pages,
        campaingDetailsData: response.data.data,
      };
    } catch (err) {
      console.error("GET API Error:", err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchAllCampaignDetail = createAsyncThunk(
  "events/fetchAllCampaignDetail",
  async (
    { offset, limit, search, id, is_test, status },
    { rejectWithValue }
  ) => {
    // console.log('Campaign Details ID. : ', id);
    try {
      // console.log('campaingDetailsData API : ');
      const response = await axiosapi.get(
        `/api/customer/campaign-guests/${id}`,
        {
          params: { offset, limit, search, is_test, status },
        }
      );

      // console.log("campaingDetailsData API : ", response);
      return {
        total: response.data.data.total,
        currentPages: response.data.data.current_page,
        totalPages: response.data.data.total_pages,
        campaingDetailsData: response.data.data,
      };
    } catch (err) {
      console.error("GET API Error:", err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchCampaignById = createAsyncThunk(
  "events/fetchCampaignById",
  async ({ offset, limit, search, id }, { rejectWithValue }) => {
    // console.log('Campaign Details ID. : ', id);
    try {
      // console.log('campaingDetailsData API : ');
      const response = await axiosapi.get(`/api/customer/campaign/${id}`, {
        // params: { offset, limit, search },
      });

      // console.log("campaingDetailsData API : ", response);
      return {
        // total: response.data.data.total,
        // currentPages: response.data.data.current_page,
        // totalPages: response.data.data.total_pages,
        campaingDetails: response?.data.success ? response?.data?.data : {},
      };
    } catch (err) {
      console.error("GET API Error:", err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const addReseller = createAsyncThunk(
  "events/addReseller",
  async (resellerData, { rejectWithValue }) => {
    // console.log("Events : ", resellerData);
    try {
      const response = await axiosapi.post(
        "/api/customer/campaign",
        resellerData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const addEvent = createAsyncThunk(
  "events/addEvent",
  async (resellerData, { rejectWithValue }) => {
    // console.log("Event Data : ", resellerData);
    try {
      const response = await axiosapi.post(
        "/api/customer/client-event",
        resellerData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateCustomer = createAsyncThunk(
  "events/updateCustomer",
  async ({ id, data }, { rejectWithValue }) => {
    // console.log("Event Data : ", data);
    try {
      const response = await axiosapi.post(
        "/api/customer/client-event/" + id,
        data
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateEvent = createAsyncThunk(
  "events/updateEvent",
  async (data, { rejectWithValue }) => {
    try {
      // console.log(data);
      const response = await axiosapi.post(
        `/api/customer/client_campaign/${data.get("client_campaign_id")}`,
        data
      );
      // console.log('update : ', response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteReseller = createAsyncThunk(
  "events/deleteReseller",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosapi.delete(`/api/reseller/${id}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchCountry = createAsyncThunk(
  "reseller/fetchCountry",
  async () => {
    try {
      const response = await axiosapi.get("/api/countries");
      return response.data.data;
    } catch (err) {
      console.error("GET API Error:", err);
      return err.response.data;
    }
  }
);
export const updateMessageStatus = createAsyncThunk(
  "events/updateMessageStatus",
  async ({ wsId, statusUpdate }, { dispatch, getState }) => {
    const state = getState();
    const campaingDetailsData = state.events.campaingDetailsData.map((guest) =>
      guest.wsId === wsId ? { ...guest, status: statusUpdate } : guest
    );
    return { campaingDetailsData };
  }
);

export const fetchDashboardData = createAsyncThunk(
  "events/fetchDashboardData",
  async ({ rejectWithValue }) => {
    try {
      // console.log(" Start");
      const response = await axiosapi.get("/api/customer/dashboard-counts", {
        // params: { sort_by_key },
      });
      // console.log("vcgha: ", response);
      return {
        data: response.data.data,
        // total: response.data.data.total,
        // currentPages: response.data.data.current_page,
        // totalPages: response.data.data.total_pages
      };
    } catch (err) {
      console.error("GET API Error:", err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchCreditLogBooks = createAsyncThunk(
  "events/fetchCreditLogBooks",
  async (
    { sort_by_key, sort_by_value, start_date, end_date, offset, limit, search },
    { rejectWithValue }
  ) => {
    try {
      // console.log("Start");
      const response = await axiosapi.get("/api/customer/credit-logbooks", {
        params: {
          sort_by_key,
          sort_by_value,
          start_date,
          end_date,
          offset,
          limit,
          search,
        },
      });
      // console.log("vcgha: ", response);
      return {
        data: response.data.data,
        total: response.data.data.total,
        currentPages: response.data.data.current_page,
        totalPages: response.data.data.total_pages,
      };
    } catch (err) {
      console.error("GET API Error:", err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const organizationType = createAsyncThunk(
  "events/organizationType",
  async () => {
    try {
      const response = await axiosapi.get("/api/customer/all-organization-types");
      // console.log("RES : ", response?.data?.data);
      return response.data.data;
    } catch (err) {
      console.error("GET API Error:", err);
      return err.response.data;
    }
  }
);

export const fetchStates = createAsyncThunk(
  "events/fetchStates",
  async () => {
    try {
      const response = await axiosapi.get("/api/states");
      // console.log("RES : ", response?.data?.data);
      return response.data.data;
    } catch (err) {
      return err.response.data;
    }
  }
);

export const fetchCityByStateId = createAsyncThunk("events/fetchCityByStateId", async (state_id, { rejectWithValue }) => {
    try {
      const response = await axiosapi.post("/api/cities", { state_id: state_id });
      return response.data.data;
    } catch (err) {
      return err.response.data;
    }
  }
);

export const updateProfile = createAsyncThunk("events/updateProfile",async (data, { rejectWithValue }) => {
  try {
    const response = await axiosapi.post(`/api/customer/profile-update`, data);
    return response; // Success case
  } catch (error) {
    return rejectWithValue(error.response.data); // Error case
  }
}
);

const eventsSlice = createSlice({
  name: "events",
  initialState: {
    events: [],
    event: {},
    total: 0,
    page: 1,
    limit: 10,
    status: "idle",
    error: null,
    totalPages: 0,
    campaings: [],
    campaingDetailsData: [],
    countries: [],
    dashboardData: {},
    creditLogBooks: {},
    campaingDetails: {},
    allCampaings: {},
    orgTypes:[],
    states:[],
    city:[],
    profile:{},
  },

  reducers: {
    updateGuestStatus: (state, action) => {
      const { id, status, reason } = action.payload;
      // console.log("campaingDetailsData : ", state.campaingDetailsData);
      state.campaingDetailsData.data = state.campaingDetailsData?.data?.map(
        (guest) => {
          // console.log("Guest : ", guest);
          if (guest.id === id) {
            return {
              ...guest,
              status,
              failed_reason: reason || guest.failed_reason,
              credits: status === "sent" ? guest.credits + 1 : guest.credits,
            };
          } else {
            return guest;
          }
        }
      );

      // console.log("State : ", state.campaingDetailsData);
    },
    clearCampaignDetails: (state) => {
      state.campaingDetails = {}; // Reset the campaingDetails state to empty
    },
    updateUserCredits:(state, action) => {
      // console.log("ACtions ::: ", action);
      if (state.user) {
        state.user.credits = action.payload; // Update the credits
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEvents.fulfilled, (state, action) => {
        state.events = action.payload.data || [];
        state.total = action.payload.total || 0;
        state.page = action.payload.offset;
        state.limit = action.payload.limit;
        state.totalPages = action.payload.totalPages;
        state.search = action.payload.search;
      })
      .addCase(fetchEventDetail.fulfilled, (state, action) => {
        // console.log('state.campaings : ', action.payload);
        state.campaings = action.payload.campaingsData || [];
        state.total = action.payload.total;
        state.totalPages = action.payload.totalPages;
        state.page = action.payload.currentPages;
      })
      .addCase(fetchCampaignDetail.fulfilled, (state, action) => {
        // console.log('state.campaingDetails : ', action.payload);
        state.campaingDetailsData = action.payload.campaingDetailsData || [];
        state.total = action.payload.total;
        state.totalPages = action.payload.totalPages;
        state.page = action.payload.currentPages;
      })
      .addCase(addReseller.fulfilled, (state, action) => {
        // console.log('PayLoad : ', action.payload);
        state.resellers.push(action.payload);
        state.total += 1;
      })
      .addCase(updateEvent.fulfilled, (state, action) => {
        // console.log('Slice : ', action.payload);
        // const index = state.resellers.findIndex((reseller) => reseller.id === action.payload.id);
        // if (index !== -1) {
        //   state.resellers[index] = {...state.resellers[index], ...action.payload};
        // }
      })
      .addCase(deleteReseller.fulfilled, (state, action) => {
        state.resellers = state.resellers.filter(
          (r) => r.id !== action.payload.id
        );
        state.total -= 1;
      })
      .addCase(fetchCountry.fulfilled, (state, action) => {
        state.countries = action.payload;
      })
      .addCase(updateMessageStatus.fulfilled, (state, action) => {
        state.campaingDetailsData = action.payload.campaingDetailsData;
      })
      .addCase(fetchCreditLogBooks.fulfilled, (state, action) => {
        state.creditLogBooks = action.payload.data;
      })
      .addCase(fetchDashboardData.fulfilled, (state, action) => {
        state.dashboardData = action.payload.data;
      })
      .addCase(fetchAllCampaigns.fulfilled, (state, action) => {
        state.allCampaings = action.payload.allCampaings;
      })
      .addCase(deleteCampaign.fulfilled, (state, action) => {
        if (state?.allCampaings?.data) {
          state.allCampaings.data = state?.allCampaings?.data?.filter(
            (campaign) => campaign.id !== action.meta.arg.id
          );
        }

        if (state?.campaings) {
          state.campaings = state?.campaings.filter(
            (campaign) => campaign.id !== action.meta.arg.id
          );
        }
      })
      .addCase(cancelCampaign.fulfilled, (state, action) => {
        if (state?.allCampaings?.data) {
          state.allCampaings.data = state?.allCampaings?.data?.map(
            (campaign) => {
              if(

                campaign.id === action.meta.arg.id
              ) {
                campaign.status = "drafted"
              }

              return campaign

            } 
          );
        }

        if (state?.campaings) {
          state.campaings = state?.campaings.filter(
            (campaign) => {
              if(

                campaign.id === action.meta.arg.id
              ) {
                campaign.status = "drafted"
              }

              return campaign

            } 
          );
        }
      })
      .addCase(deleteCustomer.fulfilled, (state, action) => {
        state.events = state?.events.filter(
          (event) => event.id !== action.meta.arg.id
        );
      })
      .addCase(fetchCampaignById.fulfilled, (state, action) => {
        // console.log("State : ", action.payload);

        state.campaingDetails = action.payload.campaingDetails || {};
      })
      .addCase(fetchCustomer.fulfilled, (state, action) => {
        // console.log("State : ", action.payload);
        state.event = action.payload.event || {};
      })
      .addCase(fetchStates.fulfilled, (state,action) => {
        state.states = action.payload
      })
      .addCase(organizationType.fulfilled, (state,action) => {
        state.orgTypes = action.payload
      })
      .addCase(fetchCityByStateId.fulfilled,(state,action) => {
        state.city = action.payload
      })
      .addCase(updateProfile.fulfilled,(state,action) => {
        state.profile = action.payload;
      })
  },
});

export const { updateGuestStatus, clearCampaignDetails, updateUserCredits } = eventsSlice.actions;

export default eventsSlice.reducer;
