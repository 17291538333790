import { useState, useEffect } from "react";
import CommonFormLabel from "../../../components/common-form-label/index";
import Input from "../../../components/input/index";
import ValidationFeedback from "../../../components/validation-feedback/index";
import {Toast,ToastContainer,Spinner,Container,Image,Form} from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { userResetPassword } from "../../../store/authSlice";
import { ResetPasswordSchema } from "../../../schema/validationSchemas";
import { ViewOffSlashIcon, EyeIcon, ArrowLeftIcon, CheckmarkBadge04Icon } from "../../../icons/icons";
import CommomButton from "../../../components/common-button";
import ButtonLoader from "../../../components/button-loader";

// const ResetPassword = () => {
//   const [showToast, setShowToast] = useState(false);
//   const [toastMessage, setToastMessage] = useState("");
//   const [toastVariant, setToastVariant] = useState("toast-success");
//   const dispatch = useDispatch();
//   const { status, error } = useSelector((state) => state.auth);
//   const location = useLocation();

//   const queryParams = new URLSearchParams(location.search);
//   const token = queryParams.get("token");
//   const email = queryParams.get("email");

//   const handleSubmit = async (values, { setSubmitting, resetForm }) => {
//     try {
//       const result = await dispatch(userResetPassword(values)).unwrap();
//       if (result && result.success) {
//         setToastMessage(result.message || "Reset password successfully!");
//         setToastVariant("toast-success");
//         resetForm();
//       } else {
//         setToastMessage(result.message || "Failed to reset password!");
//         setToastVariant("toast-danger");
//       }
//     } catch (err) {
//       const errorMessage = err?.message || "Failed to reset password!";
//       setToastMessage(errorMessage);
//       setToastVariant("toast-danger");
//     } finally {
//       setShowToast(true);
//       setSubmitting(false);
//     }
//   };

//   useEffect(() => {
//     if (error) {
//       setToastMessage(error);
//       setToastVariant("toast-danger");
//       setShowToast(true);
//     }

//     if (status === "loading") {
//       setToastMessage("Resetting password...");
//       setToastVariant("toast-warning");
//       setShowToast(true);
//     }
//   }, [status, error]);

//   return (
//     <>
//       <ToastContainer position="top-end" className="p-3" style={{ zIndex: 1 }}>
//         <Toast
//           onClose={() => setShowToast(false)}
//           show={showToast}
//           delay={5000}
//           autohide
//           bg={toastVariant}
//         >
//           <Toast.Body
//             style={{ color: "white", display: "flex", alignItems: "center" }}
//           >
//             <CheckCircle size={20} color="white" style={{ marginRight: 8 }} />
//             {toastMessage}
//           </Toast.Body>
//         </Toast>
//       </ToastContainer>
//       <Row className="align-items-center justify-content-center g-0 min-vh-100">
//         <Col xxl={4} lg={6} md={8} xs={12} className="py-8 py-xl-0">
//           <Card className="smooth-shadow-md">
//             <Card.Body className="p-6">
//               <div className="mb-4">
//                 <Link to="/">
//                   <img
//                     src="/images/brand/logo/logo-primary.svg"
//                     className="mb-2"
//                     alt=""
//                   />
//                 </Link>
//                 <p className="d-flex mb-6 ml-2">Please enter New Password</p>
//               </div>
//               <Formik
//                 initialValues={{
//                   email: email || "",
//                   token: token || "",
//                   new_password: "",
//                   new_password_confirmation: "",
//                 }}
//                 validationSchema={ResetPasswordSchema}
//                 onSubmit={handleSubmit}
//               >
//                 {({
//                   errors,
//                   touched,
//                   isSubmitting,
//                   handleChange,
//                   handleBlur,
//                   handleSubmit,
//                 }) => (
//                   <form onSubmit={handleSubmit}>
//                     <div className="mb-3">
//                       <label
//                         htmlFor="new_password"
//                         className="d-flex form-label"
//                       >
//                         Password
//                       </label>
//                       <Field
//                         id="new_password"
//                         name="new_password"
//                         type="password"
//                         placeholder=""
//                         className={`form-control ${touched.new_password && errors.new_password
//                           ? "is-invalid"
//                           : ""
//                           }`}
//                         onChange={handleChange}
//                         onBlur={handleBlur}
//                       />
//                       {touched.new_password && errors.new_password ? (
//                         <div className="invalid-feedback">
//                           {errors.new_password}
//                         </div>
//                       ) : null}
//                     </div>

//                     <div className="mb-3">
//                       <label
//                         htmlFor="new_password_confirmation"
//                         className="d-flex form-label"
//                       >
//                         Confirm Password
//                       </label>
//                       <Field
//                         id="new_password_confirmation"
//                         name="new_password_confirmation"
//                         type="password"
//                         placeholder=""
//                         className={`form-control ${touched.new_password_confirmation &&
//                           errors.new_password_confirmation
//                           ? "is-invalid"
//                           : ""
//                           }`}
//                         onChange={handleChange}
//                         onBlur={handleBlur}
//                       />
//                       {touched.new_password_confirmation &&
//                         errors.new_password_confirmation ? (
//                         <div className="invalid-feedback">
//                           {errors.new_password_confirmation}
//                         </div>
//                       ) : null}
//                     </div>

//                     <div className="d-grid w-28 d-flex justify-content-center mt-5">
//                       <Button
//                         variant="primary"
//                         type="submit"
//                         disabled={isSubmitting}
//                       >
//                         {isSubmitting ? (
//                           <>
//                             <Spinner
//                               as="span"
//                               animation="border"
//                               size="sm"
//                               role="status"
//                               aria-hidden="true"
//                             />
//                             <span className="visually-hidden">Loading...</span>
//                           </>
//                         ) : (
//                           "Reset Password"
//                         )}
//                       </Button>
//                     </div>
//                     <div className="d-grid w-28 d-flex justify-content-center mt-3">
//                       <Link to="/" className="text-decoration-underline">
//                         Sign In
//                       </Link>
//                     </div>
//                   </form>
//                 )}
//               </Formik>
//             </Card.Body>
//           </Card>
//         </Col>
//       </Row>

//     </>
//   );
// };
// export default ResetPassword;

import React from "react";

export default function ResetPassword() {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastVariant, setToastVariant] = useState("toast-success");
  const dispatch = useDispatch();
  const { status, error } = useSelector((state) => state.auth);
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");
  const email = queryParams.get("email");

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const result = await dispatch(userResetPassword(values)).unwrap();
      if (result && result.success) {
        // console.log('Result : ', result);
        setToastMessage(result.message || "Reset password successfully!");
        setToastVariant("toast-success");
        resetForm();
        // navigate('/');
      } else {
        setToastMessage(result.message || "Failed to reset password!");
        setToastVariant("toast-danger");
        // navigate('/');
      }
    } catch (err) {
      const errorMessage = err?.message || "Failed to reset password!";
      setToastMessage(errorMessage);
      setToastVariant("toast-danger");
      //   navigate('/');
    } finally {
      setShowToast(true);
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (error) {
      setToastMessage(error);
      setToastVariant("toast-danger");
      setShowToast(true);
    }

    if (status === "loading") {
      setToastMessage("Resetting password...");
      setToastVariant("toast-warning");
      setShowToast(true);
    }
  }, [status, error]);

  return (
    <>
      <ToastContainer position="top-end" className="p-3">
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={5000}
          autohide
          bg={toastVariant}
          className="position-fixed top-3 end-0 me-3 shadow-none"
        >
          <Toast.Body className="d-flex align-items-center gap-1 lh-sm">
            <CheckmarkBadge04Icon
              width={18}
              height={18}
              color="#28c76f"
              strokeWidth="2"
            />
            <span className="text-capitalize">{toastMessage}</span>
          </Toast.Body>
        </Toast>
      </ToastContainer>

      <Container fluid="xxl" className="mx-auto px-4">
        <div className="d-flex align-items-center justify-content-center py-4  reset-password-content">
          <div className="position-relative w-100 py-4 reset-password-sub-conetnt">
            <div className="bg-white rounded-2 p-5 common-shadow reset-password-card">
              <h1 className="fs-5 d-flex align-content-center justify-content-center mb-4">
                <Link
                  to="https://invitewala.in/"
                  className=" text-decoration-none  d-inline-block"
                >
                  <Image
                    src="/assets/images/logo/logo-black.svg"
                    className="site-logo"
                    alt="Site-Logo"
                  />
                </Link>
              </h1>
              <div className="mb-4 welcome-content">
                <h4 className="mb-1 fw-medium">Reset Password 🔒</h4>
                <p>
                  Your new password must be different from previously used
                  passwords
                </p>
              </div>
              <Formik
                initialValues={{
                  email: email || "",
                  token: token || "",
                  new_password: "",
                  new_password_confirmation: "",
                }}
                validationSchema={ResetPasswordSchema}
                onSubmit={handleSubmit}
              >
                {({
                  errors,
                  touched,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className="mb-4">
                      <CommonFormLabel title="New Password" />
                      <div className="position-relative password-input">
                        <Input
                          id="new_password"
                          name="new_password"
                          type="password"
                          placeholder="············"
                          onChange={handleChange}
                          className={`shadow-none ${
                            touched.new_password && errors.new_password
                              ? "error"
                              : "correct"
                          }`}
                        />
                        <div className=" position-absolute top-50 end-0 translate-middle-y me-3 cursor-pointer">
                          {/* EyeSlash Icon */}
                          <ViewOffSlashIcon
                            width={20}
                            height={20}
                            color="#444050"
                            strokeWidth="1.5"
                            className={
                              touched.new_password && errors.new_password
                                ? "error"
                                : "correct"
                            }
                          />

                          {/* Eye Icon - [Abi Ye Icon Ko Hide Kiya He d-none Ka Class Lag raha he Esi Liye Hide He.] */}
                          <EyeIcon
                            width={20}
                            height={20}
                            color="#444050"
                            strokeWidth="1.5"
                            className={`d-none ${
                              touched.new_password && errors.new_password
                                ? "error"
                                : "correct"
                            }`}
                          />
                        </div>
                      </div>
                      {touched.new_password && errors.new_password ? (
                        <ValidationFeedback title={errors.new_password} />
                      ) : null}
                    </div>
                    <div className="mb-4">
                      <CommonFormLabel title="Confirm Password" />
                      <div className="position-relative password-input">
                        <Input
                          id="new_password_confirmation"
                          name="new_password_confirmation"
                          type="password"
                          placeholder="············"
                          onChange={handleChange}
                          className={`shadow-none ${
                            touched.new_password_confirmation &&
                            errors.new_password_confirmation
                              ? "error"
                              : "correct"
                          }`}
                        />
                        <div className=" position-absolute top-50 end-0 translate-middle-y me-3 cursor-pointer">
                          {/* EyeSlash Icon */}
                          <ViewOffSlashIcon
                            width={20}
                            height={20}
                            color="#444050"
                            strokeWidth="1.5"
                            className={
                              touched.new_password_confirmation &&
                              errors.new_password_confirmation
                                ? "error"
                                : "correct"
                            }
                          />

                          {/* Eye Icon - [Abi Ye Icon Ko Hide Kiya He d-none Ka Class Lag raha he Esi Liye Hide He.] */}
                          <EyeIcon
                            width={20}
                            height={20}
                            color="#444050"
                            strokeWidth="1.5"
                            className={`d-none ${
                              touched.new_password_confirmation &&
                              errors.new_password_confirmation
                                ? "error"
                                : "correct"
                            }`}
                          />
                        </div>
                      </div>
                      {touched.new_password_confirmation &&
                      errors.new_password_confirmation ? (
                        <ValidationFeedback
                          title={errors.new_password_confirmation}
                        />
                      ) : null}
                    </div>

                    {isSubmitting ? (
                      <ButtonLoader
                        icon={
                          <Spinner
                            animation="border"
                            className="text-white br-2_5 btn-loader-wh-18"
                          />
                        }
                        title="Loading..."
                        className="w-100 mb-4 btn-primary primary-shadow "
                      />
                    ) : (
                      <CommomButton
                        title="Set New Password"
                        type="submit"
                        disabled={isSubmitting}
                        className="w-100 mb-4 btn-primary primary-shadow "
                      />
                    )}

                    <div className="d-flex align-items-center justify-content-center backtologin-link">
                      <Link
                        to="/"
                        className="text-decoration-none  d-flex align-items-center"
                      >
                        <span className="me-1">
                          <ArrowLeftIcon
                            width={20}
                            height={20}
                            color="#A5596A"
                            strokeWidth="1.5"
                          />
                        </span>
                        Back to login
                      </Link>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}
