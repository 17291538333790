import React from "react";

export default function SmallWidgets({
  icon,
  count,
  title,
  onClick,
  className,
}) {
  return (
    <div
      className={`small-widgets rounded-2 bg-white d-flex align-items-center common-shadow cursor-pointer ${className}`}
      onClick={onClick}
    >
      {icon && (
        <div className="icon rounded-1 d-flex align-items-center justify-content-center">
          {icon}
        </div>
      )}
      <div>
        {count && <h5 className=" fw-medium">{count}</h5>}
        {title && <p className="text-capitalize pt-1">{title}</p>}
      </div>
    </div>
  );
}
