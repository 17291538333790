import React, { useEffect, useRef } from 'react'
import { Modal } from 'react-bootstrap'
import { Swiper, SwiperSlide } from "swiper/react";
import { Image } from "react-bootstrap";
import { Zoom, Pagination, EffectFade, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/zoom";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "../../pages/eventsDetails/styleModule.css";

const PdfZoomViewModal = ({ show, handleClose, pdfPageWiseView, campaingDetails }) => {
    const prevRef = useRef(null);
    const nextRef = useRef(null);

    useEffect(() => {
        // Wait until refs are assigned before updating Swiper
        if (show && prevRef.current && nextRef.current) {
            const swiperInstance = document.querySelector(".mySwiper")?.swiper;
            if (swiperInstance) {
                swiperInstance.params.navigation.prevEl = prevRef.current;
                swiperInstance.params.navigation.nextEl = nextRef.current; 

                // Ensure Swiper updates both navigation buttons
                swiperInstance.navigation.destroy(); // Reset navigation
                swiperInstance.navigation.init(); // Reinitialize navigation
                swiperInstance.navigation.update(); // Update button states
            }
        }
    }, [show,prevRef,nextRef]); // Re-run when the modal is shown
    
    return (
        <>
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Body >
                    <div className="bg-white rounded-2 overflow-hidden p-4 pdf-modal-outer-div">
                        <div className="import-file-content pdf-modal-inner-div">
                            {campaingDetails && campaingDetails?.template_type === "pdf" && pdfPageWiseView.length  && (
                                <>
                                    {/* Left Arrow */}
                                    <button
                                        ref={prevRef}
                                        className="swiper-button swiper-button-prev pdf-modal-prev-btn">
                                    </button>
                                    <Swiper
                                        pagination={{
                                            type: "fraction",
                                        }}
                                        navigation={{
                                            prevEl: prevRef.current,
                                            nextEl: nextRef.current,
                                        }}
                                        onBeforeInit={(swiper) => {
                                            swiper.params.navigation.prevEl = prevRef.current;
                                            swiper.params.navigation.nextEl = nextRef.current;
                                        }}
                                        zoom={true}
                                        effect={"fade"}
                                        modules={[Pagination, Zoom, EffectFade, Navigation]}
                                        className="mySwiper mx-0 import-pdf-preview"
                                    // className="mySwiper  mx-0 import-file-preview"
                                    >
                                        {pdfPageWiseView &&
                                            pdfPageWiseView.length &&
                                            pdfPageWiseView?.map((item, i) => {
                                                return (
                                                    <SwiperSlide
                                                        key={i}
                                                        className="border rounded-1 overflow-hidden"
                                                    >
                                                        <div className="swiper-zoom-container ">
                                                            <Image
                                                                src={item?.base64}
                                                                className="img-fluid object-fit-cover"
                                                            />
                                                        </div>
                                                    </SwiperSlide>
                                                );
                                            })}
                                    </Swiper>
                                    {/* Right Arrow */}
                                    <button
                                        ref={nextRef}
                                        className="swiper-button swiper-button-next pdf-modal-nxt-btn">
                                    </button>
                                </>
                            )}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default PdfZoomViewModal