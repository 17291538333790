import React, { useState, useCallback } from "react";
import CommonFormLabel from "../../../components/common-form-label/index";
import Input from "../../../components/input/index";
import ValidationFeedback from "../../../components/validation-feedback/index";
import CommomButton from "../../../components/common-button/index";
import { useDispatch } from "react-redux";
import {
  Modal,
  ModalBody,
  // ToastContainer,
  // Toast,
} from "react-bootstrap";
import { Formik, Form, } from "formik";
import { fetchEvents, updateCustomer } from "../../../store/eventsSlice";
import * as Yup from "yup";
import { Cancel01Icon } from "../../../icons/icons";

export default function Edit({
  show,
  handleClose,
  currentPage,
  setCurrentPage,
  searchTerm,
  limit,
  id,
  currentCustomer, // Receive the current customer data
  setShowSnackbar,
  setSnackbarMessage,
  setSnackbarVariant,
}) {
  // const [showSnackbar, setShowSnackbar] = useState(false);
  // const [snackbarMessage, setSnackbarMessage] = useState("");
  // const [snackbarVariant, setSnackbarVariant] = useState("toast-success");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const dispatch = useDispatch();

  const initialEventState = {
    name: currentCustomer?.name || "",
    contact_number: currentCustomer?.contact_number || "",
    email: currentCustomer?.email || "",
    address: currentCustomer?.address || "",
  };

  const eventValidation = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    contact_number: Yup.string().required("Contact Number is required"),
    email: Yup.string().email("Invalid email address").nullable(),
    address: Yup.string().nullable(),
  });

  const handleModalClose = useCallback(() => {
    handleClose();
  }, [handleClose]);

  const handleSubmit = async (
    values,
    { setSubmitting, resetForm, setErrors }
  ) => {
    setIsSubmitting(true);
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("contact_number", values.contact_number);
    formData.append("email", values.email);
    formData.append("address", values.address);
    formData.append("_method", "PUT");

    dispatch(updateCustomer({ id: currentCustomer?.id, data: formData }))
      .then((res) => {
        if (res.payload.success) {
          dispatch(
            fetchEvents({
              offset: currentPage,
              limit:limit?.value,
              search: searchTerm,
              id: id,
            })
          );
          // console.log("REsponse: ", res.payload.message);
          setSnackbarMessage(res.payload.message);
          setSnackbarVariant("toast-success");
          setShowSnackbar(true);

          // console.log("hahahahahahahah : ", showSnackbar);
        } else {
          setSnackbarMessage(res.payload.message);
          setErrors(res.payload.data);
          setSnackbarVariant("toast-danger");
          setShowSnackbar(true);
        }
      })
      .catch((error) => {
        setSnackbarMessage("Failed to update customer: " + error.message);
        setSnackbarVariant("toast-danger");
        setShowSnackbar(true);
      })
      .finally(() => {
        setShowSnackbar(true);
        resetForm();
        handleModalClose();
        setSubmitting(false);
      });
  };

  // useEffect(() => {
  //   if (showSnackbar) {
  //     console.log("Snackbar is now shown");
  //   }
  // }, [showSnackbar]);

  return (
    <>
      {/* <ToastContainer position="top-end" className="p-3">
        <Toast
          onClose={() => setShowSnackbar(false)}
          show={showSnackbar}
          delay={3000}
          autohide
          bg={snackbarVariant}
          className="position-fixed top-3 end-0 me-3 shadow-none"
        >
          <Toast.Body className="d-flex align-items-center gap-1 lh-sm">
            <CheckmarkBadge04Icon
              width={18}
              height={18}
              color="#28c76f"
              strokeWidth="2"
            />
            <span className="text-capitalize">{snackbarMessage}</span>
          </Toast.Body>
        </Toast>
      </ToastContainer> */}

      <Modal
        show={show}
        size="md"
        className=""
        centered
        dialogClassName="common-popup-dialog common-popup-dialog-size"
        contentClassName="common-popup-content"
        backdropClassName="common-popup-backdrop"
      >
        <ModalBody className="common-popup-body">
          <div
            className="position-absolute end-0 z-2 bg-white rounded-1 cursor-pointer d-flex align-items-center justify-content-center  common-popup-colose-btn"
            onClick={handleModalClose}
          >
            <Cancel01Icon
              width={16}
              height={16}
              color="#BCBBC1"
              strokeWidth="2.5"
            />
          </div>
          <div className="common-mb-space text-center common-popup-heading">
            <h4 className="text-capitalize mb-2 fw-medium">Edit Customer</h4>
            <p>Edit {currentCustomer?.name} customer</p>
          </div>
          <Formik
            initialValues={initialEventState}
            validationSchema={eventValidation}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, handleChange,values }) => (
              <Form encType="multipart/form-data">
                <div className="common-mb-space">
                  <CommonFormLabel htmlFor="name" title="Name" />
                  <Input
                    id="name"
                    name="name"
                    type="text"
                    value={values.name}
                    placeholder="Enter Your Name"
                    onChange={handleChange}
                    className={`shadow-none ${
                      errors.name && touched.name ? "error" : "correct"
                    }`}
                  />
                  {errors.name && touched.name ? (
                    <ValidationFeedback title={errors.name} />
                  ) : null}
                </div>

                <div className="common-mb-space">
                  <CommonFormLabel
                    htmlFor="contact_number"
                    title="Contact Number"
                  />
                  <Input
                    id="contact_number"
                    name="contact_number"
                    type="number"
                    value={values.contact_number}
                    placeholder="Enter Your Contact No."
                    onChange={handleChange}
                    className={`shadow-none ${
                      errors.contact_number && touched.contact_number
                        ? "error"
                        : "correct"
                    }`}
                  />
                  {errors.contact_number && touched.contact_number ? (
                    <ValidationFeedback title={errors.contact_number} />
                  ) : null}
                </div>

                <div className="common-mb-space">
                  <CommonFormLabel htmlFor="email" title="Email" />
                  <Input
                    name="email"
                    type="email"
                    value={values.email}
                    placeholder="Enter Your Email"
                    onChange={handleChange}
                    className={`shadow-none ${
                      errors.email && touched.email ? "error" : "correct"
                    }`}
                  />
                  {errors.email && touched.email ? (
                    <ValidationFeedback title={errors.email} />
                  ) : null}
                </div>

                <div className="common-mb-space">
                  <CommonFormLabel htmlFor="address" title="Address" />
                  <Input
                    name="address"
                    type="text"
                    value={values.address}
                    placeholder="Enter Your Address"
                    onChange={handleChange}
                    className={`shadow-none ${
                      errors.address && touched.address ? "error" : "correct"
                    }`}
                  />
                  {/* {errors.address && touched.address ? (
                    <ValidationFeedback title={errors.address} />
                  ) : null} */}
                </div>

                <div className="d-flex align-items-center justify-content-center gap-3 common-popup-btn">
                  <CommomButton
                    title={isSubmitting ? "Updating..." : "Update"}
                    type="submit"
                    disabled={isSubmitting}
                    className="btn-primary primary-shadow"
                  />
                  <CommomButton
                    title="Cancel"
                    onClick={handleModalClose}
                    className="btn-muted"
                  />
                </div>

              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </>
  );
}
