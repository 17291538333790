import React, { useEffect, useState } from "react";
import {
  Container,
  Col,
  Row,
  Button,
  ToastContainer,
  Toast,
  InputGroup,
  Modal,
  Form
} from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
// import './profilemodule.css';
import "./profileModule.css";
import CommonLayer from "../../layouts/master/index";
import { useDispatch, useSelector } from "react-redux";
import { userDetails, userUpdatePassword } from "../../../store/authSlice";
// import { CheckCircle } from "react-feather";
import { ErrorMessage, Field, Formik } from "formik";
import { editProfileSchema, passwordSchema } from "../../../schema/validationSchemas";
import { fetchCountry } from "../../../store/customerSlice";
import { useLoader } from "../../../context/LoaderContext";
import { Cancel01Icon, CheckmarkBadge04Icon } from "../../../icons/icons";
import CommomButton from "../../../components/common-button";
import CommonFormLabel from "../../../components/common-form-label";
import Input from "../../../components/input";
import { fetchCityByStateId, fetchStates, organizationType, updateProfile } from "../../../store/eventsSlice";
import SelectBox from "../../../components/select-box";
import ValidationFeedback from "../../../components/validation-feedback";

export default function Detail() {
  // const [value, setValue] = useState("");
  const { setLoading } = useLoader();
  const dispatch = useDispatch();

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("toast-success");
  const [show, setShow] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCloseChangePassword = () => setShowChangePassword(false);
  // const handleShowChangePassword = () => setShowChangePassword(true);

  //   const [name, setName] = useState("");
  //   const [email, setEmail] = useState("");
  //   const [mobileNumber, setMobileNumber] = useState("");
  // const countries = useSelector((state) => state.events.countries);
  const user = useSelector((state) => state.auth.user);

  const states = useSelector((state) => state?.events?.states);
  const organizationTypes = useSelector((state) => state?.events?.orgTypes);
  const [selectedStateId,setSelectedStateId] = useState(user?.state_id ? user?.state_id : "")
  const [cityOptions,setCityOptions] = useState([]);

  // console.log("selectedStateId",selectedStateId);
  // console.log("cityOptions",cityOptions);
  
  
    // console.log('User : ', user);
  // console.log("organizationTYpes",organizationTypes);

    // console.log("states",states);

  const initialProfileUpdate = {
    name: user?.name || "",
    email: user?.email || "",
    city_id: user?.city_id || "",
    state_id: user?.state_id || "",
    mobile_number: user?.mobile_number || "",
    organization_id: user?.organization_id || "",
    organization_name: user?.organization_name || "",
  }
    
  const initialPasswordUpdate = {
    current_password: "",
    new_password: "",
    new_password_confirmation: "",
  };

  useEffect(() => {
    if (selectedStateId){
      dispatch(fetchCityByStateId({state_id:selectedStateId})).then((res) => {
        if(res?.payload){
          const list = res?.payload?.map((item) => ({
            value:item?.id,
            label:item?.name,
          }))
          setCityOptions(list);
        }})}
  }, [dispatch,selectedStateId])
  


  useEffect(() => {
    // setLoading(true);
    dispatch(userDetails()).then((action) => {
      if (action.payload && action.payload.data.success) {
        // console.log("Action : ", action.payload);
        // setName(action.payload.data.data.name);
        // setEmail(action.payload.data.data.email);
        // setMobileNumber(action.payload.data.data.mobile_number);
        // setCountry(action.payload.data.data.country_id);
      }
    });

    dispatch(fetchStates({}))
    dispatch(organizationType({}))

    dispatch(fetchCountry())
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch, setLoading]);

  // const getCountryName = (countryId) => {
  //   const country = countries.find((c) => c.id === countryId);
  //   return country ? country.name : "Not Found";
  // };

  const handlePasswordChange = (values,{ setSubmitting, setErrors, resetForm }) => {
    dispatch(userUpdatePassword(values))
      .then((action) => {
        if (action.payload && action.payload.data.success) {
          setSnackbarMessage(action.payload.data.message);
          setSnackbarVariant("toast-success");
          setShowSnackbar(true);
          resetForm();
        } else {
          setErrors(action.payload.data);
          setSnackbarMessage(
            action.payload.data.message || "Failed to update password"
          );
          setSnackbarVariant("toast-danger");
          setShowSnackbar(true);
        }
      })
      .catch((error) => {
        setSnackbarMessage(
          "Update failed: Network or server error" + error.message
        );
        setSnackbarVariant("toast-danger");
        setShowSnackbar(true);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const titles = [
    { name: 'Profile', path: '/customers' },
  ];

  const getUserInitial = (name) => {
    return name
      ? name
          .split(" ")
          .map((n) => n.charAt(0))
          .join("")
      : "UN";
  };

  const handleSubmit = async (values, { setSubmitting }) => {
     try{
      setLoading(true);
      setSubmitting(true)
      dispatch(updateProfile(values)).then((action) => {
        if (action?.payload?.data?.success === true){
          setSnackbarMessage(action?.payload?.data?.message || "Profile updated successfully!");
          setSnackbarVariant("toast-success");
          dispatch(userDetails({}));
        } 
      })
     } catch (err){
      const errorMessage = err?.message || "Failed to update a profile!";
      setSnackbarMessage(errorMessage);
      setSnackbarVariant("toast-danger");
      setLoading(false);
     }finally{
      setShowSnackbar(true);
      setSubmitting(false);
      setShow(false); 
      setLoading(false);
     }
  }


  return (
    <CommonLayer titles={titles}>
      <ToastContainer position="top-end" className="p-3">
        <Toast
          onClose={() => setShowSnackbar(false)}
          show={showSnackbar}
          delay={3000}
          autohide
          bg={snackbarVariant}
          className="position-fixed top-3 end-0 me-3 shadow-none"
        >
          <Toast.Body className="d-flex align-items-center gap-1 lh-sm">
            <CheckmarkBadge04Icon
              width={18}
              height={18}
              color="#28c76f"
              strokeWidth="2"
            />
            <span className="text-capitalize">{snackbarMessage}</span>
          </Toast.Body>
        </Toast>
      </ToastContainer>

      <Container fluid className="px-0">
        {/* If you remove the d-none class from this given row tag, you will see the old design and code. */}
        <Row className="d-none">
          <Col lg={12} md={12} xs={12}>
            <div>
              <div className="d-flex justify-content-between align-items-center top_title">
                <div className="mb-2 mb-lg-0">
                  <h3 className="mb-0 subhead_title">Profile</h3>
                </div>
              </div>
            </div>
          </Col>
          <Col xl={12} lg={12} md={12} xs={12} className="mt-6">
            <div className="position-relative overflow-hidden border-radius-8 tablecontainer">
              <Tabs
                defaultActiveKey="profilelist"
                className="mb-5 border-0 tablist_data"
                id="uncontrolled-tab-example"
              >
                <Tab
                  className="row customer_data"
                  eventKey="profilelist"
                  title="Profile"
                >
                  <div className="input_head position-relative d-flex align-items-center justify-content-between">
                    <h5 className="float-start m-0 fw-500">Profile Details</h5>
                  </div>
                  <div className="tablecontent_details col-8 position-relative border-radius-8">
                    <h6 className="fw-500">Personal Details</h6>
                    <div className="row justify-content-cener align-item-center pt-4 pb-5">
                      <div className="position-relative col-6 floating-label-content">
                        <label className="floating-label w-100 text-start p-0">
                          Name
                        </label>
                        <input
                          className="w-100 bg-white fs-16 floating-input"
                          type="text"
                          placeholder=""
                          disabled
                          value={user.name}
                        />
                      </div>
                      <div className="position-relative col-6 floating-label-content">
                        <label className="w-100 text-start floating-label p-0">
                          Phone No.
                        </label>
                        <input
                          className="w-100 bg-white fs-16 floating-input"
                          type="number"
                          placeholder=" "
                          disabled
                          value={user.mobile_number}
                        />
                      </div>
                      <div className="position-relative col-6 floating-label-content">
                        <label className="floating-label w-100 text-start p-0">
                          Email
                        </label>
                        <input
                          className="w-100 bg-white fs-16 floating-input"
                          type="email"
                          placeholder=""
                          //   autoComplete="off"
                          disabled
                          value={user.email}
                        />
                      </div>
                      <div className="position-relative col-6 floating-label-content">
                        <label className="floating-label w-100 text-start p-0">
                          Organization Name
                        </label>
                        <input
                          className="w-100 bg-white fs-16 floating-input"
                          type="organization_name"
                          placeholder=""
                          //   autoComplete="off"
                          disabled
                          value={user?.organization_name}
                        />
                      </div>
                      <div className="position-relative col-6 floating-label-content">
                        {/* <label className="floating-label w-100 text-start p-0">
                          Organization Type
                        </label>
                        <input
                          className="w-100 bg-white fs-16 floating-input"
                          type="organization_type"
                          placeholder=""
                          //   autoComplete="off"
                          disabled
                          value={user?.organization_type?.name}
                        /> */}
                        <div className="grid mb-4">
                        <div className="g-col-5">
                         
                        </div>
                      </div>
                      </div>
                      <div className="position-relative col-6 floating-label-content">
                        <label className="floating-label w-100 text-start p-0">
                          State
                        </label>
                        <input
                          className="w-100 bg-white fs-16 floating-input"
                          type="state"
                          placeholder=""
                          //   autoComplete="off"
                          disabled
                          value={user?.state?.name}
                        />
                      </div>
                      <div className="position-relative col-6 floating-label-content">
                        <label className="floating-label w-100 text-start p-0">
                          City
                        </label>
                        <input
                          className="w-100 bg-white fs-16 floating-input"
                          type="city"
                          placeholder=""
                          //   autoComplete="off"
                          disabled
                          value={user?.city?.name}
                        />
                      </div>
                    </div>
                    {/* <div className="">
                      <Button type="button" className="">
                        Add Reseller
                      </Button>
                    </div> */}
                  </div>
                </Tab>
                <Tab
                  className="row customer_data"
                  eventKey="settingdetals"
                  title="Settings"
                >
                  <Formik
                    initialValues={initialPasswordUpdate}
                    validationSchema={passwordSchema}
                    onSubmit={handlePasswordChange}
                  >
                    {({ isSubmitting }) => (
                      <Form>
                        <div className="input_head position-relative d-flex align-items-center justify-content-between">
                          <h5 className="float-start m-0 fw-500">Setting</h5>
                          {showSnackbar}
                        </div>
                        <div className="tablecontent_details col-4 position-relative border-radius-8">
                          <h6 className="fw-500">Reset Password</h6>
                          <div className="row justify-content-cener align-item-center pt-4 pb-5 flex-column">
                            <div className="position-relative col-10 floating-label-content">
                              <InputGroup>
                                <label className="w-100 text-start p-0 floating-label">
                                  Current Password
                                </label>
                                <Field
                                  className="w-100 bg-white fs-16 floating-input"
                                  type="password"
                                  placeholder=""
                                  name="current_password"
                                //   autoComplete="off"
                                />

                                <div className="required_alert text-start">
                                  <ErrorMessage
                                    name="current_password"
                                    component="p"
                                    className="text-danger"
                                  />
                                </div>
                              </InputGroup>
                            </div>
                            <div className="position-relative col-10 floating-label-content">
                              <InputGroup>
                                <label className="w-100 text-start p-0 floating-label">
                                  New Password
                                </label>
                                <Field
                                  className="w-100 bg-white fs-16 floating-input"
                                  type="password"
                                  placeholder=""
                                  name="new_password"
                                />

                                <div className="required_alert text-start">
                                  <ErrorMessage
                                    name="new_password"
                                    component="p"
                                    className="text-danger"
                                  />
                                </div>
                              </InputGroup>
                            </div>
                            <div className="position-relative col-10 floating-label-content">
                              <InputGroup>
                                <label className="w-100 text-start p-0 floating-label">
                                  Confirm Password
                                </label>
                                <Field
                                  className="w-100 bg-white fs-16 floating-input"
                                  type="password"
                                  placeholder=" "
                                  name="new_password_confirmation"
                                />

                                <div className="required_alert text-start">
                                  <ErrorMessage
                                    name="new_password_confirmation"
                                    component="p"
                                    className="text-danger"
                                  />
                                </div>
                              </InputGroup>
                            </div>
                          </div>
                          <div className="">
                            <Button type="submit" className="btn btn-primary">
                              Reset
                            </Button>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </Tab>
              </Tabs>
            </div>
          </Col>
        </Row>


        <div className="profile-content">
          <h4>My Profile</h4>
          <div className="mt-4 pt-1 grid">
            <div className="g-col-6">
              <div className="bg-white rounded-2 p-4">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center gap-2 profile-icon">
                    <div className="icon rounded-circle d-flex align-items-center justify-content-center text-white text-capitalize">{getUserInitial(user.name)}</div>
                    <h6>{user.name}</h6>
                  </div>
                  <Button varient="link" className="bg-transparent border-0 edit-link" onClick={handleShow}>Edit</Button>
                </div>
                <h6 className="mt-4">Personal Details</h6>
                <div className="mt-4 grid gap-3">
                  <div className="g-col-6">
                    <h6>Email:</h6>
                    <p>{user.email}</p>
                  </div>
                  <div className="g-col-6">
                    <h6>Phone:</h6>
                    <p>{user.mobile_number}</p>
                  </div>
                  <div className="g-col-6">
                    <h6>Organization:</h6>
                    <p>{user?.organization_name}</p>
                  </div>
                  <div className="g-col-6">
                    <h6>Organization Type:</h6>
                    <p>{user?.organization_type?.name}</p>
                  </div>
                  <div className="g-col-6">
                    <h6>State:</h6>
                    <p>{user?.state?.name}</p>
                  </div>
                  <div className="g-col-6">
                    <h6>City:</h6>
                    <p>{user?.city?.name}</p>
                  </div>
                  {/* <div className="g-col-6">
                    <CommomButton
                      title="Change Password"
                      onClick={handleShowChangePassword}
                      className="btn-primary common-one-shadow"
                    />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>

      <Modal show={show} onHide={handleClose}
        size="lg"
        centered
        dialogClassName="common-popup-dialog common-popup-dialog-size"
        contentClassName="common-popup-content"
        backdropClassName="common-popup-backdrop">

        <Modal.Body className="common-popup-body">
          <div
            className="position-absolute end-0 z-2 bg-white rounded-1 cursor-pointer d-flex align-items-center justify-content-center  common-popup-colose-btn"
            onClick={handleClose}
          >
            <Cancel01Icon
              width={16}
              height={16}
              color="#BCBBC1"
              strokeWidth="2.5"
            />
          </div>
          <div className="common-mb-space text-center common-popup-heading">
            <h4 className="text-capitalize mb-2 fw-medium">Edit Profile</h4>
            <p>Edit {user.name} Profile</p>
          </div>
          <Formik 
          initialValues={initialProfileUpdate} 
          onSubmit={handleSubmit}
          validationSchema={editProfileSchema}
          >
          {({
            errors,
            touched,
            isSubmitting,
            handleChange,
            values,
            setFieldValue,
            handleSubmit,
          }) => (
        <>
        {/* {console.log("values",values)} */}
        <Form className="grid" onSubmit={handleSubmit}>
            <div className="g-col-6">
              <CommonFormLabel htmlFor="name" title="Name" />
              <Input
                name="name"
                type="text"
                value={values.name}
                placeholder="Enter Your Name"
                onChange={handleChange}
                className={`shadow-none ${errors.name && touched.name ? "error" : "correct"}`}
              />
              {errors.name && touched.name ? (
                <ValidationFeedback title={errors.name} />
              ) : null}
            </div>
            <div className="g-col-6">
              <CommonFormLabel htmlFor="email" title="E-mail" />
              <Input
                name="email"
                type="email"
                value={values?.email}
                placeholder="Enter Your E-mail"
                onChange={handleChange}
                className={`shadow-none ${errors.email && touched.email ? "error" : "correct"}`}
              />
              {errors?.email && touched?.email ? (
                <ValidationFeedback title={errors?.email} />
              ) : null}
            </div>
            <div className="g-col-6">
              <CommonFormLabel htmlFor="mobile_number" title="Phone No." />
              <Input
                name="mobile_number"
                type="number"
                value={values.mobile_number}
                placeholder="Enter Your Phone No."
                onChange={handleChange}
                className={`shadow-none ${errors.name && touched.name ? "error" : "correct"}`}
              />
              {errors.mobile_number && touched.mobile_number ? (
                <ValidationFeedback title={errors.mobile_number} />
              ) : null}
            </div>
            <div className="g-col-6">
              <CommonFormLabel htmlFor="organization_name" title="Organization" />
              <Input
                name="organization_name"
                type="text"
                value={values.organization_name}
                placeholder="Enter Your Organization"
                onChange={handleChange}
                className={`shadow-none ${errors.organization_name && touched.organization_name ? "error" : "correct"}`}
              />
              {errors.organization_name && touched.organization_name ? (
                <ValidationFeedback title={errors.organization_name} />
              ) : null}
            </div>
            <div className="g-col-6">
              <CommonFormLabel htmlFor="organization_id" title="Organization Type" />
               <SelectBox
                name="organization_id"
                value={values?.organization_id}
                staticOption="Select an Organization Type"
                data={organizationTypes?.map((state) => ({
                  value: state.id,
                  label: state.name,
                }))}
                onChange={(e) => {
                  setFieldValue("organization_id",e?.target?.value);
                }}
              />
                {errors.organization_id && touched.organization_id ? (
                <ValidationFeedback title={errors.organization_id} />
              ) : null}
            </div>
            <div className="g-col-6">
              <CommonFormLabel htmlFor="state_id" title="State" />
              <SelectBox
                name="state_id"
                value={values?.state_id}
                staticOption="Select a State"
                className={`shadow-none ${errors.state && touched.state ? "error" : "correct"}`}
                data={states?.map((state) => ({
                  value: state.id,
                  label: state.name,
                }))}
                onChange={(e) => {
                  setFieldValue("state_id",e.target?.value);
                  setSelectedStateId(e.target.value);
                }}
              />
              {errors.state_id && touched.state_id ? (
                <ValidationFeedback title={errors.state_id} />
              ) : null}
            </div>
            <div className="g-col-6">
              <CommonFormLabel htmlFor="city_id" title="City" />
              <SelectBox
                name="city_id"
                value={values?.city_id}
                staticOption="Select a City"
                data={cityOptions}
                disabled={!selectedStateId} 
                onChange={(e) => {
                  setFieldValue("city_id",e?.target?.value);
                }}
              />
              {errors.city_id && touched.city_id ? (
                <ValidationFeedback title={errors.city_id} />
              ) : null}
            </div>

            <div className="g-col-12 mt-3">
              <div className="d-flex align-items-center justify-content-center gap-3 common-popup-btn">
                <CommomButton
                  title="Update"
                  type="submit"
                  disabled={isSubmitting}
                  className="btn-primary primary-shadow"
                />
                <CommomButton
                  title="Cancel"
                  type="button"
                  onClick={handleClose}
                  className="btn-muted"
                />
              </div>
            </div>
          </Form>
        </>
          )}

          </Formik>

        </Modal.Body>
      </Modal>

      <Modal show={showChangePassword} onHide={handleCloseChangePassword}
        centered
        dialogClassName="common-popup-dialog common-popup-dialog-size"
        contentClassName="common-popup-content"
        backdropClassName="common-popup-backdrop">

        <Modal.Body className="common-popup-body">
          <div
            className="position-absolute end-0 z-2 bg-white rounded-1 cursor-pointer d-flex align-items-center justify-content-center  common-popup-colose-btn"
            onClick={handleCloseChangePassword}
          >
            <Cancel01Icon
              width={16}
              height={16}
              color="#BCBBC1"
              strokeWidth="2.5"
            />
          </div>
          <div className="common-mb-space text-center common-popup-heading">
            <h4 className="text-capitalize mb-2 fw-medium">Change Password</h4>
            <p>Change Password {user.name} Profile</p>
          </div>
          <Form className="grid gap-3">
            <div className="g-col-12">
              <CommonFormLabel htmlFor="name" title="New Password" />
              <Input
                name="new password"
                type="number"
                // value={values.name}
                placeholder="Enter Your New Password"
                className='shadow-none'
              // className={`shadow-none ${errors.name && touched.name ? "error" : "correct"
              //   }`}
              />
              {/* {errors.name && touched.name ? (
                <ValidationFeedback title={errors.name} />
              ) : null} */}
            </div>
            <div className="g-col-12">
              <CommonFormLabel htmlFor="name" title="Confirm Password" />
              <Input
                name="confirm password"
                type="number"
                // value={values.name}
                placeholder="Enter Your Confirm Password"
                className='shadow-none'
              // className={`shadow-none ${errors.name && touched.name ? "error" : "correct"
              //   }`}
              />
              {/* {errors.name && touched.name ? (
                <ValidationFeedback title={errors.name} />
              ) : null} */}
            </div>

            <div className="g-col-12 mt-3">
              <div className="d-flex align-items-center justify-content-center gap-3 common-popup-btn">
                <CommomButton
                  title="Update"
                  type="submit"
                  // disabled={isSubmitting}
                  className="btn-primary primary-shadow"
                />
                <CommomButton
                  title="Cancel"
                  onClick={handleCloseChangePassword}
                  className="btn-muted"
                />
              </div>
            </div>
          </Form>
        </Modal.Body>

      </Modal>
    </CommonLayer>
  );
}
