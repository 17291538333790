export const crossCircleIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="16"
    height="16"
    color="#c11111"
    fill="none"
  >
    <path
      d="M15 9L12 12M12 12L9 15M12 12L15 15M12 12L9 9"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47714 17.5228 1.99998 12 1.99998"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.5 8.49998C2.86239 7.67055 3.3189 6.89164 3.85601 6.17676M6.17681 3.85597C6.89168 3.31886 7.67058 2.86237 8.5 2.49998"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const PdfIcon = (props) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 24 24"
      fill={"none"}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.26653 12.6797C4.08253 12.6797 3.95853 12.6977 3.89453 12.7157V13.8937C3.97053 13.9117 4.06553 13.9167 4.19653 13.9167C4.67553 13.9167 4.97053 13.6747 4.97053 13.2667C4.97053 12.8997 4.71653 12.6797 4.26653 12.6797ZM7.75353 12.6917C7.55353 12.6917 7.42353 12.7097 7.34653 12.7277V15.3377C7.42353 15.3557 7.54753 15.3557 7.65953 15.3557C8.47653 15.3617 9.00853 14.9117 9.00853 13.9597C9.01453 13.1297 8.52953 12.6917 7.75353 12.6917Z"
        fill="#9F2F48"
      />
      <path
        d="M10 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V18C0 18.5304 0.210714 19.0391 0.585786 19.4142C0.960859 19.7893 1.46957 20 2 20H14C14.5304 20 15.0391 19.7893 15.4142 19.4142C15.7893 19.0391 16 18.5304 16 18V6L10 0ZM5.498 14.19C5.189 14.48 4.733 14.61 4.202 14.61C4.09904 14.6111 3.99613 14.6051 3.894 14.592V16.018H3V12.082C3.403 12.022 3.811 11.994 4.219 12C4.776 12 5.172 12.106 5.439 12.319C5.693 12.521 5.865 12.852 5.865 13.242C5.864 13.634 5.734 13.965 5.498 14.19ZM9.305 15.545C8.885 15.894 8.246 16.06 7.465 16.06C6.997 16.06 6.666 16.03 6.441 16V12.083C6.8446 12.0241 7.25214 11.9963 7.66 12C8.417 12 8.909 12.136 9.293 12.426C9.708 12.734 9.968 13.225 9.968 13.93C9.968 14.693 9.689 15.22 9.305 15.545ZM13 12.77H11.468V13.681H12.9V14.415H11.468V16.019H10.562V12.03H13V12.77ZM10 7H9V2L14 7H10Z"
        fill="#9F2F48"
      />
    </svg>
  );
};

export const GreenDownloadIcon = (props) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 24 24"
      fill={"none"}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.4444 16H3.55555C2.60588 16 1.71302 15.6302 1.04142 14.9586C0.369847 14.287 0 13.3941 0 12.4444V11.5555C0 11.0646 0.397948 10.6666 0.888894 10.6666C1.37984 10.6666 1.77779 11.0646 1.77779 11.5555V12.4444C1.77779 12.9193 1.96273 13.3657 2.29845 13.7015C2.63426 14.0372 3.08068 14.2222 3.55555 14.2222H12.4444C12.9193 14.2222 13.3657 14.0372 13.7015 13.7015C14.0372 13.3656 14.2222 12.9192 14.2222 12.4444V11.5555C14.2222 11.0646 14.6202 10.6666 15.1111 10.6666C15.602 10.6666 16 11.0646 16 11.5555V12.4444C16 13.3941 15.6302 14.2869 14.9586 14.9586C14.287 15.6302 13.3941 16 12.4444 16ZM7.99998 12.4444C7.76788 12.4447 7.54491 12.3539 7.37903 12.1916L7.37897 12.1915L7.3772 12.1898L7.3767 12.1893C7.3762 12.1888 7.37577 12.1884 7.3753 12.1879L7.3744 12.187C7.37408 12.1867 7.37375 12.1864 7.37341 12.186L7.37148 12.1841L3.81591 8.62852C3.46879 8.2814 3.46879 7.71857 3.81591 7.37142C4.16302 7.0243 4.72588 7.02427 5.073 7.37142L7.11112 9.40954V0.888894C7.11109 0.397948 7.50904 0 7.99998 0C8.49093 0 8.88891 0.397948 8.88891 0.888894V9.40951L10.927 7.37142C11.2741 7.0243 11.837 7.0243 12.1841 7.37142C12.5312 7.71854 12.5312 8.2814 12.1841 8.62852L8.62852 12.1841L8.62659 12.186L8.6256 12.1869L8.6247 12.1878C8.62426 12.1883 8.62377 12.1888 8.62333 12.1892L8.6228 12.1897C8.62224 12.1903 8.62166 12.1909 8.62106 12.1915L8.621 12.1915C8.61122 12.201 8.60132 12.2103 8.59119 12.2193C8.51863 12.2841 8.43585 12.3366 8.3462 12.3744L8.34533 12.3748C8.34499 12.3749 8.34471 12.3751 8.34437 12.3752C8.23535 12.421 8.11825 12.4446 7.99998 12.4444Z"
        fill="#00796B"
      />
    </svg>
  );
};

export const Cardholder = ({ size, color, weight, onClick, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill={color}
      className={className}
      onClick={onClick}
      viewBox="0 0 256 256"
    >
      {weight === "regular" && (
        <path d="M208,48H48A24,24,0,0,0,24,72V184a24,24,0,0,0,24,24H208a24,24,0,0,0,24-24V72A24,24,0,0,0,208,48ZM40,96H216v16H160a8,8,0,0,0-8,8,24,24,0,0,1-48,0,8,8,0,0,0-8-8H40Zm8-32H208a8,8,0,0,1,8,8v8H40V72A8,8,0,0,1,48,64ZM208,192H48a8,8,0,0,1-8-8V128H88.8a40,40,0,0,0,78.4,0H216v56A8,8,0,0,1,208,192Z"></path>
      )}

      {weight === "bold" && (
        <path d="M208,44H48A28,28,0,0,0,20,72V184a28,28,0,0,0,28,28H208a28,28,0,0,0,28-28V72A28,28,0,0,0,208,44ZM48,68H208a4,4,0,0,1,4,4V88H160a12,12,0,0,0-12,12,20,20,0,0,1-40,0A12,12,0,0,0,96,88H44V72A4,4,0,0,1,48,68ZM208,188H48a4,4,0,0,1-4-4V112H85.66a44,44,0,0,0,84.68,0H212v72A4,4,0,0,1,208,188Z"></path>
      )}

      {weight === "duotone" && (
        <>
          <path
            d="M224,72v48H160a32,32,0,0,1-64,0H32V72A16,16,0,0,1,48,56H208A16,16,0,0,1,224,72Z"
            opacity="0.2"
          ></path>
          <path d="M208,48H48A24,24,0,0,0,24,72V184a24,24,0,0,0,24,24H208a24,24,0,0,0,24-24V72A24,24,0,0,0,208,48ZM40,96H216v16H160a8,8,0,0,0-8,8,24,24,0,0,1-48,0,8,8,0,0,0-8-8H40Zm8-32H208a8,8,0,0,1,8,8v8H40V72A8,8,0,0,1,48,64ZM208,192H48a8,8,0,0,1-8-8V128H88.8a40,40,0,0,0,78.4,0H216v56A8,8,0,0,1,208,192Z"></path>
        </>
      )}
    </svg>
  );
};
export const Trash = ({ size, color, weight, onClick, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill={color}
      className={className}
      onClick={onClick}
      viewBox="0 0 256 256"
    >
      {weight === "regular" && (
        <path d="M216,48H176V40a24,24,0,0,0-24-24H104A24,24,0,0,0,80,40v8H40a8,8,0,0,0,0,16h8V208a16,16,0,0,0,16,16H192a16,16,0,0,0,16-16V64h8a8,8,0,0,0,0-16ZM96,40a8,8,0,0,1,8-8h48a8,8,0,0,1,8,8v8H96Zm96,168H64V64H192ZM112,104v64a8,8,0,0,1-16,0V104a8,8,0,0,1,16,0Zm48,0v64a8,8,0,0,1-16,0V104a8,8,0,0,1,16,0Z"></path>
      )}

      {weight === "bold" && (
        <path d="M216,48H180V36A28,28,0,0,0,152,8H104A28,28,0,0,0,76,36V48H40a12,12,0,0,0,0,24h4V208a20,20,0,0,0,20,20H192a20,20,0,0,0,20-20V72h4a12,12,0,0,0,0-24ZM100,36a4,4,0,0,1,4-4h48a4,4,0,0,1,4,4V48H100Zm88,168H68V72H188ZM116,104v64a12,12,0,0,1-24,0V104a12,12,0,0,1,24,0Zm48,0v64a12,12,0,0,1-24,0V104a12,12,0,0,1,24,0Z"></path>
      )}
      {weight === "duotone" && (
        <>
          <path
            d="M224,72v48H160a32,32,0,0,1-64,0H32V72A16,16,0,0,1,48,56H208A16,16,0,0,1,224,72Z"
            opacity="0.2"
          ></path>
          <path d="M208,48H48A24,24,0,0,0,24,72V184a24,24,0,0,0,24,24H208a24,24,0,0,0,24-24V72A24,24,0,0,0,208,48ZM40,96H216v16H160a8,8,0,0,0-8,8,24,24,0,0,1-48,0,8,8,0,0,0-8-8H40Zm8-32H208a8,8,0,0,1,8,8v8H40V72A8,8,0,0,1,48,64ZM208,192H48a8,8,0,0,1-8-8V128H88.8a40,40,0,0,0,78.4,0H216v56A8,8,0,0,1,208,192Z"></path>
        </>
      )}
    </svg>
  );
};

export const ArrowLeft = ({ size, color, weight, onClick, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill={color}
      className={className}
      onClick={onClick}
      viewBox="0 0 256 256"
    >
      {weight === "regular" && (
        <path d="M224,128a8,8,0,0,1-8,8H59.31l58.35,58.34a8,8,0,0,1-11.32,11.32l-72-72a8,8,0,0,1,0-11.32l72-72a8,8,0,0,1,11.32,11.32L59.31,120H216A8,8,0,0,1,224,128Z"></path>
      )}

      {weight === "bold" && (
        <path d="M228,128a12,12,0,0,1-12,12H69l51.52,51.51a12,12,0,0,1-17,17l-72-72a12,12,0,0,1,0-17l72-72a12,12,0,0,1,17,17L69,116H216A12,12,0,0,1,228,128Z"></path>
      )}
    </svg>
  );
};

export const Coins = ({ size, color, weight, onClick, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill={color}
      className={className}
      onClick={onClick}
      viewBox="0 0 256 256"
    >
      {weight === "regular" && (
        <path d="M184,89.57V84c0-25.08-37.83-44-88-44S8,58.92,8,84v40c0,20.89,26.25,37.49,64,42.46V172c0,25.08,37.83,44,88,44s88-18.92,88-44V132C248,111.3,222.58,94.68,184,89.57ZM232,132c0,13.22-30.79,28-72,28-3.73,0-7.43-.13-11.08-.37C170.49,151.77,184,139,184,124V105.74C213.87,110.19,232,122.27,232,132ZM72,150.25V126.46A183.74,183.74,0,0,0,96,128a183.74,183.74,0,0,0,24-1.54v23.79A163,163,0,0,1,96,152,163,163,0,0,1,72,150.25Zm96-40.32V124c0,8.39-12.41,17.4-32,22.87V123.5C148.91,120.37,159.84,115.71,168,109.93ZM96,56c41.21,0,72,14.78,72,28s-30.79,28-72,28S24,97.22,24,84,54.79,56,96,56ZM24,124V109.93c8.16,5.78,19.09,10.44,32,13.57v23.37C36.41,141.4,24,132.39,24,124Zm64,48v-4.17c2.63.1,5.29.17,8,.17,3.88,0,7.67-.13,11.39-.35A121.92,121.92,0,0,0,120,171.41v23.46C100.41,189.4,88,180.39,88,172Zm48,26.25V174.4a179.48,179.48,0,0,0,24,1.6,183.74,183.74,0,0,0,24-1.54v23.79a165.45,165.45,0,0,1-48,0Zm64-3.38V171.5c12.91-3.13,23.84-7.79,32-13.57V172C232,180.39,219.59,189.4,200,194.87Z"></path>
      )}

      {weight === "bold" && (
        <path d="M188,86.11V84c0-14.62-10.83-27.55-30.51-36.4C140.87,40.12,119,36,96,36S51.13,40.12,34.51,47.6C14.83,56.45,4,69.38,4,84v40c0,14.62,10.83,27.55,30.51,36.4A131.67,131.67,0,0,0,68,169.88V172c0,14.62,10.83,27.55,30.51,36.4C115.13,215.88,137,220,160,220s44.87-4.12,61.49-11.6C241.17,199.55,252,186.62,252,172V132C252,109.86,226.71,92.08,188,86.11ZM228,132c0,7.75-21.77,22.48-61.81,23.88C180.33,147.4,188,136.3,188,124V110.44C213.88,115.15,228,125.48,228,132ZM107.37,147.63c-3.63.24-7.42.37-11.37.37-5.08,0-9.89-.22-14.43-.61a10.94,10.94,0,0,0-1.14-.09c-1.51-.14-3-.3-4.43-.48V130.93A187,187,0,0,0,96,132a187,187,0,0,0,20-1.07v15.89c-2.49.3-5.07.56-7.75.75C108,147.58,107.66,147.6,107.37,147.63ZM164,117.14V124c0,4.78-8.28,12.21-24,17.54v-15a115.32,115.32,0,0,0,17.49-6.13Q160.93,118.86,164,117.14ZM96,60c44,0,68,15.85,68,24s-24,24-68,24S28,92.15,28,84,52,60,96,60ZM28,124v-6.86q3.08,1.71,6.51,3.26A115.32,115.32,0,0,0,52,126.53v15C36.28,136.21,28,128.78,28,124Zm64,48v0c1.33,0,2.66,0,4,0q5.44,0,10.77-.32,4.45,1.57,9.23,2.86v15C100.28,184.21,92,176.78,92,172Zm48,22.82V178.94A186.45,186.45,0,0,0,160,180a187,187,0,0,0,20-1.07v15.89a170.08,170.08,0,0,1-40,0Zm64-5.28v-15a115.32,115.32,0,0,0,17.49-6.13q3.44-1.54,6.51-3.26V172C228,176.78,219.72,184.21,204,189.54Z"></path>
      )}

      {weight === "duotone" && (
        <path d="M184,89.57V84c0-25.08-37.83-44-88-44S8,58.92,8,84v40c0,20.89,26.25,37.49,64,42.46V172c0,25.08,37.83,44,88,44s88-18.92,88-44V132C248,111.3,222.58,94.68,184,89.57ZM56,146.87C36.41,141.4,24,132.39,24,124V109.93c8.16,5.78,19.09,10.44,32,13.57Zm80-23.37c12.91-3.13,23.84-7.79,32-13.57V124c0,8.39-12.41,17.4-32,22.87Zm-16,71.37C100.41,189.4,88,180.39,88,172v-4.17c2.63.1,5.29.17,8,.17,3.88,0,7.67-.13,11.39-.35A121.92,121.92,0,0,0,120,171.41Zm0-44.62A163,163,0,0,1,96,152a163,163,0,0,1-24-1.75V126.46A183.74,183.74,0,0,0,96,128a183.74,183.74,0,0,0,24-1.54Zm64,48a165.45,165.45,0,0,1-48,0V174.4a179.48,179.48,0,0,0,24,1.6,183.74,183.74,0,0,0,24-1.54ZM232,172c0,8.39-12.41,17.4-32,22.87V171.5c12.91-3.13,23.84-7.79,32-13.57Z"></path>
      )}
    </svg>
  );
};

export const StackPlus = ({ size, color, weight, onClick, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill={color}
      className={className}
      onClick={onClick}
      viewBox="0 0 256 256"
    >
      {weight === "regular" && (
        <path d="M230.91,124A8,8,0,0,1,228,134.91l-96,56a8,8,0,0,1-8.06,0l-96-56A8,8,0,0,1,36,121.09l92,53.65,92-53.65A8,8,0,0,1,230.91,124ZM24,80a8,8,0,0,1,4-6.91l96-56a8,8,0,0,1,8.06,0l96,56a8,8,0,0,1,0,13.82l-96,56a8,8,0,0,1-8.06,0l-96-56A8,8,0,0,1,24,80Zm23.88,0L128,126.74,208.12,80,128,33.26ZM232,192H216V176a8,8,0,0,0-16,0v16H184a8,8,0,0,0,0,16h16v16a8,8,0,0,0,16,0V208h16a8,8,0,0,0,0-16Zm-92,23.76-12,7L36,169.09A8,8,0,0,0,28,182.91l96,56a8,8,0,0,0,8.06,0l16-9.33A8,8,0,1,0,140,215.76Z"></path>
      )}

      {weight === "bold" && (
        <path d="M240,200a12,12,0,0,1-12,12h-8v8a12,12,0,0,1-24,0v-8h-8a12,12,0,0,1,0-24h8v-8a12,12,0,0,1,24,0v8h8A12,12,0,0,1,240,200ZM218,117.63,128,170.11,38.05,117.63A12,12,0,0,0,26,138.37l96,56a12,12,0,0,0,12.1,0l96-56A12,12,0,1,0,218,117.63ZM20,80a12,12,0,0,1,6-10.37l96-56a12.06,12.06,0,0,1,12.1,0l96,56a12,12,0,0,1,0,20.74l-96,56a12,12,0,0,1-12.1,0l-96-56A12,12,0,0,1,20,80Zm35.82,0L128,122.11,200.18,80,128,37.89ZM138,212.3,128,218.11,38.05,165.63A12,12,0,0,0,26,186.37l96,56a12,12,0,0,0,12.1,0l16-9.34A12,12,0,1,0,138,212.3Z"></path>
      )}

      {weight === "duotone" && (
        <>
          <path d="M224,80l-96,56L32,80l96-56Z" opacity="0.2"></path>
          <path d="M240,200a8,8,0,0,1-8,8H216v16a8,8,0,0,1-16,0V208H184a8,8,0,0,1,0-16h16V176a8,8,0,0,1,16,0v16h16A8,8,0,0,1,240,200Zm-20-78.91-92,53.65L36,121.09A8,8,0,0,0,28,134.91l96,56a8,8,0,0,0,8.06,0l96-56A8,8,0,1,0,220,121.09ZM24,80a8,8,0,0,1,4-6.91l96-56a8,8,0,0,1,8.06,0l96,56a8,8,0,0,1,0,13.82l-96,56a8,8,0,0,1-8.06,0l-96-56A8,8,0,0,1,24,80Zm23.88,0L128,126.74,208.12,80,128,33.26ZM140,215.76l-12,7L36,169.09A8,8,0,0,0,28,182.91l96,56a8,8,0,0,0,8.06,0l16-9.33A8,8,0,1,0,140,215.76Z"></path>
        </>
      )}
    </svg>
  );
};

export const StackMinus = ({ size, color, weight, onClick, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill={color}
      className={className}
      onClick={onClick}
      viewBox="0 0 256 256"
    >
      {weight === "regular" && (
        <path d="M230.91,124A8,8,0,0,1,228,134.91l-96,56a8,8,0,0,1-8.06,0l-96-56A8,8,0,0,1,36,121.09l92,53.65,92-53.65A8,8,0,0,1,230.91,124ZM24,80a8,8,0,0,1,4-6.91l96-56a8,8,0,0,1,8.06,0l96,56a8,8,0,0,1,0,13.82l-96,56a8,8,0,0,1-8.06,0l-96-56A8,8,0,0,1,24,80Zm23.88,0L128,126.74,208.12,80,128,33.26ZM232,192H184a8,8,0,0,0,0,16h48a8,8,0,0,0,0-16Zm-92,23.76-12,7L36,169.09A8,8,0,0,0,28,182.91l96,56a8,8,0,0,0,8.06,0l16-9.33A8,8,0,1,0,140,215.76Z"></path>
      )}

      {weight === "bold" && (
        <path d="M240,200a12,12,0,0,1-12,12H188a12,12,0,0,1,0-24h40A12,12,0,0,1,240,200ZM218,117.63,128,170.11,38.05,117.63A12,12,0,0,0,26,138.37l96,56a12,12,0,0,0,12.1,0l96-56A12,12,0,1,0,218,117.63ZM20,80a12,12,0,0,1,6-10.37l96-56a12.06,12.06,0,0,1,12.1,0l96,56a12,12,0,0,1,0,20.74l-96,56a12,12,0,0,1-12.1,0l-96-56A12,12,0,0,1,20,80Zm35.82,0L128,122.11,200.18,80,128,37.89ZM138,212.3,128,218.11,38.05,165.63A12,12,0,0,0,26,186.37l96,56a12,12,0,0,0,12.1,0l16-9.34A12,12,0,1,0,138,212.3Z"></path>
      )}

      {weight === "duotone" && (
        <>
          <path d="M224,80l-96,56L32,80l96-56Z" opacity="0.2"></path>
          <path d="M240,200a8,8,0,0,1-8,8H184a8,8,0,0,1,0-16h48A8,8,0,0,1,240,200Zm-20-78.91-92,53.65L36,121.09A8,8,0,0,0,28,134.91l96,56a8,8,0,0,0,8.06,0l96-56A8,8,0,1,0,220,121.09ZM24,80a8,8,0,0,1,4-6.91l96-56a8,8,0,0,1,8.06,0l96,56a8,8,0,0,1,0,13.82l-96,56a8,8,0,0,1-8.06,0l-96-56A8,8,0,0,1,24,80Zm23.88,0L128,126.74,208.12,80,128,33.26ZM140,215.76l-12,7L36,169.09A8,8,0,0,0,28,182.91l96,56a8,8,0,0,0,8.06,0l16-9.33A8,8,0,1,0,140,215.76Z"></path>
        </>
      )}
    </svg>
  );
};

export const MoneyWavy = ({ size, color, weight, onClick, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill={color}
      className={className}
      onClick={onClick}
      viewBox="0 0 256 256"
    >
      {weight === "regular" && (
        <path d="M244.24,60a8,8,0,0,0-7.75-.4c-42.93,21-73.59,11.16-106,.78-34-10.89-69.25-22.14-117.95,1.64A8,8,0,0,0,8,69.24V189.17a8,8,0,0,0,11.51,7.19c42.93-21,73.59-11.16,106.05-.78,19.24,6.15,38.84,12.42,61,12.42,17.09,0,35.73-3.72,56.91-14.06a8,8,0,0,0,4.49-7.18V66.83A8,8,0,0,0,244.24,60ZM232,181.67c-40.6,18.17-70.25,8.69-101.56-1.32-19.24-6.15-38.84-12.42-61-12.42a122,122,0,0,0-45.4,9V74.33c40.6-18.17,70.25-8.69,101.56,1.32S189.14,96,232,79.09ZM128,96a32,32,0,1,0,32,32A32,32,0,0,0,128,96Zm0,48a16,16,0,1,1,16-16A16,16,0,0,1,128,144ZM56,96v48a8,8,0,0,1-16,0V96a8,8,0,1,1,16,0Zm144,64V112a8,8,0,1,1,16,0v48a8,8,0,1,1-16,0Z"></path>
      )}

      {weight === "bold" && (
        <path d="M246.36,56.55a12,12,0,0,0-11.63-.6c-41.48,20.29-71.4,10.71-103.07.56C98.48,45.89,60.88,33.85,10.73,58.37A12,12,0,0,0,4,69.16v120.1a12,12,0,0,0,17.27,10.79c41.48-20.29,71.4-10.71,103.07-.56,18.83,6,39.08,12.51,62.24,12.51,17.66,0,37-3.77,58.69-14.37A12,12,0,0,0,252,186.84V66.74A12,12,0,0,0,246.36,56.55ZM228,179.12c-38,16.16-66.41,7.07-96.34-2.51-18.83-6-39.08-12.52-62.24-12.52A124.86,124.86,0,0,0,28,171.24V76.88c38-16.16,66.41-7.08,96.34,2.51C153.6,88.76,186.29,99.23,228,84.76ZM128,96a32,32,0,1,0,32,32A32.06,32.06,0,0,0,128,96Zm0,40a8,8,0,1,1,8-8A8,8,0,0,1,128,136ZM64,100v40a12,12,0,1,1-24,0V100a12,12,0,1,1,24,0Zm128,56V116a12,12,0,1,1,24,0v40a12,12,0,1,1-24,0Z"></path>
      )}

      {weight === "duotone" && (
        <path d="M244.24,60a8,8,0,0,0-7.75-.4c-42.93,21-73.59,11.16-106,.78C96.4,49.53,61.2,38.28,12.49,62.06A8,8,0,0,0,8,69.24V189.17a8,8,0,0,0,11.51,7.19c42.93-21,73.59-11.16,106.05-.78,19.24,6.15,38.84,12.42,61,12.42,17.09,0,35.73-3.72,56.91-14.06a8,8,0,0,0,4.49-7.18V66.83A8,8,0,0,0,244.24,60ZM48,152a8,8,0,0,1-16,0V88a8,8,0,0,1,16,0Zm80,8a32,32,0,1,1,32-32A32,32,0,0,1,128,160Zm96,8a8,8,0,0,1-16,0V104a8,8,0,0,1,16,0Z"></path>
      )}
    </svg>
  );
};

export const Calendar = ({ size, color, weight, onClick, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill={color}
      className={className}
      onClick={onClick}
      viewBox="0 0 256 256"
    >
      {weight === "regular" && (
        <path d="M208,32H184V24a8,8,0,0,0-16,0v8H88V24a8,8,0,0,0-16,0v8H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM72,48v8a8,8,0,0,0,16,0V48h80v8a8,8,0,0,0,16,0V48h24V80H48V48ZM208,208H48V96H208V208Zm-96-88v64a8,8,0,0,1-16,0V132.94l-4.42,2.22a8,8,0,0,1-7.16-14.32l16-8A8,8,0,0,1,112,120Zm59.16,30.45L152,176h16a8,8,0,0,1,0,16H136a8,8,0,0,1-6.4-12.8l28.78-38.37A8,8,0,1,0,145.07,132a8,8,0,1,1-13.85-8A24,24,0,0,1,176,136,23.76,23.76,0,0,1,171.16,150.45Z"></path>
      )}

      {weight === "bold" && (
        <path d="M208,28H188V24a12,12,0,0,0-24,0v4H92V24a12,12,0,0,0-24,0v4H48A20,20,0,0,0,28,48V208a20,20,0,0,0,20,20H208a20,20,0,0,0,20-20V48A20,20,0,0,0,208,28ZM68,52a12,12,0,0,0,24,0h72a12,12,0,0,0,24,0h16V76H52V52ZM52,204V100H204V204Zm60-80v56a12,12,0,0,1-24,0V143.32a12,12,0,0,1-9.37-22l16-8A12,12,0,0,1,112,124Zm61.49,33.88L163.9,168H168a12,12,0,0,1,0,24H136a12,12,0,0,1-8.71-20.25L155.45,142a4,4,0,0,0,.55-2,4,4,0,0,0-7.47-2,12,12,0,0,1-20.78-12A28,28,0,0,1,180,140a27.77,27.77,0,0,1-5.64,16.86A10.63,10.63,0,0,1,173.49,157.88Z"></path>
      )}

      {weight === "duotone" && (
        <>
          <path
            d="M216,48V88H40V48a8,8,0,0,1,8-8H208A8,8,0,0,1,216,48Z"
            opacity="0.2"
          ></path>
          <path d="M208,32H184V24a8,8,0,0,0-16,0v8H88V24a8,8,0,0,0-16,0v8H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM72,48v8a8,8,0,0,0,16,0V48h80v8a8,8,0,0,0,16,0V48h24V80H48V48ZM208,208H48V96H208V208Zm-96-88v64a8,8,0,0,1-16,0V132.94l-4.42,2.22a8,8,0,0,1-7.16-14.32l16-8A8,8,0,0,1,112,120Zm59.16,30.45L152,176h16a8,8,0,0,1,0,16H136a8,8,0,0,1-6.4-12.8l28.78-38.37A8,8,0,1,0,145.07,132a8,8,0,1,1-13.85-8A24,24,0,0,1,176,136,23.76,23.76,0,0,1,171.16,150.45Z"></path>
        </>
      )}
    </svg>
  );
};
