import React from "react";
import { Link } from "react-router-dom";

export default function SidebarItem({ to, icon, title, className }) {
  return (
    <li className="sidebar-item">
      <Link
        to={to}
        className={`d-flex align-items-center text-decoration-none ${className}`}
      >
        {icon}
        <div className="text-capitalize">{title}</div>
      </Link>
    </li>
  );
}
