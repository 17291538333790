import React from "react";
import { Modal } from "react-bootstrap";
import { Cancel01Icon } from "../../icons/icons";
import CommomButton from "../common-button";

export default function DeletePopup({
  icon,
  show,
  onHide,
  title,
  content,
  removeTitle,
  onClickSave,
  saveTitle,
  disabled,
}) {
  return (
    <Modal
      show={show}
      centered
      dialogClassName="common-popup-dialog common-popup-dialog-size"
      contentClassName="common-popup-content"
      backdropClassName="common-popup-backdrop"
    >
      <Modal.Body className="common-popup-body">
        <div
          className="position-absolute end-0 z-2 bg-white rounded-1 cursor-pointer d-flex align-items-center justify-content-center  common-popup-colose-btn"
          onClick={onHide}
        >
          <Cancel01Icon
            width={16}
            height={16}
            color="#BCBBC1"
            strokeWidth="2.5"
          />
        </div>
        <div className="text-center common-popup-heading">
          <div className="common-mb-space">
            {icon}
            <h4 className="text-capitalize pt-2 mt-1 fw-medium">{title}</h4>
            <p className="mt-3">{content}</p>
          </div>

          <div className="d-flex align-items-center justify-content-center pt-2 gap-3">
            <CommomButton
              onClick={onHide}
              title={removeTitle}
              className="btn-muted"
            />
            <CommomButton
              disabled={disabled}
              onClick={onClickSave}
              title={saveTitle}
              className="btn-danger"
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
