import React, { useEffect, useState, useCallback } from "react";
import Input from "../../../components/input";
import CommomButton from "../../../components/common-button";
import DeletePopup from "../../../components/delete-popup/index";
import CommonActionDropDown from "../../../components/common-action-dropdown";
import StatusChip from "../../../components/status-chip/index";
import CommonPagination from "../../../components/common-pagination";
import { useSelector, useDispatch } from "react-redux";
import {
  Table,
  // Button,
  Toast,
  // FormCheck as Switch,
  // Badge,
  // Dropdown,
  // Modal,
  ToastContainer,
  Image,
  ButtonGroup,
  // OverlayTrigger,
  // Tooltip,
} from "react-bootstrap";
import {
  deleteCustomer,
  fetchEvents,
  // deleteReseller,
  // updateReseller,
} from "../../../store/eventsSlice";
import { Container } from "react-bootstrap";
// import { MoreVertical } from "react-feather";
import { Link } from "react-router-dom";
// import SortImage from "../../../sort.svg";
// import PaginationComponent from "../pagination/pagination";
import { useNavigate } from "react-router-dom";
import { useLoader } from "../../../context/LoaderContext";
import moment from "moment";
// import { MoreVertical } from "react-feather";
import Edit from "./Edit";
import "./styleModule.css";
import CreatableSelect from "react-select/creatable";
import {
  CheckmarkBadge04Icon,
  Add01Icon,
  MoreVerticalIcon,
  Delete02Icon,
} from "../../../icons/icons";
import { recordsPerPageOptions } from "../campaings/common/Common";
// import { PencilSquare,Trash3} from 'react-bootstrap-icons';

export default function List({
  editHandler,
  handleOpenModal,
  currentPage,
  setCurrentPage,
  searchTerm,
  setSearchTerm,
  limit,
  handleChange,
  id,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setLoading } = useLoader();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("toast-success");
  const [openDropdown, setOpenDropdown] = useState(null);
  const events = useSelector((state) => state.events.events);
  // const countries = useSelector((state) => state.events.countries);
  // const totalPages = useSelector((state) => state.events.totalPages);
  const total = useSelector((state) => state.events.total);
  // const id = useSelector((state) => state.auth.user.id)
  // console.log('ID : ', id);

  const [showEditModal, setShowEditModal] = useState(false);
  const [currentCustomer, setCurrentCustomer] = useState(null);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  // const [hoveredRow, setHoveredRow] = useState(null);

  const handleToggleDropdown = (dropdownId) => {
    setOpenDropdown((prev) => (prev === dropdownId ? null : dropdownId));
  };

  const getEventsList = useCallback(() => {
    setLoading(true);
    dispatch(
      fetchEvents({
        offset: currentPage,
        limit: limit?.value,
        search: searchTerm,
        id: id,
      })
    )
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch, currentPage, limit?.value, searchTerm, id, setLoading]);

  useEffect(() => {
    getEventsList();
  }, [getEventsList]);

  const handlePageChange = (newPageNumber) => {
    setCurrentPage(newPageNumber - 1);
    window.scrollTo({ top: 0, behavior: "smooth" }); 
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="text-muted text-primary-hover"
    >
      {children}
    </Link>
  ));

  CustomToggle.displayName = "CustomToggle";

  const navigateToDetails = (id, item) => {
    navigate(`/customers/${id}`, { state: { item } });
  };

  const handleEditClick = (customer) => {
    setCurrentCustomer(customer); // Set the current customer data
    setShowEditModal(true); // Show the Edit modal
  };

  const handleDeleteCustomerClick = (customer) => {
    setCurrentCustomer(customer); // Set the selected campaign to be deleted
    setShowDeleteConfirmModal(true); // Show the confirmation modal
  };

  const handleConfirmDelete = async () => {
    try {
      setIsSubmitting(true); // Set loading state
      setLoading(true); // Optional loader if you have one
      dispatch(deleteCustomer({ id: currentCustomer.id }));
      setSnackbarMessage("Customer deleted successfully");
      setSnackbarVariant("toast-success");
      setShowSnackbar(true);
      getEventsList(); // Refresh the campaigns list
    } catch (error) {
      setSnackbarMessage("Failed to delete campaign");
      setSnackbarVariant("toast-danger");
      setShowSnackbar(true);
    } finally {
      setIsSubmitting(false);
      setLoading(false); // Optional loader
      setShowDeleteConfirmModal(false);
    }
  };

  // const ActionMenu = ({ customer }) => {
  //   return (
  //     <Dropdown drop="start" className="position-static">
  //       <Dropdown.Toggle as={CustomToggle}>
  //         <MoreVertical size="15px" className="text-muted" />
  //       </Dropdown.Toggle>
  //       <Dropdown.Menu align={"end"}>
  //         <Dropdown.Item eventKey="1" onClick={() => handleEditClick(customer)}>
  //           Edit
  //         </Dropdown.Item>
  //         <Dropdown.Item
  //           eventKey="2"
  //           onClick={() => {
  //             navigateToDetails(customer?.id);
  //           }}
  //         >
  //           View
  //         </Dropdown.Item>
  //         {customer?.campaigns_count === 0 && (
  //           <Dropdown.Item
  //             eventKey="3"
  //             onClick={() => handleDeleteCustomerClick(customer)}
  //           >
  //             Delete
  //           </Dropdown.Item>
  //         )}
  //       </Dropdown.Menu>
  //     </Dropdown>
  //   );
  // };

  const getCustomerActions = (customer) => [
    {
      title: "Edit",
      handler: () => handleEditClick(customer),
    },
    {
      title: "View",
      handler: () => {
        navigateToDetails(customer?.id);
      },
    },
    ...(customer?.campaigns_count === 0
      ? [
          {
            title: "Delete",
            handler: () => handleDeleteCustomerClick(customer),
          },
        ]
      : []),
  ];

  // console.log("events",events);

  return (
    <>
      <ToastContainer position="top-end" className="p-3">
        <Toast
          onClose={() => setShowSnackbar(false)}
          show={showSnackbar}
          delay={3000}
          autohide
          bg={snackbarVariant}
          className="position-fixed top-3 end-0 me-3 shadow-none"
        >
          <Toast.Body className="d-flex align-items-center gap-1 lh-sm">
            <CheckmarkBadge04Icon
              width={18}
              height={18}
              color="#28c76f"
              strokeWidth="2"
            />
            <span className="text-capitalize">{snackbarMessage}</span>
          </Toast.Body>
        </Toast>
      </ToastContainer>

      <Container fluid className="px-0">
        {/* <Row>
          <Col xl={12} lg={12} md={12} xs={12} className="mt-6">
            <div className="tablecontainer position-relative overflow-hidden">
              <div className="input_btn">
                <div className="float-end">
                  <Button
                    variant="primary"
                    onClick={() => handleOpenModal(true)}
                  >
                    Add Customer
                  </Button>
                </div>
              </div>
              <div className="position-relative overflow-auto">
                <Table className="text-nowrap data_list text-start ">
                  <thead className="table-light">
                    <tr>
                      <th scope="col">
                        <div className="d-flex gap-2 align-items-center">
                          <span className="">Name</span>
                          <img
                            src={SortImage}
                            alt="Sort"
                            style={{ width: "17px", height: "16px" }}
                          />
                        </div>
                      </th>

                      <th scope="col">
                        <div className="d-flex gap-2 align-items-center">
                          <span className="">Contact Number</span>
                          <img
                            src={SortImage}
                            alt="Sort"
                            style={{ width: "17px", height: "16px" }}
                          />
                        </div>
                      </th>

                      <th scope="col">
                        <div className="d-flex justify-content-between align-items-center">
                          <span>Campaigns</span>
                          <img
                            src={SortImage}
                            alt="Sort"
                            style={{ width: "17px", height: "16px" }}
                          />
                        </div>
                      </th>

                      <th scope="col">
                        <div className="d-flex justify-content-between align-items-center">
                          <span>Created At</span>
                          <img
                            src={SortImage}
                            alt="Sort"
                            style={{ width: "17px", height: "16px" }}
                          />
                        </div>
                      </th>

                      <th scope="col">
                        <div className="d-flex justify-content-between align-items-center">
                          <span>WhatsApp Status</span>
                          <img
                            src={SortImage}
                            alt="Sort"
                            style={{ width: "17px", height: "16px" }}
                          />
                        </div>
                      </th>
                      <th>
                        <div className="d-flex justify-content-between align-items-center">
                          <span>Action</span>
                          <img
                            src={SortImage}
                            alt="Sort"
                            style={{ width: "17px", height: "16px" }}
                          />
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {events.length === 0 ? (
                      <tr>
                        <td
                          className="mt-5 fw-bold fs-5 text-center"
                          colSpan="7"
                        >
                          No data available
                        </td>
                      </tr>
                    ) : (
                      events.map((event, index) => (
                        <tr key={event?.id} className="data_row">
                          <td className="text-capitalized">{event?.name}</td>
                          <td>{event?.contact_number}</td>
                          <td>{event?.campaigns_count}</td>
                          <td>
                            {moment(event?.created_at).format(
                              "DD-MM-YYYY hh:mm A"
                            )}
                          </td>
                          <td>
                            {event?.whatsapp_client?.is_active ? (
                              <Badge pill bg="success">
                                Connected
                              </Badge>
                            ) : (
                              <Badge pill bg="danger">
                                Not Connected
                              </Badge>
                            )}
                          </td>
                          <td>
                            <ActionMenu customer={event} />
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </Table>
                {totalPages > 1 ? (
                  <PaginationComponent
                    totalItems={total}
                    itemsPerPage={limit}
                    currentPage={currentPage + 1}
                    onPageChange={handlePageChange}
                  />
                ) : null}
              </div>
            </div>
          </Col>
        </Row> */}

        <div className="bg-white rounded-2 common-shadow custom-style">
          <div className="coomon-table-action-head d-flex align-items-center justify-content-between gap-4">
            <Input
              type="search"
              value={searchTerm}
              placeholder="Search"
              onChange={(e) => {
                setSearchTerm(e.target.value);
                setCurrentPage(0);
              }}
              className="shadow-none base-search"
            />

            <CommomButton
              onClick={() => handleOpenModal(true)}
              icon={
                <Add01Icon
                  width={16}
                  height={16}
                  color="#ffffff"
                  strokeWidth="2"
                />
              }
              title="Add Customer"
              className="btn-primary common-one-shadow"
            />
          </div>
          <Table responsive className="common-table position-relative">
            <thead>
              <tr className="position-sticky top-0 z-2">
                <th>
                  <div className="table-br">
                    <p>Name</p>
                  </div>
                </th>
                <th>
                  <div className="table-br">
                    <p>Contact Number</p>
                  </div>
                </th>
                <th>
                  <div className="table-br">
                    <p>Campaigns</p>
                  </div>
                </th>
                <th>
                  <div className="table-br">
                    <p>Created At</p>
                  </div>
                </th>
                <th>
                  <div className="table-br">
                    <p>WhatsApp Status</p>
                  </div>
                </th>
                <th>
                  <div className="table-br">
                    <p>Actions</p>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {events.length === 0 ? (
                <tr>
                  <td className="text-center" colSpan="7">
                    <div className="text-center w-100 blank-table-view">
                      <Image
                        src="/assets/images/component-common/blank-table.png"
                        alt="Blank-Table-Data-Image"
                        className="img-fluid"
                      />
                      <div className="mt-3">
                        <h5 className="fw-medium">Couldn't find any data.</h5>
                        <p className="pt-2 mt-1">
                          This report does not contain any points.
                          {/* Try to change your filter and try again. */}
                        </p>
                      </div>
                    </div>
                  </td>
                </tr>
              ) : (
                events.map((item, i) => {
                  return (
                    <tr
                      key={i}
                      onClick={() => navigateToDetails(item.id, item)}
                      className="data-row cursor-pointer"
                      // onMouseEnter={() => setHoveredRow(i)} // Track hover
                      // onMouseLeave={() => setHoveredRow(null)} // Reset on leave
                    >
                      <td
                        className="position-relative"
                        style={{ minHeight: "50px" }}
                      >
                        {/* <div
                          className="d-flex align-items-center justify-content-start"
                          style={{
                            position: "absolute",
                            top: "-14px",
                            left: "15px",
                            width: "100%",
                            height: "100%",
                            opacity: hoveredRow === i ? 1 : 0,
                            transition: "opacity 0.3s ease",
                            zIndex: 1,
                          }}
                        >
                       <OverlayTrigger placement="top" overlay={<Tooltip>Edit</Tooltip>}>
                          <div style={{ marginLeft: "10px", width: "20px", height: "20px"}}>
                            <PencilSquare onClick={(e) => {
                              e.stopPropagation();
                              handleEditClick(item)
                            }}/>
                          </div>
                       </OverlayTrigger>
                        {item?.campaigns_count === 0 && (
                        <OverlayTrigger placement="top" overlay={<Tooltip>Delete</Tooltip>}>
                          <div style={{ width: '20px', height: '20px' }}>
                            <Trash3
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDeleteCustomerClick(item);
                              }}
                            />
                          </div>
                        </OverlayTrigger>
                       )}
                        </div> */}
                        {/* <span style={{ zIndex: 0 }}> */}
                        {item.name}
                        {/* </span> */}
                      </td>
                      <td>{item.contact_number}</td>
                      <td>{item.campaigns_count}</td>
                      <td>
                        {moment(item.created_at).format("DD-MM-YYYY hh:mm A")}
                      </td>
                      <td>
                        {item.whatsapp_client.is_active ? (
                          <>
                            <StatusChip
                              bg="chip-green"
                              title="Connected"
                              className="text-capitalize"
                            />
                            <div className="customer_list_no_show">
                              {item?.whatsapp_client &&
                              item?.whatsapp_client?.mobile_number
                                ? item?.whatsapp_client?.mobile_number
                                : ""}
                            </div>
                          </>
                        ) : (
                          <StatusChip
                            bg="chip-red"
                            title="Not Connected"
                            className="text-capitalize"
                          />
                        )}
                      </td>
                      <td onClick={(e) => e.stopPropagation()}>
                        <CommonActionDropDown
                          as={ButtonGroup}
                          id={`dropdown-basic-${i}`}
                          icon={
                            <MoreVerticalIcon
                              width={20}
                              height={20}
                              color="#6D6B77"
                              strokeWidth="4"
                            />
                          }
                          actions={getCustomerActions(item)}
                          show={openDropdown === i}
                          onToggle={() => handleToggleDropdown(i)}
                          className="action-menu"
                        />
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </Table>

          <div className="common-table-foot-new mx-1 d-flex flex-wrap align-items-center justify-content-between gap-4">
            <p className="custom-table-infotext ">
              Displaying {currentPage * limit?.value + 1} to{" "}
              {Math.min((currentPage + 1) * limit?.value, total)} of {total}{" "}
              Customers
            </p>
            <div className="d-flex gap-5">
              {/* {totalPages > 1 ? (
                <> */}
              {events?.length !== 0 && (
                <>
                  <CreatableSelect
                    isClearable
                    options={recordsPerPageOptions}
                    value={limit}
                    onChange={handleChange}
                    placeholder="Select or create"
                    menuPortalTarget={document.body} // Render the menu at the body level
                    menuPlacement="top" // Display the options above the input
                    styles={{
                      control: (base) => ({
                        ...base,
                        width: "170px", // Fixed width for the input field
                      }),
                      menu: (base) => ({
                        ...base,
                        width: "170px", // Fixed width for the dropdown menu
                      }),
                      menuPortal: (base) => ({
                        ...base,
                        zIndex: 9999, // Ensure it appears above everything
                      }),
                    }}
                  />
                  <CommonPagination
                    totalItems={total}
                    itemsPerPage={limit?.value}
                    currentPage={currentPage + 1}
                    onPageChange={handlePageChange}
                  />
                </>
              )}

              {/* </>
              ) : null} */}
            </div>
          </div>
        </div>
      </Container>

      {/* <Modal
        show={showDeleteConfirmModal}
        onHide={() => setShowDeleteConfirmModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this Customer?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowDeleteConfirmModal(false)}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleConfirmDelete}
            disabled={isSubmitting}
          >
            {isSubmitting ? "Deleting..." : "Delete"}
          </Button>
        </Modal.Footer>
      </Modal> */}

      <DeletePopup
        show={showDeleteConfirmModal}
        onHide={() => setShowDeleteConfirmModal(false)}
        icon={
          <Delete02Icon
            width={48}
            height={48}
            color="#ff4c51"
            strokeWidth="1.5"
          />
        }
        title="Confirm Delete?"
        content="Are you sure you want to delete this customer?"
        removeTitle="Cancel"
        onClickSave={handleConfirmDelete}
        disabled={isSubmitting}
        saveTitle={isSubmitting ? "Deleting..." : "Delete"}
      />

      {showEditModal && (
        <Edit
          show={showEditModal}
          handleClose={() => setShowEditModal(false)}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          searchTerm={searchTerm}
          limit={limit}
          id={id}
          currentCustomer={currentCustomer}
          setShowSnackbar={setShowSnackbar}
          setSnackbarMessage={setSnackbarMessage}
          setSnackbarVariant={setSnackbarVariant}
        />
      )}
    </>
  );
}
