import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

export default function CampaignsReportChart({ className }) {
  const [state, setState] = useState({
    series: [
      {
        type: "column",
        data: [490, 950, 1920, 1500, 500, 200, 800, 1100, 350],
      },
    ],
    options: {
      chart: {
        type: "line",
        events: {
          click: function (chart, w, e) { },
        },
        toolbar: {
          show: false,
        },
      },
      grid: {
        show: true,
        borderColor: "#EAEAEA",
        strokeDashArray: 4,
      },
      annotations: {
        points: [
          {
            x: "Bananas",
            seriesIndex: 0,
            label: {
              borderColor: "#EAEAEA",
              offsetY: 0,
              style: {
                color: "#fff",
                background: "#775DD0",
              },
              text: "Bananas are good",
            },
          },
        ],
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          columnWidth: "45%",
          distributed: false,
        },
      },
      stroke: {
        width: 0,
        curve: "smooth",
      },
      colors: [
        "#A5596A",
        "#A5596A",
        "#A5596A",
        "#A5596A",
        "#A5596A",
        "#A5596A",
        "#A5596A",
        "#A5596A",
        "#A5596A",
      ],
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: true,
        fontSize: "12px", // Default font size for legend
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "July",
          "Aug",
          "Sep",
        ],
        labels: {
          style: {
            colors: "#6D6B77",
            fontSize: "12px", // Default font size for x-axis labels
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: "#6D6B77",
            fontSize: "12px", // Default font size for y-axis labels
          },
        },
      },
    },
    chartHeight: 265, // Default chart height
  });

  const adjustChartDimensions = () => {
    const screenWidth = window.innerWidth;
    const legendFontSize = screenWidth > 1920 ? "18px" : "12px";
    const xAxisFontSize = screenWidth > 1920 ? "18px" : "12px";
    const yAxisFontSize = screenWidth > 1920 ? "18px" : "12px";
    const chartHeight = screenWidth > 1920 ? 285 : 265;

    setState((prevState) => ({
      ...prevState,
      options: {
        ...prevState.options,
        legend: {
          ...prevState.options.legend,
          fontSize: legendFontSize,
        },
        xaxis: {
          ...prevState.options.xaxis,
          labels: {
            ...prevState.options.xaxis.labels,
            style: {
              ...prevState.options.xaxis.labels.style,
              fontSize: xAxisFontSize,
            },
          },
        },
        yaxis: {
          ...prevState.options.yaxis,
          labels: {
            ...prevState.options.yaxis.labels,
            style: {
              ...prevState.options.yaxis.labels.style,
              fontSize: yAxisFontSize,
            },
          },
        },
      },
      chartHeight: chartHeight,
    }));
  };

  useEffect(() => {
    adjustChartDimensions();
    window.addEventListener("resize", adjustChartDimensions);

    return () => {
      window.removeEventListener("resize", adjustChartDimensions);
    };
  }, []);

  return (
    <div className={className}>
      <div id="chart">
        <ReactApexChart
          options={state.options}
          series={state.series}
          type="line"
          height={state.chartHeight}
        />
      </div>
    </div>
  );
}
