import React, { useState, useRef, useEffect } from "react";
import EmojiPicker from "emoji-picker-react";
import { SmileIcon } from "../../../../icons/icons";

const EmojiPickerWrapper = ({ onEmojiClick }) => {
  const [isEmojiPickerVisible, setEmojiPickerVisibility] = useState(false);
  const emojiPickerRef = useRef();

  const handleEmojiClick = (event, emojiObject) => {
    onEmojiClick(event, emojiObject);
    setEmojiPickerVisibility(false);
  };

  const handleOutsideClick = (event) => {
    if (
      emojiPickerRef.current &&
      !emojiPickerRef.current.contains(event.target)
    ) {
      setEmojiPickerVisibility(false);
    }
  };

  const handleTogglePicker = () => {
    setEmojiPickerVisibility(!isEmojiPickerVisible);
  };

  // Attach or detach the outside click listener when the visibility changes
  useEffect(() => {
    if (isEmojiPickerVisible) {
      document.addEventListener("click", handleOutsideClick);
    } else {
      document.removeEventListener("click", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [isEmojiPickerVisible]);

  return (
    <div ref={emojiPickerRef}>
      {isEmojiPickerVisible && (
        <EmojiPicker
          onEmojiClick={handleEmojiClick}
          suggestedEmojisMode="recent"
          height={350}
          lazyLoadEmojis={true}
          skinTonesDisabled={true}
          previewConfig={{
            showPreview: false,
          }}
          autoFocusSearch={true}
        />
      )}
      <SmileIcon
        className="cursor-pointer"
        height={18}
        width={18}
        color="#444050"
        strokeWidth="2"
        onClick={handleTogglePicker}
      />
    </div>
  );
};

export default EmojiPickerWrapper;
